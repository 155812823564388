import React, { Component } from 'react';
import ROUTES from '../../const/routes.const';
import Layout from '../../components/layout/layout';
import NavbarGroup from '../../components/navbar/navbar-group';
import { groupService, quizzesServices } from '../../services';
import LocalStorageService from '../../services/local-storage.service';
import LANG from '../../const/language.const';
import ModalAssociateQuizzes from './modal-associate-quizzes';
import CardQuiz from '../../components/cards/card-quiz';
import { Icon } from '../../components/ui';
import swal from '@sweetalert/with-react';

class GroupQuizzes extends Component {
  constructor(props) {
    super(props);

    const currentAcademic = LocalStorageService.session();
    this.state = {
      academic: currentAcademic,
      group: {
        _id: this.props.match.params.group || '',
        pin: '',
        institute: {
          _id: ''
        },
        quizzes: [],
      },
      isModalOpen: false,
      quizzes: [],
    }

    this.onCloseHandler = this.onCloseHandler.bind(this);
    this.associateQuizzes = this.associateQuizzes.bind(this);
    this._renderQuizzes = this._renderQuizzes.bind(this);
    this.showQuizHandler = this.showQuizHandler.bind(this);
    this.removeQuiz = this.removeQuiz.bind(this);
    this.changeCheckQuizHandler = this.changeCheckQuizHandler.bind(this);
    this.deleteQuizHandler = this.deleteQuizHandler.bind(this);
  }

  componentDidMount() {
    this.getGroup();
    this.getQuizzes();
  }

  async getGroup() {
    try {
      const groupId = this.props.match.params.group;
      const response = await groupService.getGroupDetail(groupId);
      this.setState({
        group: response.data
      });
    } catch(error) {
      console.log(error);
    }
  }

  async getQuizzes() {
    try {
      const { academic } = this.state;
      const response = await quizzesServices.getByOwner(academic._id);
      this.setState({
        quizzes: response.data,
      });
    } catch(error) {
      console.log(error);
    }

  }

  async associateQuizzes() {
    try {
      let group = this.state.group;
      let selectedQuizzes = this.state.quizzes.filter(item => item.checked).map(q => q._id);
      const response = await groupService.associateQuizzes(group._id, selectedQuizzes);
      if (response.data.status) {
        let updatedQuizzes = this.state.quizzes.map(q => {
          q.checked = false;
          return q;
        });
        this.setState({ 
          isModalOpen: false,
          quizzes: updatedQuizzes 
        });
        this.getGroup();
      }
    } catch (error) {
      console.log(error);
    }
  }

  showQuizHandler(quizId) {
    this.props.history.push(ROUTES.QUIZZES.QUESTIONS.replace(':quiz', quizId));
  }

  async deleteQuizHandler(quiz) {
    const confirm = await swal({
      text: `¿Seguro que quieres desvincular el cuestionario "${quiz.name}"?`,
      icon: 'warning',
      dangerMode: true,
      buttons: [
        'Cancelar',
        'Si, desvincular'
      ]
    });

    if (!confirm) {
      return false;
    }

    try {
      await this.removeQuiz(quiz)
      swal({
        title: 'Cuestionario desvinculado',
        text: 'El cuestionario ha sido desvinculado del grupo. Puedes volver a vincularlo mediante el botón "+ VINCULAR CUESTIONARIO"',
        icon: 'success',
        button: 'ok',
      });
    } catch (error) {
      console.log(error.response.data.message)
    }
  }

  async removeQuiz(quiz) {
    try {
      let group = this.state.group;
      const response = await groupService.removeQuizzes(group._id, [quiz._id]);
      if (response.data.status) {
        this.getGroup();
      }
    } catch(error) {
      console.log(error);
    }
  }

  onCloseHandler() {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  }

  changeCheckQuizHandler(event) {
    let value = event.target.value;
    let checked = event.target.checked;

    let selectQuizzes = this.state.quizzes.map(quiz => {
      if (quiz._id === value) {
        quiz.checked = checked;
      }
      return quiz;
    });

    this.setState({
      quizzes: selectQuizzes
    });
  }

  _renderQuizzes() {
    return this.state.quizzes.map(item => {
      if (this.state.group && this.state.group.quizzes.indexOf(item._id) >= 0) {
        return (
          <div className="col-md-4" key={item._id}>
            <CardQuiz 
              quiz={item}
              showDetail={this.showQuizHandler}
              editOption="group"
              delete={this.deleteQuizHandler}
            />
          </div>
        )
      }
      return null;
    });
  }

  _renderEmpty() {
    return (
      <div className="col-md-6 offset-md-3 pt-5">
        <div className="text-center">Aún no tienes ningún cuestionario vinculado a este grupo.</div>
      </div>
    );
  }

  render() {
    const { group, quizzes }  = this.state;
    let quizzesList = quizzes.filter(q => {
      return group.quizzes.indexOf(q._id, group.quizzes) < 0;
    });
    
    return (
      <Layout 
        navBar={
          <NavbarGroup 
            linkBack={ROUTES.INSTITUTES.GROUPS.replace(':id', group.institute._id)}
            titleNav={group.name}
            groupPin={group.pin}
            currentOption={this.props.match.url}
            groupID={this.state.group._id}
            searchBar={false}
          />
        }
      >
        <div className="row">
          <div className="col-12 align-content-end text-right mb-4">
            <div style={{display: 'inline-flex'}}>
              <button
                type="button"
                className="btn btn-primary btn-rounded btn-material-icon"
                onClick={this.onCloseHandler}
              >
                <i className="material-icons">add</i>
                <span>{ LANG.QUIZZES.BTN_LINK }</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-info d-flex">
              <div className="mr-3"><Icon name="info" /></div>
              <p style={{fontSize: '0.85rem'}}>Echa un vistazo aquí a los cuestionarios que tienes habilitados para usar con este grupo. <br/>Recuerda que la creación de los cuestionarios no se realiza en esta sección sino en la opción de cuestionarios de la pantalla principal. Una vez que tengas creados cuestionarios allá los puedes vincular a este grupo mediante la opción de "+ VINCULAR CUESTIONARIO".</p>
            </div>
          </div>
        </div>
        <div className="row">
          { quizzes.length > 0 ? this._renderQuizzes() : this._renderEmpty() }
        </div>
        <ModalAssociateQuizzes 
          isOpen={this.state.isModalOpen}
          onClose={this.onCloseHandler}
          quizzes={quizzesList}
          changeCheckQuiz={this.changeCheckQuizHandler}
          saveSelectedQuizzes={this.associateQuizzes}
        />
      </Layout>
    );
  }
}

export default GroupQuizzes;