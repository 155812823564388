import React from 'react';
import { Avatar, Button } from '../../../components/ui';
import DEFAULT_AVATAR from '../../../assets/images/default-avatar.png';
import QuizRating from '../../../components/quiz-rating/quiz-rating';

function CardQuizShopInfo({quiz, enabledCartBtn}) {
  return (
    <div>
      <div className="card-mk-quiz--title">Nombre del cuestionario</div>
      <div className="card-mk-quiz--level">Nivel escolar</div>
      <div className="card-mk-quiz--info">
        <div>Grado escolar</div>
        <div>120 preguntas</div>
        <div>1k descargas</div>
        <div>
          <div className="quiz-raiting-starts">
            <QuizRating 
              initialRating={3}
              readonly
            />
          </div>
        </div>
      </div>
      <div className="card-mk-quiz--author">
        <div>
          <span>Nombre del autor</span>
        </div>
        <Avatar src={DEFAULT_AVATAR} />
      </div>
      <div className="card-mk-quiz--price">
        $1000.00mxn
      </div>
      <div className="card-mk-quiz--actions">
        { enabledCartBtn &&
          <Button 
            color="primary"
            label="Comprar"
          />
        }
      </div>
    </div>
  );
}

export default CardQuizShopInfo;