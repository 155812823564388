import React from 'react';
import { Button } from '../../../components/ui';
import ListTeams from '../../../components/list-teams/list-teams';
import ListSudents from '../../../components/list-students/list-students';
import { TextField } from '../../../components/ui';
import swal from '@sweetalert/with-react';
import { Modal, Row, Col } from 'react-bootstrap';

function FormChallengeTeams(props) {
  const [numRandomTeams, setnumRandomTeams] = React.useState(2);
  const [isRandomTeamsOpen, setIsRandomTeamsOpen] = React.useState(false);
  const [showAlertChangeTeam, setShowAlertChangeTeam] = React.useState(false);
  const [changeTeamRequest, setChangeTeamRequest] = React.useState(null);

  const { 
    students, 
    selectedStudents, 
    onSelectStudent, 
    onCreateTeam,
    teams,
    onChangeTeams, 
    onChangeStudents,
    onCreateRandomTeams,
    calculateRandomTeams,
    onNext,
    onBack,
    onRemoveTeamMember,
  } = props;

  const changeNumRandomTeamsHandler = (e) => {
    let value = e.target.value;
    if (value >= 2) {
      setnumRandomTeams(value);
    }
  }

  const toogleRandomTeamsModal = () => {
    setIsRandomTeamsOpen(!isRandomTeamsOpen);
  }

  const createRandomTeamsHandle = () => {
    toogleRandomTeamsModal();
    onCreateRandomTeams(numRandomTeams);
  }

  const changeMemberTeam = () => {
    if (changeTeamRequest.fromTeam < 0) {
      teams[changeTeamRequest.toTeam].members.push(changeTeamRequest.member);
      let listStudents = students.filter(item => item._id !== changeTeamRequest.member._id);
      onChangeStudents(listStudents);
    } else {
      teams[changeTeamRequest.fromTeam].members = teams[changeTeamRequest.fromTeam].members.filter(item => item._id !== changeTeamRequest.member._id);
      teams[changeTeamRequest.toTeam].members.push(changeTeamRequest.member);
    }

    toogleAlertChangeTeam();
    onChangeTeams(teams);
  }
  
  const changeSelectTeam = (e) => {
    changeTeamRequest.toTeam = e.target.value;
    setChangeTeamRequest(changeTeamRequest);
  }

  const removeMemberHandler = (member, team) => {
    console.log('remove member', member, team);
    onRemoveTeamMember(member, team);
  }
  
  const toogleAlertChangeTeam = () => {
    setShowAlertChangeTeam(!showAlertChangeTeam);
  }

  const moveMemberHandler = async (member, team) => {
    setChangeTeamRequest({
      member: member,
      fromTeam: team,
      toTeam: team !== 0 ? 0 : 1,
    })
    toogleAlertChangeTeam();
  }

  const assignTeamHandler = async (member) => {
    if (teams.length > 0) {
      moveMemberHandler(member, -1);
    } else {
      await swal({
        title: '¡Ups!',
        text: 'Aún no tienes equipos creados',
        icon: 'error',
        button: 'ok',
      });
    }
  }

  let enabledCreateTeam = selectedStudents.length ? true : false;
  let enableRandomTeams = teams.length ? false : true;
  let calculatedTeams = calculateRandomTeams(numRandomTeams);
  let enableCreateRandom = calculatedTeams && !calculatedTeams.find(item => item.numMembers === 0) ? true : false;
  let disabledNext = teams.length > 0 && students.length < 1 ? false : true;

  return (
    <div className="form-challenge-teams">
      <div className="row bg-primary" style={{ margin: '-1.25rem -1.25rem 0' }}>
        <div className="col-12 text-right py-3">
          <Button 
            color="outline-light"
            label="Crear equipos aleatorios"
            icon="shuffle"
            className="btn-sm"
            disabled={!enableRandomTeams}
            onClick={toogleRandomTeamsModal}
          />
        </div>
      </div>
      <div className="row py-3">
        <div className="col">
          <strong className="text-primary">
            {`${students.length} Alumnos disponibles`}
          </strong>
        </div>
        <div className="col-md-auto">
          <Button 
            color="outline-primary"
            label="Crear equipo"
            icon="add"
            className="btn-sm"
            disabled={!enabledCreateTeam}
            onClick={onCreateTeam}
          />
        </div>
      </div>
      <div className="list-teams">
        <ListTeams 
          teams={teams}
          onChangeTeams={onChangeTeams}
          onMoveMember={moveMemberHandler}
          onRemoveMember={removeMemberHandler}
        />
      </div>
      <div className="list-students">
        <ListSudents 
          students={students}
          selected={selectedStudents}
          onSelectStudent={onSelectStudent}
          onAssignTeam={assignTeamHandler}
        />
      </div>
      <div className="row">
        <div className="col pt-4 text-right">
          <Button 
            color="outline-primary"
            icon="chevron_right"
            label="Regresar"
            className="ml-2"
            onClick={onBack}
          />
          <Button 
            color="outline-primary"
            icon="chevron_right"
            label="Continuar"
            className="ml-2"
            disabled={disabledNext}
            onClick={onNext}
          />
        </div>
      </div>
      <Row>
        <Col>
          <Modal 
            centered
            show={showAlertChangeTeam} 
            onHide={toogleAlertChangeTeam}>
            <Modal.Body>
              <div className="py-3">
                <p>Seleccione el nuevo grupo del alumno</p>
                <select 
                  className="form-control"
                  onChange={changeSelectTeam}>
                  { changeTeamRequest && teams.map((team, index) => {
                      if (index !== changeTeamRequest.fromTeam) {
                        return (<option value={index} key={`to_${index}`}>{team.name}</option>);
                      }
                      return null;
                    }) 
                  }
                </select>
                <div className="text-right pt-4">
                  <Button
                    color="link" 
                    label="Cancelar"
                    onClick={toogleAlertChangeTeam}
                  />
                  <Button 
                    color="primary"
                    label="Cambiar equipo"
                    onClick={changeMemberTeam}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
      <Row>
        <Col>
          <Modal
            centered
            show={isRandomTeamsOpen}
            onHide={toogleRandomTeamsModal}>
            <Modal.Body>
              <div className="py-3">
                <TextField  
                  label="¿Cuántos equipos deseas crear?"
                  name="numMembers"
                  type="numeric"
                  value={numRandomTeams}
                  onChange={changeNumRandomTeamsHandler}
                />
                <div className="alert alert-info">
                  <p>Se crearan</p>
                  <ul>
                    { calculatedTeams && calculatedTeams.map((item, index) => {
                        return (
                          <li key={`calculated_team_${index}`}>
                            { `${item.numTeams} equipos de ${item.numMembers} miembros` }
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
                <div className="form-actions text-right">
                  <Button 
                    label="Cancelar"
                    color="link"
                    onClick={toogleRandomTeamsModal}
                  />
                  <Button 
                    label="Crear equipos"
                    color="primary"
                    className="ml-2"
                    disabled={!enableCreateRandom}
                    onClick={createRandomTeamsHandle}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default FormChallengeTeams;