import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarGroup from '../../components/navbar/navbar-group';
import ROUTES from '../../const/routes.const';
import { groupService, campaignsService, teamChallengeService } from '../../services';
import CampaignCard from '../campaigns/campaign-card';
import ModalFormCampaign from '../campaigns/modal-form-campaign/modal-form-campaign';
import ModalTypeEvent from '../../components/modal-type-event/modal-type-event';
import ModalFormTeamChallenge from '../team-challenges/form-team-challenge/modal-form-team-challenge';
import { store } from 'react-notifications-component';
import TeamChallengeCard from '../team-challenges/team-challenge-card';
import EVENT_STATUS from '../../const/event-status.const';

class GroupEvents extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      group: {
        _id: this.props.match.params.group || '',
        pin: '',
        institute: {
          _id: ''
        },
      },
      campaigns: [],
      filterCampaigns: [],
      filterStatus: 'all',
      isModalSingleOpen: false,
      isOpenSelectType: false,
      editEvent: null,
    }

    this.toogleModalSingleHandler = this.toogleModalSingleHandler.bind(this);
    this.toogleModalTeamHandler = this.toogleModalTeamHandler.bind(this);
    this.savedTeamHandler = this.savedTeamHandler.bind(this);
    this.toogleSelectEventType = this.toogleSelectEventType.bind(this);
    this.selectTypeHandler = this.selectTypeHandler.bind(this);
    this.savedCampaignHandler = this.savedCampaignHandler.bind(this);
    this.onClickEventHandler = this.onClickEventHandler.bind(this);
  }

  STATUS = {
    ALL: 'all',
    ACTIVE: 'active',
    DRAFT: 'draft',
    CLOSED: 'closed'
  } 

  componentDidMount() {
    this.getGroup();
    this.getEvents();
  }

  async getGroup() {
    try {
      const groupId = this.props.match.params.group;
      const response = await groupService.getGroupDetail(groupId);
      this.setState({
        group: response.data
      });
    } catch(error) {
      console.log(error);
    }
  }

  async getEvents() {
    try {
      const groupId = this.props.match.params.group;
      const resSingle = await campaignsService.getCampaignsGroup(groupId);
      let singleEvents = resSingle.data;
      const resTeam = await teamChallengeService.getByGroup(groupId);
      let teamEvents = resTeam.data;
      let events = [...singleEvents, ...teamEvents];
      events.sort((a, b) => {
        //Primero ordena por status: activos, cerrados, borradores, luego por fecha acorde a status
        if (a.status > b.status) {
          return 1;
        }

        if (a.status < b.status) {
          return -1;
        }

        if (a.status === 'active') {
          return new Date(b.startDate) - new Date(a.startDate)
        }

        if (a.status === 'closed') {
          return new Date(b.completionDate) - new Date(a.completionDate)
        } else {
          //borradores
          return new Date(b.createdAt) - new Date(a.createdAt)
        }
      });
      this.setState({
        campaigns: events,
        filterCampaigns: events,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getCampaigns() {
    try {
      const groupId = this.props.match.params.group;
      const response = await campaignsService.getCampaignsGroup(groupId);
      this.setState({
        campaigns: response.data,
        filterCampaigns: response.data,
      });
    } catch(error) {
      console.log(error);
    }
  }

  applyFilterHandler(status) {
    if (status === this.STATUS.ALL) {
      this.setState({ 
        filterCampaigns: this.state.campaigns,
        filterStatus: status
      });
    } else {
      let filterCampaigns = this.state.campaigns.filter(item => item.status === status);
      this.setState({ 
        filterCampaigns,
        filterStatus: status 
      });
    }
  }

  toogleModalSingleHandler() {
    if (this.state.isModalSingleOpen) {
      this.setState({ 
        isModalSingleOpen: false,
        editEvent: null 
      });
    } else {
      this.setState({ isModalSingleOpen: true });
    }
  }

  toogleModalTeamHandler() {
    if (this.state.isModalTeamOpen) {
      this.setState({ 
        isModalTeamOpen: false,
        editEvent: null 
      });
    } else {
      this.setState({ isModalTeamOpen: true });
    }
  }

  savedTeamHandler() {
    this.getEvents();
    this.toogleModalTeamHandler();
    store.addNotification({
      message: "El evento ha sido creado",
      type: "success",
      insert: "top",
      container: "bottom-center",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  savedCampaignHandler() {
    this.getEvents();
    this.toogleModalSingleHandler();
    store.addNotification({
      message: "El evento ha sido creado",
      type: "success",
      insert: "top",
      container: "bottom-center",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  toogleSelectEventType() {
    this.setState({ isOpenSelectType: !this.state.isOpenSelectType });
  }

  selectTypeHandler(typeEvent) {
    this.toogleSelectEventType();
    if (typeEvent === 'team') {
      this.toogleModalTeamHandler();
    } else {
      this.toogleModalSingleHandler();
    }
  }

  onClickEventHandler(event) {
    if (event.numQuestions) {
      if (event.status === EVENT_STATUS.DRAFT) {
        this.setState({ editEvent: event._id }, this.toogleModalTeamHandler);
      } else {
        this.props.history.push(ROUTES.TEAM_CHALLENGES.DETAIL.replace(':challenge', event._id));
      }
    } else {
      if (event.status === EVENT_STATUS.DRAFT) {
        this.setState({ editEvent: event._id }, this.toogleModalSingleHandler);
      } else {
        this.props.history.push(ROUTES.CAMPAIGNS.DETAIL.replace(':campaign', event._id));
      }
    }
  }

  _renderCampaigns() {
    return this.state.filterCampaigns.map(campaign => {
      return (
        <div key={campaign._id} className="col-md-6">
          { campaign.numQuestions
            ? (<TeamChallengeCard 
                challenge={campaign} 
                onClick={() => this.onClickEventHandler(campaign)}
              />) 
            : (<CampaignCard 
                campaign={campaign} 
                onClick={() => this.onClickEventHandler(campaign)}
              />)
          }
        </div>
      );
    });
  }

  render() {
    const { group }  = this.state;
    return (
      <Layout 
        navBar={
          <NavbarGroup 
            linkBack={ROUTES.INSTITUTES.GROUPS.replace(':id', group.institute._id)}
            titleNav={group.name}
            groupPin={group.pin}
            currentOption={this.props.match.url}
            groupID={this.state.group._id}
            searchBar={false}
          />
        }
      >
        <div className="row mb-4">
          <div className="col-md-8">
            <ul className="nav nav-filters">
              <li className="nav-item">
                <button 
                  className={`nav-link ${this.state.filterStatus === this.STATUS.ALL ? 'active' : ''} d-flex flex-column align-items-center`} 
                  onClick={() => this.applyFilterHandler(this.STATUS.ALL)}
                >
                  <i className="material-icons">view_module</i>
                  <span>Todas</span>
                </button>
              </li>
              <li className="nav-item">
                <button 
                  className={`nav-link ${this.state.filterStatus === this.STATUS.ACTIVE ? 'active' : ''} d-flex flex-column align-items-center`} 
                  onClick={() => this.applyFilterHandler(this.STATUS.ACTIVE)}
                >
                  <i className="material-icons">wb_sunny</i>
                  <span>Activas</span>
                </button>
              </li>
              <li className="nav-item">
                <button 
                  className={`nav-link ${this.state.filterStatus === this.STATUS.CLOSED ? 'active' : ''} d-flex flex-column align-items-center`} 
                  onClick={() => this.applyFilterHandler(this.STATUS.CLOSED)}
                >
                  <i className="material-icons">alarm_off</i>
                  <span>Finalizadas</span>
                </button>
              </li>
              <li className="nav-item">
                <button 
                  className={`nav-link ${this.state.filterStatus === this.STATUS.DRAFT ? 'active' : ''} d-flex flex-column align-items-center`} 
                  onClick={() => this.applyFilterHandler(this.STATUS.DRAFT)}
                >
                  <i className="material-icons">save</i>
                  <span>Borradores</span>
                </button>
              </li>
            </ul>  
          </div>
          <div className="col-md-4">
            <div style={{ flexWrap: 'nowrap', display: 'flex', justifyContent: 'flex-end'}}>
              <button
                type="button"
                className="btn btn-primary btn-rounded btn-material-icon"
                onClick={this.toogleSelectEventType}
              >
                <i className="material-icons">add</i>
                <span>Crear</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {this._renderCampaigns()}
        </div>
        <ModalFormCampaign 
          isOpen={this.state.isModalSingleOpen}
          group={this.state.group._id}
          onClose={this.toogleModalSingleHandler}
          onSaved={this.savedCampaignHandler}
          editCampaign={this.state.editEvent}
        />
        <ModalFormTeamChallenge 
          isOpen={this.state.isModalTeamOpen}
          onHide={this.toogleModalTeamHandler}
          onSaved={this.savedTeamHandler}
          group={this.state.group._id}
          editChallenge={this.state.editEvent}
        />
        <ModalTypeEvent
          isOpen={this.state.isOpenSelectType}
          onClose={this.toogleSelectEventType}
          onSelectType={this.selectTypeHandler}
        />
      </Layout>
    );
  }
}

export default GroupEvents;