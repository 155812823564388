import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarGroup from '../../components/navbar/navbar-group';
import ROUTES from '../../const/routes.const';
import { groupService } from '../../services';
import CardGroupAccuracy from '../../components/card-group-accuracy/card-group-accuracy';
import CardGroupSettings from './card-group-settings';
import { statsServices } from '../../services/stats.service';

class GroupDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: {
        _id: this.props.match.params.group || '',
        pin: '',
        institute: {
          _id: ''
        },
      },
      accuracyStats: null
    }
  }

  componentDidMount() {
    const groupId = this.props.match.params.group;
    this.getGroup();
    this.getAccuracyStats(groupId);
    this.getBestThemes(groupId);
  }

  async getGroup() {
    try {
      const groupId = this.props.match.params.group;
      const response = await groupService.getGroupDetail(groupId);
      this.setState({
        group: response.data
      });
    } catch(error) {
      console.log(error);
    }
  }

  async getAccuracyStats(group) {
    try {
      const response = await statsServices.getGroupAccuracy(group);
      this.setState({ accuracyStats: response.data });
    } catch (error) {
      console.log(error);
    }
  }

  async getBestThemes(group) {
    try {
      const response = await statsServices.getGroupThemePerformance(group);
      let themes = response.data;
      this.setState({ statsThemes: themes });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { group }  = this.state;

    return (
      <Layout 
        navBar={
          <NavbarGroup 
            linkBack={ROUTES.INSTITUTES.GROUPS.replace(':id', group.institute._id)}
            titleNav={group.name}
            groupPin={group.pin}
            currentOption={this.props.match.url}
            groupID={this.state.group._id}
            searchBar={false}
          />
        }
      >
        <div className="row">
          <div className="col-md-8">
            <div className="card mb-3">
              <div className="card-body">
                <p>Bienvenido al espacio de administración de tu grupo <strong>{group.name}</strong>.</p>
                <p>Si deseas que tus alumnos del grupo puedan participar en retos amistosos entre sí, debes:</p>
                <ol>
                  <li>Configurar los retos amistosos.</li>
                  <li>Vincular el o los cuestionarios que estarán habilitados para los retos, en la pestaña superior derecha <i>"Cuestionarios del grupo"</i>.</li>
                  <li>Compartir a tus alumnos el Pin del grupo <strong>{group.pin}</strong>.</li>
                </ol>
                <p>Para consultar qué alumnos del grupo ya entraron a la aplicación correctamente, consulta la pestaña superior <i>"Alumnos"</i>. Recuerda que la única forma de que entren a la aplicación es utilizando el Pin del grupo. También es imporante recordar que los alumnos eligen el nombre o nickname con el que aparecen por lo que, si requieres que se registren de forma específica con una clave, su nombre real, su número de lista o alguna otra forma en la que te facilite identificarlos, comunícaselos claramente cuando les compartas el Pin del grupo.</p>
                <p>Para crear eventos especiales como competencias individuales o competencias por equipos que estén delimitadas en el tiempo, debes utilizar la pestaña superior derecha <i>"Eventos especiales"</i>.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <CardGroupSettings group={group._id} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CardGroupAccuracy
              stats={this.state.accuracyStats}
              statsThemes={this.state.statsThemes}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default GroupDashboard;