import React from 'react';
import LANG from '../../const/language.const';
import ROUTES from '../../const/routes.const';
import MenuNav from './menu-nav';
import SearchBar from '../ui/search-bar';

function NavbarPrimary(props) {
  const menuOptions=[
    {
      label: LANG.NAVBAR.QUIZZES,
      icon: 'view_module',
      link: ROUTES.HOME
    },
    {
      label: LANG.NAVBAR.INSTITUTES,
      icon: 'apartment',
      link: ROUTES.INSTITUTES.ROOT
    }
  ];

  let enableActions = props.hideActions ? false : true;

  return (
    <div className="navbar navbar-primary">
      <MenuNav 
        navOptions={menuOptions}
        currentUri={props.currentOption}
      />
      <div className="navbar-search">
        <SearchBar
          value={props.searchQuery}
          showCancelSearch={true}
          onChange={props.searchHandler}
        />
      </div>
      <div className="actions">
        { enableActions &&
          <button
            type="button"
            className="btn btn-primary btn-rounded btn-material-icon"
            onClick={props.actionHandler}>
            <i className="material-icons">add</i>
            <span>{props.actionText}</span>
          </button>
        }
      </div>
    </div>
  );
}

export default NavbarPrimary;