import React, { Component } from 'react'
import { TextFieldDecorate, Icon, Button } from '../../components/ui'
import LANG from '../../const/language.const';
import AuthService from '../../services/auth.service';
import LocalStorageService from '../../services/local-storage.service';
import ROUTES from '../../const/routes.const';
import { LANDING_PAGE, PRIVACY_URL, TERMS_URL } from '../../enviroments/enviroment';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: ''
    }

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    const session = LocalStorageService.session();
    if (session) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  onChangeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
      error: '',
    });
  }

  async submitForm() {
    try {
      const { email, password } = this.state;
      const response = await AuthService.login(email, password);
      let data = response.data;
      LocalStorageService.loginSession(data);
      if (data.academic.onboardingVerified) {
        this.props.history.push(ROUTES.HOME);
      }else{
        let route = ROUTES.ONBOARDING.ROOT.replace('/:review', '');
        this.props.history.push(route);
      }
    } catch (e) {
      if (e.response && e.response.data) {
        this.setState({ error: e.response.data.message });
      }
    }
  }

  render() {
    return (
      <div className="wrap-auth">
        <div className="card card-auth">
          <div className="card-header">
            <h2>{LANG.AUTH.LOGIN.TITLE_FORM}</h2>
          </div>
          <div className="card-body">
            <div className="alert-messages">
              {this.state.error &&
                <div className="alert alert-danger">{this.state.error}</div>
              }
            </div>
            <form>
              <TextFieldDecorate
                type="email"
                name="email"
                placeholder={LANG.AUTH.LOGIN.LABEL_EMAIL}
                leftDecorate={<Icon name="person" color="primary" />}
                value={this.state.email}
                onChange={this.onChangeHandler}
              />
              <TextFieldDecorate
                type="password"
                name="password"
                placeholder={LANG.AUTH.LOGIN.LABEL_PASSWORD}
                leftDecorate={<Icon name="vpn_key" color="primary" />}
                value={this.state.password}
                onChange={this.onChangeHandler}
              />
              <div className="mt-4">
                <Button
                  color="primary"
                  label="Iniciar sesión"
                  icon="login"
                  onClick={this.submitForm}
                />
              </div>
            </form>
            <div className="login-actions">
              <div className="mb-3">
                <Button
                  color="outline-primary"
                  label="No tengo cuenta"
                  icon="face"
                  onClick={() => this.props.history.push(ROUTES.AUTH.REGISTER)}
                />
              </div>
              <div className="mb-3">
                <Button
                  color="outline-primary"
                  label="No recuerdo mi contraseña"
                  icon="sentiment_very_dissatisfied"
                  onClick={() => this.props.history.push(ROUTES.AUTH.FORGOT_PASSWORD)}
                />
              </div>
            </div>
            <div className="footer-copy">
              <a href={LANDING_PAGE}>
                <img src={require('../../assets/images/Logo-Positive.svg')} alt="GameIF Academy" />
              </a>
              <div className="mt-3">{LANG.AUTH.COPY.TEXT}</div>
              <div>
                <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.TERMS}</a>
                <span> • </span>
                <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.PRIVACY}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;