import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarPrimary from '../../components/navbar/navbar-primary';
import LANG from '../../const/language.const';
import LocalStorageService from '../../services/local-storage.service';
import { quizzesServices } from '../../services';
import swal from '@sweetalert/with-react';
import { accentFold } from '../../utilities/helpers';
import ROUTES from '../../const/routes.const';
import CardQuiz from '../../components/cards/card-quiz';
import ModalFormQuiz from './modal-form-quiz';
import { Button, Icon } from '../../components/ui';

class Quizzes extends Component {
  constructor(props) {
    super(props);

    const currentAcademic = LocalStorageService.session();
    this.state = {
      academic: currentAcademic || null,
      quizzes: [],
      filterQuizzes: [],
      search: '',
      isModalOpen: false,
      editQuiz: null,
    };

    this.getQuizzes = this.getQuizzes.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
    this.addQuizHandler = this.addQuizHandler.bind(this);
    this.editQuizHandler = this.editQuizHandler.bind(this);
    this.deleteQuizHandler = this.deleteQuizHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.goToQuizDetail = this.goToQuizDetail.bind(this);
    this.saveQuiz = this.saveQuiz.bind(this);
  }

  async componentDidMount() {
    await this.getQuizzes();
  }

  async getQuizzes() {
    try {
      if (this.state.academic) {
        const response = await quizzesServices.getByOwner(this.state.academic._id);
        this.setState({ 
          quizzes: response.data,
          filterQuizzes: response.data, 
          search: '',
          isModalOpen: false,
          editQuiz: null,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        this.onError(error.response.data.message);
      } else {
        this.onError(LANG.ERRORS.UNEXPECTED_ERROR);
      }
    }
  }

  closeModalHandler() {
    this.setState({
      editQuiz: null,
      isModalOpen: false
    });
  }

  addQuizHandler() {
    let newQuiz = {
      name: "",
      level: "",
      degree: "",
      topic: "",
      owner: this.state.academic._id,
      author: this.state.academic._id,
      type: "PRIVATE"
    };

    this.setState({ 
      editQuiz: newQuiz,
      isModalOpen: true 
    });
  }

  searchHandler(query) {
    const { quizzes } = this.state;
    let results = quizzes;

    if (query) {
      let strSearch = accentFold(query);
      let expSearch = new RegExp(`${strSearch}.*`, 'i');
      results = quizzes.filter(item => {
        return expSearch.test(accentFold(item.name));
      });
    }

    this.setState({ 
      search: query,
      filterQuizzes: results 
    });
  }

  goToQuizDetail(quiz) {
    this.props.history.push(ROUTES.QUIZZES.QUESTIONS.replace(':quiz',quiz));
  }

  async deleteQuizHandler(quiz) {
    const confirm = await swal({
      text: `¿Seguro que quieres eliminar el cuestionario "${quiz.name} (${quiz.topic})"?`,
      icon: 'warning',
      dangerMode: true,
      buttons: [
        'Cancelar',
        'Si, borrar'
      ]
    });

    if (!confirm) {
      return false;
    }

    try {
      await quizzesServices.deleteQuiz({ _id: quiz._id });
      this.onSucces();
      this.getQuizzes();
    } catch (error) {
      this.onError(error.response.data.message)
    }
  }

  editQuizHandler(quiz) {
    let editQuiz = Object.assign({}, quiz);
    editQuiz.level = editQuiz.level._id;
    editQuiz.degree = editQuiz.degree._id;
    delete editQuiz.deleted;
    delete editQuiz.questions;

    this.setState({
      editQuiz,
      isModalOpen: true
    });
  }

  async saveQuiz(quiz) {
    try {
      if (quiz._id) {
        await quizzesServices.update(quiz);
      } else { 
        await quizzesServices.create(quiz);
      }
      this.onSucces();
      this.getQuizzes();
    } catch (error) {
      this.onError('Ocurrio un error al guardar los datos');
    } 
  }

  onError(message) {
    swal({
      title: 'Error',
      text: message,
      icon: 'error',
      button: 'ok',
    });
  }

  async onSucces() {
    await swal({
      title: '¡Excelente!',
      text: 'El cuestionario ha sido guardado exitosamente. El siguiente paso es agregarle preguntas. Para ello da clic sobre la tarjeta de tu nuevo cuestionario.',
      icon: 'success',
      button: 'ok',
    });
  }
  
  _renderQuizzes() {
    return this.state.filterQuizzes.map(item => {
      return (
        <div key={item._id} className="col-12 col-sm-6 col-md-4">
          <CardQuiz 
            quiz={item}
            showDetail={this.goToQuizDetail}
            delete={this.deleteQuizHandler}
            update={this.editQuizHandler}
          />
        </div>
      );
    });
  }

  _renderEmpty() {
    return (
      <div className="col-md-6 offset-md-3 text-center">
        <p><strong>¡Bienvenido a Gameif Academy!</strong></p>
        <p>Estás a unos pasos de comenzar a brindarle a tus alumnos una experiencia de aprendizaje y retención del mismo basada en el juego.</p>
        <p>Una buena forma de comenzar es creando cuestionarios. Los cuestionarios son los bancos de preguntas que habilitarás para que tus alumnos puedan participar en los juegos de trivia. Crea un cuestionario por cada uno de los temas que quieras reforzar con tus alumnos.</p>
      </div>
    )
  }

  render() {
    return (
      <Layout
        navBar={
          <NavbarPrimary 
            currentOption={this.props.match.url}
            searchQuery={this.state.search}
            searchHandler={this.searchHandler}
            hideActions={true}
          />
        }
      >
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-info d-flex">
              <div className="mr-3"><Icon name="info" /></div>
              <p style={{fontSize: '0.85rem'}}> **Los cuestionarios son los bancos de preguntas que utilizarás en las diferentes instituciones y grupos en los que impartes clases. Por ello los cuestionarios se crean de forma independiente de las instituciones permitiéndote utilizar un mismo cuestionario en cualquiera de las instituciones en las que impartes clases.</p>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col text-right">
            {/* <Button 
              color="outline-primary"
              icon="storefront"
              label="Marketplace"
              className="mr-2"
              onClick={() => this.props.history.push(ROUTES.MARKETPLACE.ROOT)}
            /> */}
            <Button 
              color="primary"
              icon="add"
              label={LANG.QUIZZES.BTN_ADD}
              onClick={this.addQuizHandler}
            />
          </div>
        </div>
        
        <div className="row mt-4">
          { this.state.quizzes.length > 0 ? this._renderQuizzes() : this._renderEmpty() }
        </div>
        { 
          this.state.editQuiz &&
          <ModalFormQuiz 
            dataQuiz={this.state.editQuiz}
            isOpen={this.state.isModalOpen}
            onClose={this.closeModalHandler}
            saveQuiz={this.saveQuiz}
          />
        }
      </Layout>
    );
  }
}

export default Quizzes;