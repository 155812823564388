import React from 'react';

function Checkbox({
  name,
  id,
  inline,
  checked,
  value,
  label,
  onChange
}) {
  const containerClasses = `custom-control custom-checkbox ${inline ? 'custom-control-inline' : ''}`;
  const checkboxId = id ? id : `customCheckbox-${name}`;

  return (
    <div className={containerClasses}>
      <input 
        type="checkbox" 
        id={checkboxId} 
        name={name} 
        className="custom-control-input" 
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label 
        className="custom-control-label" 
        htmlFor={checkboxId}
      >
        {label}
      </label>
    </div>
  );
}

export default Checkbox;