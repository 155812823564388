import React from 'react';

function TextFieldDecorate({
  value, 
  label, 
  name,
  placeholder, 
  disabled,
  type, 
  customClasses,
  errorMessage,
  onChange,
  readonly,
  counter,
  counterLimit,
  leftDecorate,
  rightDecorate,
  ...otherProps
}) {
  const inputType = type ? type : 'text';
  const containerClasses = `form-group ${errorMessage ? 'has-error' : ''} ${customClasses ? customClasses : ''}`
  const isDisabled = disabled ? true : false;
  const isReadOnly = readonly ? true : false;
  let valueLength = value ? value.length : 0;
  let classCounter = counter && valueLength > counterLimit ? 'tf-count text-warning' : 'tf-count';

  return (
    <div className={containerClasses}>
      {label && <label>{label}</label>}
      <div className="input-group mb-3 fg-line">
        { 
          leftDecorate &&
          <div className="input-group-prepend">
            <span className="input-group-text text-accent">{leftDecorate}</span>
          </div>
        }
        <input
          type={inputType}
          name={name}
          placeholder={placeholder}
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
          value={value}
          onChange={onChange}
          {...otherProps}
        />
        { 
          rightDecorate &&
          <div className="input-group-append">
            <span className="input-group-text text-accent">{rightDecorate}</span>
          </div>
        }
      </div>
      <div className="help-block">
        { errorMessage && <span>{errorMessage}</span> }
        { counter && <span className={classCounter}>{`${valueLength}/${counterLimit}`}</span> }
        <div className="clearfix"></div>
      </div>
    </div>
  )
}

export default TextFieldDecorate;