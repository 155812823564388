import React, { Component } from 'react';
import LayoutMarket from '../../components/layout-market/layout-market';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { Button, Icon } from '../../components/ui';
import SearchBar from '../../components/ui/search-bar';
import CardQuizShop from './card-quiz/card-quiz-shop';
import CardQuizFeatured from './card-quiz/card-quiz-featured';

class Marketplace extends Component {
  render() {
    return (
      <LayoutMarket>
        <div className="row mb-3">
          <div className="col">
            <Tab.Container id="left-tab-mk" defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <Nav className="flex-column nav-mk-featured">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Mejor calificadas</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Lo más vendido</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Novedades</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <CardQuizFeatured quiz={null} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <CardQuizFeatured quiz={null} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <CardQuizFeatured quiz={null} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
        {/* filters */}
        <div className="row align-items-center mb-4">
          <div className="col-md-6">
            <div style={{maxWidth: '250px', display: 'inline-block', marginRight: '12px'}}>
              <SearchBar />
            </div>
            <Button 
              color="outline-primary"
              label="Filtros"
              icon="filter_list"
            />
          </div>
          <div className="col-md-6">
            <ul className="nav nav-mk-filter-types float-right">
              <li className="nav-item">
                <button className="btn active">
                  <Icon name="view_module" />
                  <span>Todos</span>
                </button>
              </li>
              <li className="nav-item">
                <button className="btn">
                  <Icon name="monetization_on" />
                  <span>Premium</span>
                </button>
              </li>
              <li className="nav-item">
                <button className="btn">
                  <Icon name="tag_faces" />
                  <span>Gratuito</span>
                </button>
              </li>
              <li className="nav-item">
                <button className="btn">
                  <Icon name="visibility" />
                  <span>En revisión</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <CardQuizShop 
              quiz={null}
              enabledCartBtn={true}
            />
          </div>
        </div>
      </LayoutMarket>
    );
  }
}

export default Marketplace;