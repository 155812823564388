import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarQuiz from '../../components/navbar/navbar-quiz';
import ROUTES from '../../const/routes.const';
import { quizzesServices } from '../../services';
import { Avatar, Button } from '../../components/ui';
import ModalQuizShared from './modal-quiz-shared';

class QuizCommunity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quiz: {
        _id: this.props.match.params.quiz || null,
        name: '',
        questions: [],
        type: 'PRIVATE'
      }, 
      isOpenShare: false
    }

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  componentDidMount() {
    let quizID = this.props.match.params.quiz;
    this.getQuiz(quizID);
  }

  toogleShareModal() {
    this.setState({
      isOpenShare: !this.state.isOpenShare
    });
  }

  async getQuiz() {
    try {
      const quizID = this.props.match.params.quiz;
      const response = await quizzesServices.quizDetail(quizID);
      this.setState({
        quiz: response.data
      });
    } catch (error) {
      console.log('ERROR', error);
    }
  }

  _renderInvitationCommunity() {
    return(
      <div className="card-share-communnity mt-5">
        <Avatar 
          icon="storefront"
          className="avatar-lg"
        />
        <h3>Comunidad Gameif academy</h3>
        <p className="mb-4">Comparte tus cuestionarios con la comunidad de profesionales activos en Gameif Store.<br/>
          Utiliza y recibe retroalimentaciones de los usuarios para mejorar tus cuestionarios y poder monetizar con ellos.<br/>
          Puedes compartir tus cuestionarios para uso libre o monetizados.<br/>
          Los cuestionarios monetizados debe alcanzar un mínimo de valoraciones positivas, una vez alcanzando las valoraciones puedes comenzar a monetizar con tu cuestionario.<br/>
        </p>
        <Button 
          color="primary"
          label="Compartir cuestionario"
          icon="storefront"
          onClick={this.toogleShareModal}
        />
      </div>
    );
  }

  render() {
    const { quiz } = this.state;
    return (
      <Layout
        navBar={
          <NavbarQuiz 
            linkBack={ROUTES.QUIZZES.ROOT}
            titleNav={quiz.name}
            deleteQuiz={this.deleteQuiz}
            updateQuiz={this.editQuiz}
            quiz={quiz._id}
            currentOption={this.props.match.url}
          />
        }
      >
        <h2 className="mk-title">Información de uso</h2>
        <div className="row">
          <div className="col">
            <div className="card-deck">
              <div className="card card-stats">
                <div className="card-body">
                  <Avatar icon="person" />
                  <div className="stat-data">100k</div>
                  <div className="stat-desc">Alumnos jugaron con el cuestionario</div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-body">
                  <Avatar icon="tag_faces" />
                  <div className="stat-data">80%</div>
                  <div className="stat-desc">Alumnos respondieron correctamente</div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-body">
                  <Avatar icon="sentiment_very_dissatisfied" />
                  <div className="stat-data">20%</div>
                  <div className="stat-desc">Alumnos respondieron incorrectamente</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { quiz.type === 'PRIVATE' && this._renderInvitationCommunity() }
        <ModalQuizShared 
          quizId={quiz._id}
          quizName={quiz.name}
          isOpen={this.state.isOpenShare} 
          onHide={this.toogleShareModal} 
        />
      </Layout>
    );
  }
}

export default QuizCommunity;