import React from 'react';
import { Link } from 'react-router-dom';
import MenuNav from './menu-nav';
import ROUTES from '../../const/routes.const';
import { Icon } from '../ui';


function NavbarTeamChallenge(props) {
  const menuOptions=[
    {
      label: 'Información',
      icon: 'info',
      link: ROUTES.TEAM_CHALLENGES.DETAIL.replace(':challenge', props.challengeID)
    },
    {
      label: 'Estadísticas',
      icon: 'local_fire_department',
      link: ROUTES.TEAM_CHALLENGES.STATS.replace(':challenge', props.challengeID)
    }
  ];

  return (
    <div className="navbar navbar-campaign">
      <div className="nav-back">
        <Link to={props.linkBack}>
          <i className="material-icons arrow-back">arrow_back</i>
        </Link>
      </div>
      <div className="navbar-title">
        <span className="text-primary">{props.titleNav}</span><br/>
        <span className="text-accent">
          <Icon name="people" color="accent" size={18} />&nbsp;Competencia en equipo
        </span>
      </div>
      <MenuNav 
        navOptions={menuOptions}
        currentUri={props.currentOption}
      />
    </div>
  );
}

export default NavbarTeamChallenge;