import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const referencesServices = {
    getByQuiz, 
    getById, 
    create, 
    update, 
    deleteReference,
}

async function getById(_id) {
    return await HTTP.get(`${ROUTES_API.REFERENCES.GET_BY_ID}${_id}`)
}

async function getByQuiz(quiz) {
    return await HTTP.get(`${ROUTES_API.REFERENCES.GET_BY_QUIZ}${quiz}`)
  }

async function create(reference) {
    return await HTTP.post(ROUTES_API.REFERENCES.CREATE, reference )
}
async function update(reference) {
    return await HTTP.post(ROUTES_API.REFERENCES.CREATE, reference )
}
async function deleteReference(reference) {
    return await HTTP.post(ROUTES_API.REFERENCES.DELETE, reference )
}
