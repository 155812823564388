import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../ui/search-bar';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import MenuNav from './menu-nav';
import ROUTES from '../../const/routes.const';

function NavbarQuiz(props) {
  const menuOptions=[
    {
      label: 'Preguntas',
      icon: 'live_help',
      link: ROUTES.QUIZZES.QUESTIONS.replace(':quiz', props.quiz)
    },
    {
      label: 'Bibliografía',
      icon: 'more',
      link: ROUTES.QUIZZES.REFERENCES.replace(':quiz', props.quiz)
    },
    // {
    //   label: 'Comunidad',
    //   icon: 'storefront',
    //   link: ROUTES.QUIZZES.COMMUNITY.replace(':quiz', props.quiz)
    // }
  ];

  return (
    <div className="navbar navbar-quiz">
      <div className="nav-back">
        <Link to={props.linkBack}>
          <i className="material-icons arrow-back">arrow_back</i>
        </Link>
      </div>
      <div className="navbar-title">
        <h4>{props.titleNav}</h4>
        { !props.titleOptions &&
          <div className="dropdown-primary-down ml-2">
            <DropdownButton
              drop='down'
              variant="light btn-circle arrow-none"
              alignRight
              title={<i className="material-icons">more_vert</i>}
              id='dropdown-button-drop'
            >
              <Dropdown.Item eventKey="1" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={props.deleteQuiz} >
                  <span>Borrar</span>
                  <i className="material-icons">delete</i>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={props.updateQuiz} >
                  <span>Editar</span>
                  <i className="material-icons">edit</i>
                </div>
                <div className="arrow-down">
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        }
      </div>
      { !props.onlyTitle &&
        <>
          <div className="navbar-search">
            <SearchBar
              value={props.searchQuery}
              showCancelSearch={true}
              onChange={props.searchHandler}
            />
          </div>
          <MenuNav 
            navOptions={menuOptions}
            currentUri={props.currentOption}
          />
        </>
      }
    </div>
  );
}

export default NavbarQuiz;