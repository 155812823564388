import React from 'react';
import './modal-events.scss';
import { Modal } from 'react-bootstrap';

function ModalEvents(props) {
  const {
    title,
    headerTools,
    children,
    footer,
    showBullets,
    numBullets,
    currectBullet,
    ...modalProps
  } = props;

  const createBullets = (numBullets, currenBullet) => {
    let bullets = [];

    for (let i = 0; i < numBullets; i++) {
      bullets.push(
        <li key={`bullet-${i}`} className={ i < currenBullet ? 'active' : '' }></li>
      );
    }

    return bullets;
  }

  return (
    <Modal
      size="lg"
      className="modal-form-events"
      {...modalProps}
    >
      <Modal.Header className="bg-primary">
        <div className="modal-close mb-3">
          <button 
            type="button" 
            className="btn btn-link text-capitalize"
            onClick={modalProps.onHide}
          >
            <i className="material-icons">close</i>Cerrar
          </button>
        </div>
        <div className="modal-header-title">
          <div className="float-right">
            { showBullets && 
              <ol className="bullets-indicators">
                {
                  createBullets(numBullets, currectBullet).map(item => { return item })
                }
              </ol>
            }
          </div>
          <h4>{ title }</h4>
        </div>
        { headerTools &&
          <div className="modal-header-tools">
            { headerTools } 
          </div>
        }
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      { footer && 
        <Modal.Footer>
          { footer }
        </Modal.Footer>
      }
    </Modal>
  );
}

export default ModalEvents;