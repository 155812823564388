import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

class AuthService {

    validateSession(params) {
        return HTTP.post(ROUTES_API.AUTH.VALIDATE_SESSION, params);
    }

    login(email, password) {
        let params = { email, password };
        return HTTP.post(ROUTES_API.AUTH.LOGIN, params);
    }

    register(account) {
        return HTTP.post(ROUTES_API.AUTH.REGISTER, account);
    }

    forgotPassword(email) {
        return HTTP.post(ROUTES_API.AUTH.FORGOT_PASSWORD, {email});
    }

    validateResetRequest(academic, uuid) {
        let params = {
            id: academic,
            uuid
        };
        return HTTP.post(ROUTES_API.AUTH.VALIDATE_REQUEST_RESET, params);
    }

    changePassword(id, uuid, password) {
        return HTTP.post(ROUTES_API.AUTH.RESET_PASSWORD, { id, uuid, password });
    }

}

const instance = new AuthService();
Object.freeze(instance);

export default instance;