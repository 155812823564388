import React from 'react';

function EventProgressBar(props) {
  const { progress, ...otherProps } = props;

  return (
    <div className="event-progress-bar" {...otherProps}>
      <div className="progress">
        <div 
          className="progress-bar" 
          role="progressbar" 
          aria-valuemin="0" 
          aria-valuemax="100"
          aria-valuenow={progress} 
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
}

export default EventProgressBar;