import React from 'react';

function TextField({
  value, 
  label, 
  name,
  placeholder, 
  disabled,
  type, 
  customClasses,
  errorMessage,
  onChange,
  readonly,
  counter,
  counterLimit
}) {
  const inputType = type ? type : 'text';
  const containerClasses = `form-group ${errorMessage ? 'has-error' : ''} ${customClasses ? customClasses : ''}`
  const isDisabled = disabled ? true : false;
  const isReadOnly = readonly ? true : false;
  let valueLength = value ? value.length : 0;
  let classCounter = counter && valueLength > counterLimit ? 'tf-count text-warning' : 'tf-count';

  return (
    <div className={containerClasses}>
      <div className="fg-line">
        {label && <label>{label}</label>}
        <input
          type={inputType}
          name={name}
          placeholder={placeholder}
          className="form-control"
          disabled={isDisabled}
          readOnly={isReadOnly}
          value={value}
          onChange={onChange}
        />
      </div>
      <div className="help-block">
        { errorMessage && <span>{errorMessage}</span> }
        { counter && <span className={classCounter}>{`${valueLength}/${counterLimit}`}</span> }
        <div className="clearfix"></div>
      </div>
    </div>
  )
}

export default TextField;