import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const institutesServices = {
  create, update, deleteInstitute, 
}

async function create(institute) {
  return await HTTP.post(ROUTES_API.INSTITUTES.CREATE, institute);
}

async function update(institute) {
  return await HTTP.post(ROUTES_API.INSTITUTES.UPDATE, institute);
}

async function deleteInstitute(institute) {
  return await HTTP.post(ROUTES_API.INSTITUTES.DELETE, institute);
}