import React from 'react';
import Header from './header';
import SidebarNotification from '../../views/notifications/sidebar-notification';
import SidebarProfile from '../../views/profile/sidebar-profile';

function Layout(props) {
  const [activeSidebar, setActiveSidebar] = React.useState(false);
  const [activeProfile, setActiveProfile] = React.useState(false);

  const toogleSidebar = () => {
    setActiveSidebar(!activeSidebar)
  }

  const toogleProfile = () => {
    setActiveProfile(!activeProfile)
  }

  return (
    <div id="wrapper">
      <Header 
        onClickNotification={toogleSidebar} 
        onClickProfile={toogleProfile} >
        {props.navBar}
      </Header>
      <div className="page-content">
        <div className="container">
          {props.children}
        </div>
      </div>
      <SidebarNotification 
        activeSidebar={activeSidebar}
        toogleSidebar={toogleSidebar}
      />
      <SidebarProfile 
        activeProfile={activeProfile}
        toogleProfile={toogleProfile}
      />
    </div>
  );
}

export default Layout;