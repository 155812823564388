import React, { Component } from 'react';
import ModalEvents from '../../components/modal-events/modal-events';
import ImageUploader from '../../components/image-uploader/image-uploader';
import { Icon, Radio, TextFieldDecorate, Checkbox, Button } from '../../components/ui';
import { quizzesServices } from '../../services';
import { store } from 'react-notifications-component';
import LANG from '../../const/language.const';

class ModalQuizShared extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.deleteTagHandler = this.deleteTagHandler.bind(this);
    this.addTagHandler = this.addTagHandler.bind(this);
    this.dragTagHandler = this.dragTagHandler.bind(this);
    this.changeTypeShare = this.changeTypeShare.bind(this);
    this.changeAceptTems = this.changeAceptTems.bind(this);
    this.changeDescription = this.changeDescription.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.setInitValues = this.setInitValues.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  getInitialState() {
    return {
      quiz: {
        type_share: 'free',
        description: '',
        image: '',
      },
      tags: [],
      acceptTerms: false,
      errors: null,
      fileImage: null
    }
  }

  setInitValues() {
    let initState = this.getInitialState();
    this.setState(initState);
  }

  changeTypeShare(e) {
    let quiz = this.state.quiz;
    const value = e.target.value;
    quiz.type_share = value;

    this.setState({quiz})

  }

  changeAceptTems(e) {
    let accepted = false;
    if (e.target.checked) {
      accepted = true;
    }

    this.setState({ acceptTerms: accepted })
  }

  changeDescription(e) {
    let quiz = this.state.quiz;
    quiz.description = e.target.value;

    this.setState({ quiz });
  }

  onChangeImage(pictureFiles, pictureDataURLs) {
    let quiz = this.state.quiz;
    let file = pictureFiles.length ? pictureFiles[0] : null;
    quiz.image = pictureDataURLs.length ? pictureDataURLs[0] : '';

    this.setState({
      quiz,
      fileImage: file
    });
  }

  submitForm() {
    let errors = {};
    let validForm = true;

    if (!this.state.quiz.description) {
      errors.description = LANG.ERRORS.REQUIRED_FIELD;
      validForm = false;
    }

    if (!this.state.quiz.image) {
      errors.image = 'Imagen requerida';
      validForm = false;
    }

    if (validForm) {
      this.saveHandler();
    } else {
      this.setState({ errors });
    }
  }

  async saveHandler() {
    let quiz = this.state.quiz;
    quiz._id = this.props.quizId;

    try {
      const response = await quizzesServices.shareQuiz(quiz);
      console.log(response.data);
      store.addNotification({
        message: "Se ha compartido el evento",
        type: "success",
        insert: "top",
        container: "bottom-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      this.props.onHide();
    } catch(e) {
      console.log(e);
    }
  }

  deleteTagHandler(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  addTagHandler(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  dragTagHandler(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  render() {
    const {quiz} = this.state;
    let propsPreviewImage = {
      withPreview: true
    };

    if (quiz.image) {
      propsPreviewImage.defaultImages = [quiz.image];
    }

    return (
      <ModalEvents
        show={this.props.isOpen}
        onHide={this.props.onHide}
        // onEntering={this.loadData}
        onExiting={this.setInitValues}
        title="Compartir cuestionario"
        showBullets={false}
      >
        <div className="form-share-quiz">
          <div className="text-primary mb-3">
            <Icon name="subject" size={18} /> {this.props.quizName}
          </div>
          <div className="mb-3">
            <label className="text-primary mr-3">Compartir como</label>
            <Radio 
              name="type_share"
              id="rd_share_free"
              inline
              checked={quiz.type_share === 'free'}
              value="free"
              label="Gratuito"
              onChange={this.changeTypeShare}
            />
            {/* <Radio 
              name="type_share"
              id="rd_share_pay"
              inline
              checked={quiz.type_share === 'pay'}
              value="pay"
              label="Monetizado"
              onChange={this.changeTypeShare} /> */}
          </div>
          <div className="mb-3">
            <TextFieldDecorate 
              placeholder="Descripción del cuestionario"
              leftDecorate={<Icon name="short_text" />}
              value={this.state.quiz.description}
              errorMessage={this.state.errors && this.state.errors.description }
              onChange={this.changeDescription}
            />
          </div>
          <div className="mb-3">
            <div className="text-danger">
              { this.state.errors && this.state.errors.image }
            </div>
            <ImageUploader
              withPreview={true}
              onChange={this.onChangeImage}
              withIcon={false}
              buttonClassName="btn btn-primary btn-rounded btn-material-icon d-inline-flex"
              buttonText={
                <>
                  <i className="material-icons">add_photo_alternate</i>
                  <span>Seleccionar imagen</span>
                </>
              }
              fileSizeError=" el archivo es demasiado grande"
              fileTypeError=" el tipo de archivo no es soportado"
              buttonType="button"
              singleImage={true}
              {...propsPreviewImage}
            />
          </div>
          <div className="mb-4">
            <Checkbox 
              name="aceptTerms"
              id="aceptTerms"
              label="Acepto los terminos y condiciones"
              checked={this.state.acceptTerms}
              value="1"
              onChange={this.changeAceptTems}
            />
          </div>
          <div className="text-right">
            <Button 
              disabled={!this.state.acceptTerms}
              color="outline-primary"
              icon="storefront"
              label="Compartir cuestionario"
              onClick={this.submitForm}
            />
          </div>
        </div>
      </ModalEvents>
    );
  }
}

export default ModalQuizShared;