import React from 'react';

function Icon(props) {
  const { color, name, size } = props;
  const iconColor = color ? `text-${color}` : '';
  const iconSize = size ? `md-${size}` : '';
  const iconClasses = `material-icons ${iconColor} ${iconSize}`;

  return (
    <i className={iconClasses}>{name}</i>
  );
}

export default Icon;