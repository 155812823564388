import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarPrimary from '../../components/navbar/navbar-primary';
import CardInstitute from '../../components/cards/card-institute';
import LANG from '../../const/language.const';
import LocalStorageService from '../../services/local-storage.service';
import { academicServices, institutesServices } from '../../services';
import ModalFormInstitute from './modal-form-institute';
import swal from '@sweetalert/with-react';
import { accentFold } from '../../utilities/helpers';
import ROUTES from '../../const/routes.const';
import { Icon } from '../../components/ui';

class Institutes extends Component {
  constructor(props) {
    super(props);

    const currentAcademic = LocalStorageService.session();
    this.state = {
      academic: currentAcademic || null,
      institutes: [],
      filterInstitutes: [],
      search: '',
      isModalOpen: false,
      editInstitute: null,
    };

    this.getInstitutes = this.getInstitutes.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
    this.addInstituteHandler = this.addInstituteHandler.bind(this);
    this.editInstitueHandler = this.editInstitueHandler.bind(this);
    this.deleteInstituteHandler = this.deleteInstituteHandler.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.goToDetail = this.goToDetail.bind(this);
    this.saveInstitute = this.saveInstitute.bind(this);
  }

  async componentDidMount() {
    await this.getInstitutes();
  }

  async getInstitutes() {
    try {
      if (this.state.academic) {
        const response = await academicServices.getAcademicInstitutes(this.state.academic._id);
        this.setState({ 
          institutes: response.data,
          filterInstitutes: response.data, 
          search: '',
          isModalOpen: false,
          editInstitute: null,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        this.onError(error.response.data.message);
      } else {
        this.onError(LANG.ERRORS.UNEXPECTED_ERROR);
      }
    }
  }

  closeModalHandler() {
    this.setState({
      editInstitute: null,
      isModalOpen: false
    });
  }

  addInstituteHandler() {
    let newInstitute = {
      name: '',
      country: '',
      state: '',
      address: ''
    };

    this.setState({ 
      editInstitute: newInstitute,
      isModalOpen: true 
    });
  }

  searchHandler(query) {
    const { institutes } = this.state;
    let results = institutes;

    if (query) {
      let strSearch = accentFold(query);
      let expSearch = new RegExp(`${strSearch}.*`, 'i');
      results = institutes.filter(item => {
        return expSearch.test(accentFold(item.name));
      });
    }

    this.setState({ 
      search: query,
      filterInstitutes: results 
    });
  }

  goToDetail(institute) {
    this.props.history.push(ROUTES.INSTITUTES.GROUPS.replace(':id', institute));
  }

  async deleteInstituteHandler(institute) {
    const confirm = await swal({
      text: `¿Seguro que quieres eliminar el instituto "${institute.name}"?`,
      icon: 'warning',
      dangerMode: true,
      buttons: [
        'Cancelar',
        'Si, borrar'
      ]
    });

    if (!confirm) {
      return false;
    }

    try {
      await institutesServices.deleteInstitute({ "_id": institute._id });
      this.onSucces();
      this.getInstitutes();
    } catch (error) {
      this.onError(error.response.data.message)
    }
  }

  editInstitueHandler(institute) {
    let editInstitute = Object.assign({}, institute);
    editInstitute.country = editInstitute.country._id;
    editInstitute.state = editInstitute.state ? editInstitute.state._id : null;
    delete editInstitute.groups;

    this.setState({
      editInstitute: editInstitute,
      isModalOpen: true
    });
  }

  async saveInstitute(institute) {
    try {
      if (institute._id) {
        await institutesServices.update(institute);
      } else { 
        institute.academic = this.state.academic._id;
        await institutesServices.create(institute);
      }
      this.onSucces();
      this.getInstitutes();
    } catch (error) {
      this.onError('Ocurrio un error al guardar los datos');
    } 
  }

  onError(message) {
    swal({
      title: 'Error',
      text: message,
      icon: 'error',
      button: 'ok',
    });
  }

  async onSucces() {
    await swal({
      title: 'Éxito',
      text: '!Felicidades! has creado con éxito una Institución. Ahora asegúrate de crear grupos dentro de ella para que puedas comenzar a trabajar con tus alumnos de dicha institución.',
      icon: 'success',
      button: 'ok',
    });
  }
  
  _renderInstitutes() {
    return this.state.filterInstitutes.map(item => {
      return (
        <div key={item._id} className="col-12 col-sm-6 col-md-4">
          <CardInstitute
            institute={item}
            onClick={this.goToDetail}
            delete={this.deleteInstituteHandler}
            update={this.editInstitueHandler}
          />
        </div>
      );
    });
  }

  _renderEmpty() {
    return (
      <div className="col-md-6 offset-md-3 pt-5">
        <div className="text-center">No hay ninguna Institución creada aún.<br/>Presiona el botón "+ INSTITUCIÓN" para crear una.</div>
      </div>
    );
  }

  render() {
    return (
      <Layout
        navBar={
          <NavbarPrimary 
            currentOption={this.props.match.url}
            searchQuery={this.state.search}
            searchHandler={this.searchHandler}
            actionText={LANG.INSTITUTES.BTN_ADD}
            actionHandler={this.addInstituteHandler}
          />
        }
      >
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-info d-flex">
              <div className="mr-3"><Icon name="info" /></div>
              <p style={{fontSize: '0.85rem'}}>Las instituciones son las escuelas, colegios u organizaciones educativas en las que como profesor impartes clases.
Dentro de cada institución puedes crear también cada uno de los grupos a los que impartes.<br/>
Para administrar los grupos de cada Institución, presiona sobre la tarjeta de la misma.</p>
            </div>
          </div>
        </div>
        <div className="row">
          { this.state.filterInstitutes.length > 0 ? this._renderInstitutes() : this._renderEmpty() }
        </div>
        { 
          this.state.editInstitute &&
          <ModalFormInstitute 
            dataInstitute={this.state.editInstitute}
            isOpen={this.state.isModalOpen}
            onClose={this.closeModalHandler}
            saveInstitute={this.saveInstitute}
          />
        }
      </Layout>
    );
  }
}

export default Institutes;