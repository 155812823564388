import React, { Component } from 'react';
import { Icon } from '../../components/ui';
import LayoutMarket from '../../components/layout-market/layout-market';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';

class MarketplaceCheckoutComplete extends Component {
  render() {
    return (
      <LayoutMarket>
        <div className="row mb-5">
          <div className="col">
            <Link to={ROUTES.MARKETPLACE.ROOT} className="btn btn-link">
              <Icon name="arrow_back_ios" />
              REGRESAR
            </Link>
          </div>
        </div>
        <div className="checkout-success text-center">
          <div className="result-icon">
            <Icon name="check" />
          </div>
          <h3>Compra éxitosa</h3>
          <p>Tu pago a sido procesado con éxito, podras encontrar tus articulos comprados en la sección categorias de conocimiento</p>
          <Link to={ROUTES.QUIZZES.ROOT} className="btn btn-primary btn-rounded">
            Ver articulos comprados
          </Link>
        </div>
      </LayoutMarket>
    );
  }
}

export default MarketplaceCheckoutComplete;