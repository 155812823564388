import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

class LevelService {

    getAll() {
        return HTTP.get(ROUTES_API.LEVEL.GET_ALL);
    }

}

const instance = new LevelService();
Object.freeze(instance);

export default instance;
