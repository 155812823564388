import React from 'react';
import REFERENCE_TYPES from '../../const/reference-types.const';
import * as moment from 'moment';
import 'moment/locale/es-us';
import { Icon } from '../../components/ui';

function formatTextReference(reference) {
  switch (reference.referenceType) {
    case REFERENCE_TYPES.MAGAZINE:
      return `${reference.authors}. (${moment(reference.recoveryDate).format('YYYY, MMMM D')}). ${reference.referenceTitle}. ${reference.sourceTitle}, ${reference.pages}.`;
    case REFERENCE_TYPES.NEWSPAPER:
      return `${reference.authors}. (${reference.yearOfPublication}). ${reference.referenceTitle}. ${reference.sourceTitle}, ${reference.pages}.`;
    case REFERENCE_TYPES.ENCYCLOPEDIA:
      return `${reference.authors}. ${reference.yearOfPublication}. ${reference.referenceTitle}. ${reference.sourceTitle}, (${reference.volume}, ${reference.pages}) ${reference.placeOfPublication}.`;
    case REFERENCE_TYPES.CHAPTERS:
      return `${reference.authors}. (${reference.yearOfPublication}). ${reference.referenceTitle}. ${reference.sourceTitle}(${reference.pages}). ${reference.placeOfPublication}: ${reference.editorial}`;
    case REFERENCE_TYPES.WEB:
      return `${reference.authors}. (${reference.yearOfPublication}). ${reference.referenceTitle}. ${moment(reference.recoveryDate).format('MMMM D, YYYY')}, ${reference.publishingAssociation} Sitio ${reference.url}`;
    case REFERENCE_TYPES.BOOK:
      return `${reference.authors}. (${reference.yearOfPublication}). ${reference.sourceTitle}. ${reference.placeOfPublication}: ${reference.editorial}.`
    default:
      return '';
  }
}

function getReferenceIconName(type) {
  let iconName = '';
  switch (type) {
    case REFERENCE_TYPES.MAGAZINE:
      iconName = 'import_contacts';
      break;
    case REFERENCE_TYPES.NEWSPAPER:
      iconName = 'description';
      break;
    case REFERENCE_TYPES.ENCYCLOPEDIA:
      iconName = 'school';
      break;
    case REFERENCE_TYPES.CHAPTERS:
      iconName = 'menu_book';
      break;
    case REFERENCE_TYPES.WEB:
      iconName = 'web';
      break;
    case REFERENCE_TYPES.BOOK:
    default:
      iconName = 'book';
      break;
  }

  return iconName;
}

function ReferenceItem(props) {
  const { reference } = props;

  return (
    <div className="reference-item">
      <div className="card border-0 mb-3">
        <div className="card-body card-detail-question m-0">
          <div className="add-question-card d-flex justify-content-between align-items-center">
            <h3 className="ml-3 fs-18 f-montserrat-semiBold lh-16 color-black-opacity mb-0">
              <Icon
                name={getReferenceIconName(reference.referenceType)}
              />
              {formatTextReference(reference)}
            </h3>
            <ul className="d-flex mr-2">
              <li className="arrow">
                <button
                  type="button"
                  className="btn"
                  onClick={() => props.onDelete(reference)}>
                  <span> <i className="material-icons icon">delete</i></span>
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => props.onEdit(reference)}>
                  <span><i className="material-icons mx-2 icon">edit</i></span>
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={() => props.onShow(reference._id)}>
                  <span><i className="material-icons">navigate_next</i></span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReferenceItem;