import React, { Component } from 'react';
import ModalForm from '../../components/ui/modal-form';

class ModalAssociateQuizzes extends Component {
  
  _renderQuizzes() {
    return this.props.quizzes.map(quiz => {
      let checked = quiz.checked ? true : false;
      return (
        <li key={quiz._id}>
          <div className="custom-control custom-checkbox">
            <input 
              type="checkbox" 
              name="checkQuizzes[]"
              className="custom-control-input" 
              id={`quiz-${quiz._id}`} 
              checked={checked}
              value={quiz._id}
              onChange={this.props.changeCheckQuiz}
            />
            <label className="custom-control-label" htmlFor={`quiz-${quiz._id}`}>
              {quiz.name}
            </label>
            <span>{`${quiz.totalQuestions} preguntas`}</span>
          </div>
        </li>
      )
    });
  }

  render() {
    return (
      <ModalForm
        title={'Agregar Cuestionario'}
        isOpen={this.props.isOpen}
        onHide={this.props.onClose}
      >
        <div className="row mb-2">
          <div className="col-12 text-center">
            { (this.props.quizzes && this.props.quizzes.length > 0) 
              ? <p>Selecciona los cuestionarios que habilitarás para este grupo independientemente si los utilizarás en Juegos amistosos o Eventos especiales como Retos individuales o Retos por equipo.</p>
              : <div className="alert alert-warning"><p>No existen cuestionarios disponibles para vincular a este grupo. Para crearlos dirígete a la sección principal "CUESTIONARIOS" para crearlos y regresa aquí para vincularlos.</p></div>
            }
          </div>
        </div>
        <div className="modal-associate-subheader px-4">
          <div className="selections">Sin cuestionarios seleccionados</div>
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: '1.2rem'}}>
            <div className="subtitle" style={{flex: 1}}>
              <h3>Mis cuestionarios</h3>
            </div>
            <div className="actions text-right">
              <button 
                className="btn btn-sm btn-primary btn-rounded"
                onClick={this.props.saveSelectedQuizzes}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
        <form className="form px-4" id="formAssociateQuizzess">
          <ul className="list-quizzes">
            { this._renderQuizzes() } 
          </ul>
        </form>
      </ModalForm>
    );
  }
}

export default ModalAssociateQuizzes;