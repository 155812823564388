import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import ROUTES from '../../const/routes.const';
import LANG from '../../const/language.const';
import NavbarQuiz from '../../components/navbar/navbar-quiz';
import { quizzesServices, QuestionsServices } from '../../services';
import swal from '@sweetalert/with-react';
import { Button, Icon } from '../../components/ui';
import QuestionsImportModal from './questions-import-modal/questions-import-modal';

class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quiz: {
        _id: this.props.match.params.quiz || null,
        name: '',
        questions: [],
      }, 
      showModalImport: false,
    }

    this.toogleModalImport = this.toogleModalImport.bind(this);
    this.importSuccessHandler = this.importSuccessHandler.bind(this);
  }

  componentDidMount() {
    this.getQuiz();
  }

  async getQuiz() {
    try {
      const quizID = this.props.match.params.quiz;
      const response = await quizzesServices.quizDetail(quizID);
      this.setState({
        quiz: response.data
      });
    } catch (error) {
      console.log('ERROR', error);
    }
  }

  detailQuestion(questionID) {
    this.props.history.push(ROUTES.QUESTIONS.DETAIL.replace(':question', questionID));
  }

  editQuestion(questionID) {
    this.props.history.push(ROUTES.QUESTIONS.EDIT.replace(':question', questionID));
  }

  async deleteQuestion(question) {
    const confirm = await swal({
      text: `¿Seguro que quieres eliminar la pregunta?`,
      icon: 'warning',
      dangerMode: true,
      buttons: [
        'Cancelar',
        'Si, eliminar'
      ]
    });

    if (!confirm) {
      return false;
    }

    try {
      await QuestionsServices.deleteQuestion({ '_id': question._id });
      let message = 'La pregunta ha sido eliminada';
      this.onSucces(message);
      this.getQuiz();
    } catch (error) {
      console.log('ERROR', error);
    }
  }

  deleteQuiz() {
    console.log('deleteQuiz')
  }

  editQuiz() {
    console.log('edit quiz')
  }

  toogleModalImport() {
    let showModalImport = this.state.showModalImport;
    this.setState({
      showModalImport: !showModalImport
    });
  }

  onSucces(message) {
    swal({
      text: message,
      icon: 'success',
    });
  }

  importSuccessHandler() {
    this.toogleModalImport();
    this.onSucces(LANG.QUIZZES.IMPORT_SUCCESS);
    this.getQuiz();
  }

  _renderQuestions() {
    const questions = this.state.quiz.questions;
    return questions.map(question => {
      return (
        <div key={question._id} className="row">
          <div className="card col-12 px-0 border-0  mb-3">
            <div className="card-body card-detail-question m-0">
                <div className="add-question-card d-flex justify-content-between align-items-center">
                  <h3 className="ml-3 fs-18 f-montserrat-semiBold lh-16 color-black-opacity mb-0">{question.title}</h3>
                  <ul className="d-flex mr-2">
                    <li className="arrow">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.deleteQuestion(question)}>
                        <span> <i className="material-icons icon">delete</i></span>
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.editQuestion(question._id)}>
                        <span><i className="material-icons mx-2 icon">edit</i></span>
                      </button>
                      <button
                        type="button"
                        className="btn"
                        onClick={() => this.detailQuestion(question._id)}>
                        <span><i className="material-icons">navigate_next</i></span>
                      </button>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      );
    });
  }

  _renderEmptyState() {
    return (
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3 text-center">
          <p>Para agregar preguntas a este cuestionario puedes hacerlo creando una por una utilizando la opción de "+ AGREGAR PREGUNTA", o puedes cargarlas de forma masiva utilizando la opción de "IMPORTAR ARCHIVO".</p>
        </div>
      </div>
    );
  }

  _buttonAddBlock() {
    return (
      <Link to={ROUTES.QUESTIONS.ADD.replace(':quiz', this.state.quiz._id)}>
        <div className="row">
          <div className="card col-12 px-0 border-0  mb-3">
              <div className="card-body card-detail-add m-0">
                <div className="add-question-card">
                    <span><i className="material-icons">add_circle</i></span>
                    <h3 className="ml-3 fs-18 f-montserrat-semiBold lh-22 color-white mb-0">{LANG.QUIZZES.ADD_QUESTION}</h3>
                </div>
              </div>
          </div>
        </div>
      </Link>
    )
  }

  render() {
    const { quiz } = this.state;
    return (
      <Layout
        navBar={
          <NavbarQuiz 
            linkBack={ROUTES.QUIZZES.ROOT}
            titleNav={quiz.name}
            deleteQuiz={this.deleteQuiz}
            updateQuiz={this.editQuiz}
            quiz={quiz._id}
            currentOption={this.props.match.url}
          />
        }
      >
        <div>
        <div className="row">
            <div className="col-md-12">
              <div className="alert alert-info d-flex">
                <div className="mr-3"><Icon name="info" /></div>
                <p style={{fontSize: '0.85rem'}}>
                Para agregar preguntas a este cuestionario puedes hacerlo creando una por una utilizando la opción de "+ AGREGAR PREGUNTA", o puedes cargarlas de forma masiva utilizando la opción de "IMPORTAR ARCHIVO".
                </p>
              </div>
            </div>
          </div>
          <div className="questions-header section-header">
            <h2>{LANG.QUIZZES.QUESTIONS_TITLE}</h2>
            <Button 
              color="primary"
              icon="add_circle"
              label="Agregar pregunta"
              onClick={() => this.props.history.push(ROUTES.QUESTIONS.ADD.replace(':quiz', quiz._id))}
            />
            <Button 
              color="secondary"
              icon="upload_file"
              label="Importar Archivo"
              onClick={this.toogleModalImport}
            />
          </div>
          <div className="questions-wrap">
            { quiz && quiz.questions.length > 0 ? this._renderQuestions() : this._renderEmptyState() }
          </div>
        </div>
        <QuestionsImportModal 
          show={this.state.showModalImport}
          quiz={quiz._id} 
          onHide={this.toogleModalImport}
          onImportSuccess={this.importSuccessHandler}
        />
      </Layout>
    );
  }
}

export default Questions;