import React, { Component } from 'react';
import { TextFieldDecorate, Icon, Button } from '../../components/ui'
import LANG from '../../const/language.const';
import AuthService from '../../services/auth.service';
import LocalStorageService from '../../services/local-storage.service';
import ROUTES from '../../const/routes.const';
import { LANDING_PAGE, PRIVACY_URL, TERMS_URL } from '../../enviroments/enviroment';

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountCreated: false,
      errors: {},
      account: {
        name: '',
        lastname: '',
        surname: '',
        email: '',
        password: ''
      },
      confirmPassword: '',
      passwordRules: {
        length: false,
        number: false,
        symbol: false,
        uppercase: false,
      }
    }

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.processForm = this.processForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.passwordHandler = this.passwordHandler.bind(this);
    this.handlePasswordConfirm = this.handlePasswordConfirm.bind(this);
  }

  componentDidMount() {
    const session = LocalStorageService.session();
    if (session) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  onChangeHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    let account = this.state.account;
    let errors = this.state.errors;

    account[name] = value;
    
    if (name === 'email') {
      errors.email = !this._isValidEmail(value);
    } else {
      errors[name] = false;
    }


    this.setState({
      account,
      errors
    });
  }

  passwordHandler(event) {
    let value = event.target.value
    let account = this.state.account;
    let passwordRules = this.state.passwordRules;
    let errors = this.state.errors;
    account.password = value;
    errors.password = true;

    //validacion contraseña
    passwordRules.length = this._isValidLength(account.password)
    passwordRules.uppercase = this._isValidUpperCharacter(account.password)
    passwordRules.number = this._isValidDigit(account.password)
    passwordRules.symbol = this._isValidSpecialCharacter(account.password)

    if (passwordRules.length && passwordRules.uppercase && passwordRules.number && passwordRules.symbol) {
      errors.password = false
    }

    this.setState({ 
      account, 
      passwordRules,
      errors 
    })
  }

  handlePasswordConfirm(event) {
    let value = event.target.value;
    let errors = this.state.errors;
    
    errors.samePassword = !value === this.state.account.password;

    this.setState({
      confirmPassword: value,
      errors
    })
  }

  _isValidEmail(email) {
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    return emailRegex.test(email);
  }

  _isValidLength(password) {
    return password && password.trim().length >= 8;
  }

  _isValidUpperCharacter(password) {
    const regExp = new RegExp('[A-Z]');
    return regExp.test(password);
  }

  _isValidDigit(password) {
    const regExp = new RegExp('[0-9]');
    return regExp.test(password);
  }

  _isValidSpecialCharacter(password) {
    const regExp = new RegExp('[$!¡$%$&/$($)=?¿@#{}$[/\\]/]');
    return regExp.test(password);
  }

  processForm() {
    const { account, confirmPassword } = this.state;
    let errors = this.state.errors;
    let validForm = true;

    if (!account.name) {
      validForm = false;
      errors.name = true;
    }

    if (!account.lastname) {
      validForm = false;
      errors.lastname = true;
    }

    if (!account.email || !this._isValidEmail(account.email)) {
      validForm = false;
      errors.email = true;
    }

    if (!account.password || errors.password) {
      validForm = false;
      errors.password = true;
    }

    if (!confirmPassword || errors.samePassword) {
      validForm = false;
      errors.samePassword = true;
    }


    if (validForm) {
      this.setState({ error: '' });
      this.submitForm();
    } else {
      this.setState({ errors })
    }
  }

  async submitForm() {
    try {
      const { account } = this.state;
      await AuthService.register(account);
      this.setState({ accountCreated: true });
    } catch (e) {
      if (e.response && e.response.data) {
        this.setState({ error: e.response.data.message });
      }
    }
  }


  _renderAccountCreated() {
    return (
      <div className="created-account-success mb-4">
        <h2>Cuenta creada</h2>
        <p className="mb-3">Se enviará un correo electrónico con un link para activar tu cuenta. Por favor verifica tu aplicación de correo</p>
        <Button
          color="primary"
          label="Entendido"
          icon="person"
          onClick={() => this.props.history.push(ROUTES.AUTH.LOGIN)}
        />
      </div>
    );
  }

  _renderForm() {
    return (
      <form>
        <TextFieldDecorate
          type="text"
          name="name"
          placeholder={LANG.AUTH.REGISTER.LABEL_NAME}
          leftDecorate={<Icon name="person" color="primary" />}
          value={this.state.account.name}
          onChange={this.onChangeHandler}
          errorMessage={this.state.errors && this.state.errors.name ? LANG.ERRORS.REQUIRED_FIELD : ''}
        />
        <div className="row">
          <div className="col-md-6">
            <TextFieldDecorate
              type="text"
              name="lastname"
              placeholder={LANG.AUTH.REGISTER.LABEL_LASTNAME}
              leftDecorate={<Icon name="short_text" color="primary" />}
              value={this.state.account.lastname}
              onChange={this.onChangeHandler}
              errorMessage={this.state.errors && this.state.errors.lastname ? LANG.ERRORS.REQUIRED_FIELD : ''}
            />
          </div>
          <div className="col-md-6">
            <TextFieldDecorate
              type="text"
              name="surname"
              placeholder={LANG.AUTH.REGISTER.LABEL_SURNAME}
              leftDecorate={<Icon name="short_text" color="primary" />}
              value={this.state.account.surname}
              onChange={this.onChangeHandler}
            />
          </div>
        </div>
        <TextFieldDecorate
          type="email"
          name="email"
          placeholder={LANG.AUTH.LOGIN.LABEL_EMAIL}
          leftDecorate={<Icon name="mail" color="primary" />}
          value={this.state.account.email}
          onChange={this.onChangeHandler}
          errorMessage={this.state.errors && this.state.errors.email ? LANG.ERRORS.INVALID_EMAIL : ''}
        />
        <TextFieldDecorate
          type="password"
          name="password"
          placeholder={LANG.AUTH.REGISTER.LABEL_PASSWORD}
          leftDecorate={<Icon name="vpn_key" color="primary" />}
          value={this.state.account.password}
          onChange={this.passwordHandler}
          errorMessage={this.state.errors && this.state.errors.password ? 'Contraeña no válida' : ''}
        />
        <div className="px-3 mb-3">
          <p>{LANG.AUTH.REGISTER.LABEL_PASSWORD_RULES}</p>
          <small  className={(this.state.passwordRules.length ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_LENGTH}</small>
          <small  className={(this.state.passwordRules.uppercase ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_UPPERCASE}</small>
          <small  className={(this.state.passwordRules.number ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_NUMBER}</small>
          <small  className={(this.state.passwordRules.symbol ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_SYMBOL}</small>
        </div>
        <TextFieldDecorate
          type="password"
          name="confirmPassword"
          placeholder={LANG.AUTH.REGISTER.LABEL_CONFIRM_PASSWORD}
          leftDecorate={<Icon name="vpn_key" color="primary" />}
          value={this.state.confirmPassword}
          onChange={this.handlePasswordConfirm}
          errorMessage={this.state.errors && this.state.errors.samePassword ? LANG.ERRORS.NO_CONFIRM_PASSWORD : ''}
        />
        <div className="mt-4 text-right">
          <Button
            color="primary"
            label={LANG.AUTH.REGISTER.BTN_CREATE_ACCOUNT}
            icon="save"
            onClick={this.processForm}
          />
        </div>
      </form>
    );
  }

  render() {
    return (
      <div className="wrap-auth">
        <div className="card card-auth">
          <div className="card-header">
            <h2>{LANG.AUTH.REGISTER.TITLE_FORM}</h2>
          </div>
          <div className="card-body">
            <div className="alert-messages">
              {this.state.error &&
                <div className="alert alert-danger">{this.state.error}</div>
              }
            </div>
            { this.state.accountCreated ? this._renderAccountCreated() : this._renderForm() }
            <div className="login-actions">
              <div className="mb-3">
                <Button
                  color="outline-primary"
                  label="Iniciar sesión"
                  icon="login"
                  onClick={() => this.props.history.push(ROUTES.AUTH.LOGIN)}
                />
              </div>
            </div>
            <div className="footer-copy">
              <a href={LANDING_PAGE} rel="noopener noreferrer">
                <img src={require('../../assets/images/Logo-Positive.svg')} alt="GameIF Academy" />
              </a>
              <div className="mt-3">{LANG.AUTH.COPY.TEXT}</div>
              <div>
                <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.TERMS}</a>
                <span> • </span>
                <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.PRIVACY}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;