import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import ROUTES from '../../const/routes.const';
import LANG from '../../const/language.const';
import NavbarQuiz from '../../components/navbar/navbar-quiz';
import { quizzesServices, referencesServices } from '../../services';
import REFERENCE_TYPES from '../../const/reference-types.const';
import ReferenceItem from './reference-item';
import Select from 'react-select';
import { Button, Icon } from '../../components/ui';
import { Modal } from 'react-bootstrap';


class References extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quiz: {
        name: '',
      },
      references: [],
      currentType: '',
      showModalType: false,
      referenceTypeOptions: [
        { value: '', label: 'Todos' },
        { value: REFERENCE_TYPES.BOOK, label: LANG.REFERENCES.REFERENCE_BOOK },
        { value: REFERENCE_TYPES.MAGAZINE, label: LANG.REFERENCES.REFERENCE_MAGAZINE },
        { value: REFERENCE_TYPES.NEWSPAPER, label: LANG.REFERENCES.REFERENCE_NEWSPAPER },
        { value: REFERENCE_TYPES.CHAPTERS, label: LANG.REFERENCES.REFERENCE_CHAPTERS },
        { value: REFERENCE_TYPES.ENCYCLOPEDIA, label: LANG.REFERENCES.REFERENCE_ENCYCLOPEDIA },
        { value: REFERENCE_TYPES.WEB, label: LANG.REFERENCES.REFERENCE_WEB },
      ]
    };

    this.changeTypeReference = this.changeTypeReference.bind(this);
    this.editReferenceHandler = this.editReferenceHandler.bind(this);
    this.showReferenceHandler = this.showReferenceHandler.bind(this);
    this.deleteReferenceHandler = this.deleteReferenceHandler.bind(this);
    this.changeTypeHandler = this.changeTypeHandler.bind(this);
    this.toggleModalHandler = this.toggleModalHandler.bind(this);
    this.selectAddType = this.selectAddType.bind(this);
  }

  componentDidMount() {
    this.getQuiz();
    this.getReferences();
  }

  async getQuiz() {
    try {
      const quizID = this.props.match.params.quiz;
      const response = await quizzesServices.getById(quizID);
      this.setState({
        quiz: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getReferences() {
    try {
      const quizID = this.props.match.params.quiz;
      const response = await referencesServices.getByQuiz(quizID);
      this.setState({
        references: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  changeTypeReference(type) {
    this.setState({ currentType: type });
  }

  changeTypeHandler(option) {
    this.setState({ currentType: option.value });
  }

  selectAddType(type) {
    this.props.history.push(ROUTES.REFERENCES.ADD.replace(':type', type).replace(':quiz', this.state.quiz._id));
  }

  toggleModalHandler() {
    let { showModalType } = this.state;
    this.setState({ showModalType: !showModalType });
  }

  getTextTypeReference() {
    const { currentType } = this.state;

    switch (currentType) {
      case REFERENCE_TYPES.BOOK:
        return LANG.REFERENCES.APA_BOOK;
      case REFERENCE_TYPES.MAGAZINE:
        return LANG.REFERENCES.APA_MAGAZINE;
      case REFERENCE_TYPES.NEWSPAPER:
        return LANG.REFERENCES.APA_NEWSPAPER;
      case REFERENCE_TYPES.CHAPTERS:
        return LANG.REFERENCES.APA_CHAPTERS;
      case REFERENCE_TYPES.ENCYCLOPEDIA:
        return LANG.REFERENCES.APA_ENCYCLOPEDIA;
      case REFERENCE_TYPES.WEB:
        return LANG.REFERENCES.APA_WEB;
      default:
        return '';
    }
  }

  deleteReferenceHandler(reference) {
    console.log('delete Reference', reference);
  }

  editReferenceHandler(reference) {
    this.props.history.push(ROUTES.REFERENCES.EDIT.replace(':reference', reference._id));
  }

  showReferenceHandler(referenceId) {
    this.props.history.push(ROUTES.REFERENCES.DETAIL.replace(':reference', referenceId));
  }

  _renderReferences() {
    const { references, currentType } = this.state;
    return references.map(reference => {
      if (currentType !== '' && reference.referenceType !== currentType) {
        return null;
      }

      return (
        <div key={reference._id}>
          <ReferenceItem 
            reference={reference}
            onDelete={this.deleteReferenceHandler}
            onEdit={this.editReferenceHandler}
            onShow={this.showReferenceHandler}
          />
        </div>
      )
    });
  }

  render() {
    const { quiz, currentType } = this.state;
    
    return (
      <Layout
        navBar={
          <NavbarQuiz 
            linkBack={ROUTES.QUIZZES.ROOT}
            titleNav={quiz.name}
            deleteQuiz={() => console.log('deleteQuiz')}
            updateQuiz={() => console.log('edit quiz')}
            quiz={quiz._id}
            currentOption={this.props.match.url}
          />
        }
      >
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-info d-flex">
                <div className="mr-3"><Icon name="info" /></div>
                <p style={{fontSize: '0.85rem'}}> Si es de tu interés contribuir en el mundo académico global, es importante que tu cuestionario cuente con las referencias bibliográficas correspondientes, de esta forma y si lo autorizas, tu cuestionario podría ser utilizado por otros profesores de cualquier parte del mundo y encontrarlo por medio de la búsqueda bibliográfica.
                  <br/>Para agregar una referencia bibliográfica, presiona el botón de "+" y luego selecciona el tipo de documento fuente.</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-end mb-3">
            <div className="col-md-4">
              <div className="form-group">
                <Select 
                  name="filter-type"
                  defaultValue=""
                  options={this.state.referenceTypeOptions}
                  value={this.state.currentType}
                  searchable={true}
                  placeholder="Tipo de bibliografía"
                  className="fg-line-select-container"
                  classNamePrefix="fg-line-select"
                  onChange={this.changeTypeHandler}
                />
              </div>
            </div>
            <div className="col-md-3">
              <Button 
                label={LANG.REFERENCES.BTN_LABEL_ADD}
                icon="add_circle"
                onClick={this.toggleModalHandler}
              />
            </div>
          </div>
          <div className="references-wrap">
            { this._renderReferences() }
          </div>
        </div>
        <Modal show={this.state.showModalType} onHide={this.toggleModalHandler}>
          <Modal.Header closeButton>
            <Modal.Title>Agregar bibliografía</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Seleccione el tipo de bibliografía que desea agregar</h4>
            <div className="row">
              <div className="col-12">
                <Button 
                  className="my-2 mx-2"
                  label={LANG.REFERENCES.REFERENCE_BOOK}
                  icon="book"
                  onClick={() => this.selectAddType(REFERENCE_TYPES.BOOK)}
                />
                <Button 
                  className="my-2 mx-2"
                  label={LANG.REFERENCES.REFERENCE_MAGAZINE}
                  icon="import_contacts"
                  onClick={() => this.selectAddType(REFERENCE_TYPES.MAGAZINE)}
                />
                <Button 
                  className="my-2 mx-2"
                  label={LANG.REFERENCES.REFERENCE_NEWSPAPER}
                  icon="description"
                  onClick={() => this.selectAddType(REFERENCE_TYPES.NEWSPAPER)}
                />
                <Button 
                  className="my-2 mx-2"
                  label={LANG.REFERENCES.REFERENCE_ENCYCLOPEDIA}
                  icon="school"
                  onClick={() => this.selectAddType(REFERENCE_TYPES.ENCYCLOPEDIA)}
                />
                <Button 
                  className="my-2 mx-2"
                  label={LANG.REFERENCES.REFERENCE_CHAPTERS}
                  icon="menu_book"
                  onClick={() => this.selectAddType(REFERENCE_TYPES.CHAPTERS)}
                />
                <Button 
                  className="my-2 mx-2"
                  label={LANG.REFERENCES.REFERENCE_WEB}
                  icon="web"
                  onClick={() => this.selectAddType(REFERENCE_TYPES.WEB)}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    );
  }
}

export default References;