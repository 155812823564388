import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarCampaign from '../../components/navbar/navbar-campaign';
import ROUTES from '../../const/routes.const';
import {campaignsService} from '../../services';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { statsServices } from '../../services/stats.service';

class CampaignStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {
        _id: this.props.match.params.campaign || '',
        group: '',
        name: ''
      },
      statsQuizzes: [],
      statsCampaign: null,
    }

    this.getCampaignStats = this.getCampaignStats.bind(this);
  }

  async componentDidMount() {
    this.getCampaign();
    await this.getStatsQuizzes();
    this.getCampaignStats();
  }

  async getCampaign() {
    const campaignID = this.props.match.params.campaign;
    try {
      const response = await campaignsService.getDetail(campaignID);
      this.setState({
        campaign: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getStatsQuizzes() {
    const campaignID = this.props.match.params.campaign;
    try {
      const response = await statsServices.getCampaignQuizzesStats(campaignID)
      this.setState({
        statsQuizzes: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getCampaignStats() {
    const campaignID = this.props.match.params.campaign;
    try {
      const response = await statsServices.getCampaignStats(campaignID);
      console.log(response.data)
      let campaignStats = {};
      
      let accuracy = 0;
      let totalQuestions = 0;
      let corrects = 0;
      for (let i = 0; i < response.data.usersStats.length; i++) {
        const element = response.data.usersStats[i];
        corrects += element.correct;
        totalQuestions += element.total;
      }

      accuracy = corrects ? Math.round(corrects * 100 / totalQuestions) : 0;
      campaignStats.accuracy = accuracy;
      campaignStats.totalQuestions = totalQuestions;
      campaignStats.questionsUsers = totalQuestions ? Math.round(totalQuestions / response.data.usersStats.length) : 0;
      campaignStats.challengesPlayed = response.data.challengesPlayed;
      campaignStats.challengesOpen = response.data.challengesOpen;
      console.log(campaignStats)
      
      this.setState({
        statsCampaign: campaignStats
      });
    } catch (error) {
      console.log(error);
    }
  }

  customTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label"><strong>{label}</strong></p>
          <p>Correctas: <span>{(payload[0].dataKey === 'correct' ? payload[0].value : payload[1].value)}</span>
          <br/>Incorrectas: <span>{(payload[0].dataKey === 'correct' ? payload[1].value : payload[0].value)}</span>
          <br/>Precisión: <span>{payload[0].payload.precision}%</span></p>
          {/* <p className="intro">{getIntroOfPage(label)}</p>
          <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      );
    }
  
    return null;
  };

  renderLegendText(value, entry) {
    const { color } = entry;
    let label = value === 'correct' ? 'Correctas' : 'Incorrectas';

    return <span style={{ color }}>{label}</span>;
  }

  render() {
    const { campaign, statsQuizzes, statsCampaign } = this.state;
    return (
      <Layout 
        navBar={
          <NavbarCampaign 
            linkBack={ROUTES.GROUPS.CAMPAIGNS.replace(':group', campaign.group)}
            titleNav={campaign.name}
            currentOption={this.props.match.url}
            campaignID={campaign._id}
          />
        }
      >
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card" style={{height: '100%', backgroundColor: 'rgba(158,81,255,.8)'}}>
                      <div className="card-body text-right text-light">
                        <h2 className="m-0">{statsCampaign && statsCampaign.accuracy }%</h2>
                        <small>Precisión global</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                  <div className="card" style={{height: '100%', backgroundColor: 'rgba(158,81,255,.8)'}}>
                      <div className="card-body text-right text-light">
                        <h2 className="m-0">{statsCampaign && statsCampaign.totalQuestions}</h2>
                        <small>Preguntas respondidas</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                  <div className="card" style={{height: '100%', backgroundColor: 'rgba(158,81,255,.8)'}}>
                      <div className="card-body text-right text-light">
                        <h2 className="m-0">{ statsCampaign && statsCampaign.challengesPlayed.total_challenges }</h2>
                        <small>Retos jugados</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                  <div className="card" style={{height: '100%', backgroundColor: 'rgba(158,81,255,.8)'}}>
                      <div className="card-body text-right text-light">
                        <h2 className="m-0">{ statsCampaign && statsCampaign.questionsUsers }</h2>
                        <small>Promedio de preguntas respondidas por usuario</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      layout="vertical"
                      data={statsQuizzes}
                      margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis dataKey="topic" type="category" />
                      <Tooltip content={this.customTooltip} />
                      <Legend formatter={this.renderLegendText} />
                      <Bar dataKey="correct" fill="#9E51FF" />
                      <Bar dataKey="incorrect" fill="#ffc658" />
                    </BarChart>
                  </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CampaignStats;