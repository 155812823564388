import React, { useEffect, useState } from 'react';
import OnboardingLayout from '../../components/layout/onboardingLayout';
import Steps from '../../components/stepper/stepper';

const Onboarding = ({ currentStep, nextStep, onboardingSteps, currentAcademic, goToStep }) => (
    <OnboardingLayout>
        {
            currentStep === 0 || currentStep === 5
                ? <CardMessage {...{ currentStep, nextStep, currentAcademic }} />
                : <CardViewer {...{ currentStep, nextStep, onboardingSteps, goToStep }} />
        }
    </OnboardingLayout>
)


const CardMessage = ({ nextStep, currentStep, currentAcademic }) => (
    <div className="card card-message p-4">
        {
            currentStep === 0
                ? <InitialStep userName={currentAcademic.name}>
                    <Button title="Comenzar" icon="arrow_forward" onClick={nextStep} />
                </InitialStep>
                : <FinalStep>
                    <Button title="Continuar" icon="arrow_forward" onClick={nextStep} />
                </FinalStep>
        }
    </div>
)

const CardViewer = ({ currentStep, nextStep, onboardingSteps, goToStep }) => {
    const [renderStep, setRenderStep] = useState({ eventKey: 1, title: "", description: "", embedId: "" });

    useEffect(() => {
        let step = onboardingSteps.find(onboardingStep => onboardingStep.eventKey === currentStep);
        setRenderStep(step);
    }, [currentStep, onboardingSteps]);

    const ButtonViewer = ({ title }) => (
        <div className="align-self-end">
            <Button
                onClick={nextStep}
                {...{
                    title: title ? title : 'Continuar',
                    icon: 'arrow_forward'
                }}
            />
        </div>
    )

    return (
        <>
            <div className="col-1 col-md-1 col-xl-2 h-100 pl-2">
                <Steps {...{ currentStep, goToStep }} />
            </div>
            <div className="col-11 col-md-11 col-xl-10 pt-4 pb-4 pr-4">
                <div className="card card-viewer p-4">
                    <h5>Paso <sub>{renderStep.eventKey}</sub></h5>
                    <h2>{renderStep.title}</h2>
                    <h4>{renderStep.description}</h4>
                    <IframeCustom embedId={renderStep.embedId} />
                    <ButtonViewer />
                </div>
            </div>
        </>
    )
}

const InitialStep = ({ children, userName }) => (
    <>
        <h4>Introducción</h4>
        <p>Hola <strong>{userName}</strong>, antes de comenzar a usar la plataforma, debes conocer como funciona.</p>
        <p>Mira los siguientes vídeos para convertirte en profesional de GAMEIF® Academy.</p>
        <div className="mt-3">
            {children}
        </div>
    </>
)

const FinalStep = ({ children }) => (
    <>
        <h4>Bien hecho!</h4>
        <p>Haz completado el tutorial. Ahora, puedes comenzar a utilizar GAMEIF® Academy. </p>
        <p>Si necesitas ver de nuevo el tutorial, puedes hacerlo en la sección de tu perfil.</p>
        <div className="mt-3">
            {children}
        </div>
    </>
)

const Button = ({ onClick, title, icon }) => (
    <button
        type="button"
        className="btn btn-primary btn-rounded btn-material-icon"
        onClick={onClick}>
        <i className="material-icons">{icon}</i>
        <span>{title}</span>
    </button>
)

export const IframeCustom = ({ embedId }) => (
    <div className="video-responsive mb-3">
        <iframe
            src={`https://www.youtube.com/embed/${embedId}?showinfo=0&rel=0&&controls=2&showinfo=0&fs=0&modestbranding=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
)

export default Onboarding;