import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import LANG from '../../const/language.const';

class CardInstitute extends Component {
  constructor(props) {
    super(props);
    this.deleteInstitute = this.deleteInstitute.bind(this);
    this.updateInstitute = this.updateInstitute.bind(this);
  }

  deleteInstitute() {
    const institute = Object.assign({}, this.props.institute);
    this.props.delete(institute)
  }

  updateInstitute() {
    const institute = Object.assign({}, this.props.institute);
    this.props.update(institute)
  }

  render() {
    const { institute } = this.props;
    const numGroups = institute.groups.length;
    let numStudents = 0;
    for (let i = 0; i < numGroups; i++) {
      numStudents += parseInt(institute.groups[i].enrolled);
    }

    return (
      <div className="card card-institute" >
        <div className="card-body pb-1" onClick={() => this.props.onClick(institute._id)}>
          <h3 className="card-title">{institute.name}</h3>
          <ul className="data-stats">
            <li className="mr-5">
              <span className="title-stats">{LANG.INSTITUTES.CARD.GROUPS}</span>
              <span className="number-stats">{numGroups}</span>
            </li>
            <li className="mr-5">
              <span className="title-stats">{LANG.INSTITUTES.CARD.STUDENTS}</span>
              <span className="number-stats">{numStudents}</span>
            </li>
          </ul>
        </div>
        <div className="card-footer text-right">
          <div className="dropdown-primary-up">
            <DropdownButton
              drop='up'
              variant="icon arrow-none"
              alignRight
              title={<i className="material-icons">more_vert</i>}
              id='dropdown-button-drop'
            >
              <Dropdown.Item eventKey="1" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={() => this.deleteInstitute(this.props.institute)} >
                  <span>Borrar</span>
                  <i className="material-icons">delete</i>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={() => this.updateInstitute(this.props.institute)} >
                  <span>Editar</span>
                  <i className="material-icons">edit</i>
                </div>
                <div className="arrow-down">
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    )
  }
}

export default CardInstitute;