import React, { useState, useEffect } from 'react';

const Stepper = ({ goToStep, currentStep }) => {
    const [progress, setProgress] = useState(15);

    useEffect(() => {
        if (currentStep === 1) {
            setProgress(17)
        }
        else if (currentStep === 2) {
            setProgress(48)
        } else if (currentStep === 3) {
            setProgress(80)
        } else {
            setProgress(100)
        }
    }, [currentStep])

    const Step = ({ step }) => (
        <div className={`step-container ${step <= currentStep ? 'step-selected' : ''}`} >
            <button
                className={`btn-icon button-step ${step <= currentStep ? '_selected' : ''}`}
                onClick={() => goToStep(step)}
            >
                {step}
            </button>
        </div >
    )

    return (
        <div className="stepper-wrapper">
            <div className="stepper">
                <div className="progress-stepper"><div className="_stepper" style={{ height: `${progress}%` }} /></div>
                <Step step={1} />
                <Step step={2} />
                <Step step={3} />
                <Step step={4} />
            </div>
        </div>
    );
}



export default Stepper;