import React from 'react';
import Select from 'react-select';

function SelectField({
  options,
  placeholder,
  name,
  onChange,
  label,
  value,
  errorMessage,
  isClearable,
  isDisabled,
  isLoading,
  isSearchable,
}) {
  const containerClasses = `form-group ${errorMessage ? 'has-error' : ''}`;
  
  return (
    <div className={containerClasses}>
      {label && <label>{label}</label>}
      <Select 
        name={name}
        options={options}
        value={value}
        placeholder={placeholder}
        className="fg-line-select-container"
        classNamePrefix="fg-line-select"
        onChange={onChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
      />
      {errorMessage && <small className="help-block">{errorMessage}</small>}
    </div>
  );
}

export default SelectField;