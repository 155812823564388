import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const statsServices = {
  getGroupAccuracy,
  getGroupThemePerformance,
  getCampaignQuizzesStats,
  getCampaignChallengesStats,
  getCampaignStats
}

async function getGroupAccuracy(groupId) {
  return await HTTP.get(ROUTES_API.STATS.GET_GROUP_ACCURACY.replace(':group', groupId));
}

async function getGroupThemePerformance(groupId) {
  return await HTTP.get(ROUTES_API.STATS.GET_GROUP_PERFORMANCE_THEME.replace(':group', groupId));
}

async function getCampaignQuizzesStats(campaignId) {
  return await HTTP.get(ROUTES_API.STATS.GET_CAMPAIGN_QUIZZES_STATS.replace(':campaign', campaignId));
}

async function getCampaignChallengesStats(campaignId) {
  return await HTTP.get(ROUTES_API.STATS.GET_CAMPAIGN_CHALLENGES.replace(':campaign', campaignId));
}

async function getCampaignStats(campaignId) {
  return await HTTP.get(ROUTES_API.STATS.GET_CAMPAIGN_STATS.replace(':campaign', campaignId));
}