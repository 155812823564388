import React, { Component } from 'react';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      value: this.props.value
    };
  }

  handleInput = (e) => {
    this.setState({value: e.target.value})
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  handleCancel = () => {
    this.setState({active: false, value: ''})
    if (this.props.onChange) {
      this.props.onChange('')
    }
  }

  _renderClearButton() {
    return (
      <button 
        type="button" 
        className="search-btn-clear" 
        onClick={this.handleCancel} >
        <i className="fa fa-times"></i>
      </button>
    );
  }

  render() {
    const { value } = this.state;
    const props = this.props;

    return (
      <div className="search-container">
        <label>
          <i className="search-icon fa fa-search"></i>
        </label>
        <input 
          className="search-input" 
          type="text"
          placeholder= "Buscar"
          value={value}
          onChange={this.handleInput} />
        { value && props.showCancelSearch && this._renderClearButton() }
      </div>
    );
  }
}

export default SearchBar;