import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const notificationsServices = {
  getByAcademic,
  getDetail,
  save
}

async function getByAcademic(academicID) {
  return await HTTP.get(ROUTES_API.NOTIFICATIONS.GET_BY_ACADEMIC.replace(':academic', academicID));
}

async function getDetail(notificationID) {
  return await HTTP.get(ROUTES_API.NOTIFICATIONS.GET_DETAIL.replace(':id', notificationID));
}

async function save(notification) {
  return await HTTP.post(ROUTES_API.NOTIFICATIONS.SAVE, notification);
}