import React, { Component } from 'react'
import { Sidebar } from '../../components/layout/sidebar'
import { Icon, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '../../components/ui';
import LocalStorageService from '../../services/local-storage.service';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';
export class SidebarProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      academic: {
        name: '',
        lastname: '',
        email: ''
      }
    };

    this.logoutHandler = this.logoutHandler.bind(this);
  }

  componentDidMount() {
    let academic = LocalStorageService.session();

    this.setState({ academic });
  }

  logoutHandler() {
    LocalStorageService.signOff();
    window.location.reload(false);
  }

  render() {
    const { academic } = this.state;
    return (
      <div className="sidebar-profile">
        <Sidebar height={"100vh"} side="left" active={this.props.activeProfile}>
          <div className="profile-userpic">
            <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=100" alt="Mi Perfil" />
            <IconButton
              name="arrow_back"
              className="btn-profile-back"
              onClick={this.props.toogleProfile}
            />
          </div>
          <div className="profile-userinfo">
            <List>
              <ListItem verticalAlign="center">
                <ListItemAvatar>
                  <Icon name="people" />
                </ListItemAvatar>
                <ListItemText>{`${academic.name} ${academic.lastname}`}</ListItemText>
              </ListItem>
              <ListItem verticalAlign="center">
                <ListItemAvatar>
                  <Icon name="mail" />
                </ListItemAvatar>
                <ListItemText>{academic.email}</ListItemText>
              </ListItem>
            </List>
          </div>
          <div className="profile-useractions">
            <List>
              <Link style={{ color: 'white' }} to={ROUTES.ONBOARDING.REVIEW}>
                <ListItem verticalAlign="center">
                  <ListItemAvatar>
                    <Icon name="school" />
                  </ListItemAvatar>
                  <ListItemText>Tutoriales</ListItemText>
                </ListItem>
              </Link>
              <ListItem
                verticalAlign="center"
                onClick={this.logoutHandler}
              >
                <ListItemAvatar>
                  <Icon name="clear" />
                </ListItemAvatar>
                <ListItemText>Cerrar Sesión</ListItemText>
              </ListItem>
            </List>
          </div>
        </Sidebar>
      </div>
    )
  }
}

export default SidebarProfile
