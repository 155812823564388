import React from 'react';

function ListItemAvatar(props) {
  const { children, className, ...other } = props;
  return (
    <div 
      className={`list-group-item-avatar ${className ? className : ''}`}
      {...other}
    >
      { children }
    </div>
  );
}

export default ListItemAvatar;