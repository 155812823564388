class LocalStorageService {

  loginSession(academic) {
    localStorage.setItem('academic', JSON.stringify(academic))
  }

  signOff() {
    localStorage.clear();
  }

  session() {
    let session = JSON.parse(localStorage.getItem('academic'));
    return session ? session.academic : null;
  }

  getToken() {
    let session = JSON.parse(localStorage.getItem('academic'));
    return session ? session.token : null;
  }

  getInfoSession() {
    let session = JSON.parse(localStorage.getItem('academic'));
    return session;
  }

}

const instance = new LocalStorageService();
Object.freeze(instance);

export default instance;
