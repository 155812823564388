import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const quizzesServices = {
  create,
  update,
  deleteQuiz,
  getByOwner,
  quizDetail,
  getById,
  shareQuiz,
  importQuiz,
}

async function getByOwner(owner) {
  return await HTTP.get(`${ROUTES_API.QUIZZES.GET_BY_OWNER}${owner}`)
}

async function getById(_id) {
  return await HTTP.get(`${ROUTES_API.QUIZZES.GET_BY_ID}${_id}`)
}

async function create(quiz) {
  return await HTTP.post(ROUTES_API.QUIZZES.CREATE, quiz);
}

async function update(quiz) {
  return await HTTP.post(ROUTES_API.QUIZZES.UPDATE, quiz);
}

async function deleteQuiz(quiz) {
  return await HTTP.post(ROUTES_API.QUIZZES.DELETE, quiz);
}

async function quizDetail(_id) {
  return await HTTP.get(`${ROUTES_API.QUIZZES.QUIZ_DETAIL}${_id}`)
}

async function shareQuiz(quiz) {
  return await HTTP.post(`${ROUTES_API.QUIZZES.SHARE_QUIZ}`, quiz);
}

async function importQuiz(quiz, file) {
  return await HTTP.post(`${ROUTES_API.QUIZZES.IMPORT_QUIZ}${quiz}`, file);
}
