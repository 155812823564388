const GROUP = 'groups/';
const ACADEMICS = 'academic/';
const LEVEL = 'levels/';
const AUTH = 'auth/'
const COUNTRIES = 'countries';
const INSTITUTES = 'institutes';
const QUIZZES = 'quizzes';
const QUESTIONS = 'questions';
const REFERENCES = 'references';
const STUDENTS = 'students';
const CAMPAIGNS = 'campaigns';
const TEAM_CHALLENGES = 'team-challenges';
const NOTIFICATIONS = 'notifications';
const STATS = 'stats';

const ROUTES_API = {
  GROUP: {
    CREATE: GROUP + 'create',
    UPDATE: GROUP + 'update',
    DELETE: GROUP + 'delete',
    GET_GROUP_DETAIL: GROUP + 'get-group-detail/',
    GET_SETTINGS: `${GROUP}settings/`,
    SAVE_SETTINGS: `${GROUP}settings`,
    REMOVE_STUDENT: `${GROUP}remove-student`,
    REMOVE_QUIZ: `${GROUP}remove-quizzes`,
    ASSOCIATE_QUIZ: `${GROUP}associate-quizzes`,
    GET_QUIZZES: `${GROUP}quizzes/`,
    GET_BY_ACADEMIC: `${GROUP}academic/`
  },
  ACADEMICS: {
    INSTITUTES: `${ACADEMICS}`,
    GET_BY_ACADEMIC: `${ACADEMICS}get-by-academic/`,
    SET_FINISHED_TUTORIALS: `${ACADEMICS}set-finished-tutorials/`,
  },
  LEVEL: {
    GET_ALL: `${LEVEL}get-all`,
  },
  AUTH: {
    VALIDATE_SESSION: `${AUTH}validate-session`,
    LOGIN: `${AUTH}login`,
    REGISTER: `${AUTH}register`,
    FORGOT_PASSWORD: `${AUTH}forgot-password`,
    VALIDATE_REQUEST_RESET: `${AUTH}validate-request`,
    RESET_PASSWORD: `${AUTH}reset-password`,
  },
  COUNTRIES: {
    GET_ALL: `${COUNTRIES}`,
    GET_ONE: `${COUNTRIES}/:country`
  },
  INSTITUTES: {
    CREATE: `${INSTITUTES}/create`,
    UPDATE: `${INSTITUTES}/update`,
    DELETE: `${INSTITUTES}/delete`
  },
  QUIZZES: {
    CREATE: `${QUIZZES}/create`,
    UPDATE: `${QUIZZES}/update`,
    DELETE: `${QUIZZES}/delete`,
    GET_BY_OWNER: `${QUIZZES}/get-by-owner/`,
    GET_BY_GROUP: `${QUIZZES}/group/`,
    QUIZ_DETAIL: `${QUIZZES}/quiz-detail/`,
    GET_BY_ID: `${QUIZZES}/get-by-id/`,
    SHARE_QUIZ: `${QUIZZES}/share/`,
    IMPORT_QUIZ: `${QUIZZES}/import/`,
  },
  QUESTIONS: {
    CREATE: `${QUESTIONS}/create`,
    UPDATE: `${QUESTIONS}/update`,
    DELETE: `${QUESTIONS}/delete`,
    UPLOAD_IMAGE: `${QUESTIONS}/image/upload`,
    GET_BY_ID: `${QUESTIONS}/get-by-id/`,
    GET_BY_QUIZ: `${QUESTIONS}/quiz/`,
  },
  REFERENCES: {
    CREATE: `${REFERENCES}/create`,
    UPDATE: `${REFERENCES}/update`,
    DELETE: `${REFERENCES}/delete`,
    GET_BY_ID: `${REFERENCES}/get-by-id/`,
    GET_BY_QUIZ: `${REFERENCES}/quiz/`,
  },
  STUDENTS: {
    GET_BY_GROUP: `${STUDENTS}/group/`,
  },
  CAMPAIGNS: {
    GET_BY_GROUP: `${CAMPAIGNS}/group/`,
    GET_DETAIL: `${CAMPAIGNS}/detail/`,
    SAVE: `${CAMPAIGNS}`,
    CLOSE: `${CAMPAIGNS}/close`,
    DELETE: `${CAMPAIGNS}/delete`,
    RANKING: `${CAMPAIGNS}/:campaign/ranking`,
    REPORT: `${CAMPAIGNS}/report/:campaign`
  },
  TEAM_CHALLENGES: {
    SAVE: `${TEAM_CHALLENGES}`,
    UPDATE: `${TEAM_CHALLENGES}/update`,
    CLOSE: `${TEAM_CHALLENGES}/close`,
    DELETE: `${TEAM_CHALLENGES}/delete`,
    GET_BY_GROUP: `${TEAM_CHALLENGES}/group/`,
    GET_DETAIL: `${TEAM_CHALLENGES}/detail/`,
    GET_TEAMS: `${TEAM_CHALLENGES}/:challenge/teams`,
    REPORT: `${TEAM_CHALLENGES}/report/:challenge`,
    GET_STATS: `${TEAM_CHALLENGES}/stats-challenge/:challenge`,
    GET_SCHEDULE: `${TEAM_CHALLENGES}/rounds/:challenge`,
  },
  NOTIFICATIONS: {
    GET_BY_ACADEMIC: `${NOTIFICATIONS}/academic/:academic`,
    GET_DETAIL: `${NOTIFICATIONS}/detail/:id`,
    SAVE: `${NOTIFICATIONS}`
  },
  STATS: {
    GET_GROUP_ACCURACY: `${STATS}/group-accuracy/:group`,
    GET_GROUP_PERFORMANCE_THEME: `${STATS}/group-performance-theme/:group`,
    GET_CAMPAIGN_QUIZZES_STATS: `${STATS}/campaign-quizzes/:campaign`,
    GET_CAMPAIGN_CHALLENGES: `${STATS}/campaign-challenges/:campaign`,
    GET_CAMPAIGN_STATS: `${STATS}/campaign/:campaign`
  }
}

export default ROUTES_API;
