import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const groupService = {
    save,
    update,
    deleteGroup,
    getGroupDetail,
    getSettings,
    getQuizzes,
    saveSettings,
    removeStudent,
    removeQuizzes,
    associateQuizzes,
    getAllByAcademic,
}

async function getAllByAcademic(academicID) {
    return await HTTP.get(`${ROUTES_API.GROUP.GET_BY_ACADEMIC}${academicID}`);
}

async function save(group) {
    return await HTTP.post(ROUTES_API.GROUP.CREATE, group);
}

async function update(group) {
    return await HTTP.post(ROUTES_API.GROUP.UPDATE, group);
}

async function deleteGroup(_id) {
    return await HTTP.post(ROUTES_API.GROUP.DELETE, _id)
}

async function getGroupDetail(_id) {
    return await HTTP.get(`${ROUTES_API.GROUP.GET_GROUP_DETAIL}${_id}`)
}

async function getSettings(groupId) {
    return await HTTP.get(`${ROUTES_API.GROUP.GET_SETTINGS}${groupId}`);
}

async function saveSettings(settings) {
    return await HTTP.post(`${ROUTES_API.GROUP.SAVE_SETTINGS}`, settings);
}

async function removeStudent(group, student) {
    let params = { group, student };
    return await HTTP.post(`${ROUTES_API.GROUP.REMOVE_STUDENT}`, params);
}

async function associateQuizzes(group, quizzes) {
    let params = { group, quizzes };
    return await HTTP.post(`${ROUTES_API.GROUP.ASSOCIATE_QUIZ}`, params);
}

async function removeQuizzes(group, quizzes) {
    let params = { group, quizzes };
    return await HTTP.post(`${ROUTES_API.GROUP.REMOVE_QUIZ}`, params);
}

async function getQuizzes(groupId) {
    return await HTTP.get(`${ROUTES_API.GROUP.GET_QUIZZES}${groupId}`);
}