import React from 'react';
import Rating from 'react-rating';
import { Icon } from '../ui';

function QuizRating(props) {
  return (
    <Rating
      emptySymbol={<Icon color="primary" size={18} name="star_border" />}
      fullSymbol={<Icon color="primary" size={18} name="star" />}
      {...props}
    />
  );
}

export default QuizRating;