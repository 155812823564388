import React, { Component } from 'react'
import { Icon, IconButton, List, ListItem, ListItemIcon, ListItemText } from '../../components/ui'

class Notifications extends Component {

  _renderNotifications() {
    if (this.props.notifications) {
      return this.props.notifications.map(item => {
        return (
          <div key={`not-${item._id}`}>
            <ListItem onClick={() => this.props.goToDetail(item._id)}>
              <ListItemIcon>
                <Icon 
                  name="notifications"
                />
              </ListItemIcon>
              <ListItemText 
                primary={item.title}
                secondary={item.date}
              />
              <ListItemIcon>
                <Icon 
                  name="navigate_next"
                />
              </ListItemIcon>
            </ListItem>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="notifications">
        <div className="notifications-header">
          <IconButton 
            name="close"
            onClick={this.props.onClose}
          />
          <h3>Notificaciones</h3>
        </div>
        <div>
          <List className="notifications-list">
            {this._renderNotifications()}
          </List>
        </div>
        <div className="notifications-footer">
          <button 
            type="button" 
            className="btn btn-link btn-block"
            onClick={this.props.onClickAdd}
          >
            Nueva notificación
            <Icon name="add" />
          </button>
        </div>
      </div>
    );
  }
}

export default Notifications;