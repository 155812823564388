import React from 'react';
import { Icon } from '../ui';
import Countdown from './countdown';
import EventProgressBar from './event-progress-bar';

function EventStatusProgress(props) {
  let eventIcon = 'save';
  let statusText = 'Borrador desde';
  let progress = 100;
  let today = new Date();

  if (props.eventStatus === 'active' && props.eventStartDate < today && props.eventEndDate > today) {
    eventIcon = 'wb_sunny';
    statusText = 'Finaliza en';
    if (props.eventStartDate < today) {
      let startTime = props.eventStartDate.getTime();
      let endTime = props.eventEndDate.getTime();
      let currentTime = today.getTime();

      progress = (endTime - currentTime) / (endTime - startTime) * 100;
    }
  } else if (props.eventStatus === 'active' && props.eventStartDate > today) {
    eventIcon = 'wb_sunny';
    statusText = 'Inicia el'
  } else if (props.eventStatus === 'closed' || props.eventEndDate < today) {
    eventIcon = 'alarm_off';
    statusText = 'Evento finalizado';
  }

  return (
    <div className="event-status-progress mt-3 pb-2">
      <div className="event-status-icon text-primary">
        <Icon name={eventIcon} />
      </div>
      <div className="event-status-date-wrapper p-2">
        <div className="event-status-title text-muted">{statusText}</div>
        <div className="event-status-counter text-accent">
          { props.eventStatus === 'active' && props.eventStartDate > today &&
            <div>{`${props.eventStartDate.toLocaleString()}`}</div>
          }
          { props.eventStatus === 'active' && props.eventStartDate < today && props.eventEndDate > today &&
            <Countdown date={props.eventEndDate} />
          }
          { props.eventStatus === 'draft' && props.eventCreation &&
            <div>{`${props.eventCreation.toLocaleDateString()}`}</div>
          }
          { props.eventStatus === 'closed' &&
            <div>{`${props.eventCompletionDate.toLocaleDateString()}`}</div>
          }
          { props.eventStatus === 'active' && props.eventEndDate < today &&
            <div>{`${props.eventCompletionDate.toLocaleDateString()}`}</div>
          }
        </div>
      </div>
      { props.eventStatus === 'active' && props.eventEndDate > today && 
      <EventProgressBar progress={progress} style={{marginBottom: '-0.5rem'}} />
      }
    </div>
  );
}

export default EventStatusProgress;