import React, { useContext } from 'react';
import OnboardingLayout from '../../components/layout/onboardingLayout';
import { Accordion, Card } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from "react-bootstrap/AccordionContext";
import { IconButton } from '../../components/ui';

const OnboardingReview = ({ onboardingSteps, finishOnboarding }) => (
    <OnboardingLayout>
        <div className="card card-viewer col-11 col-md-11 col-xl-10 p-4">
            <div className="card-main-header">
                <IconButton 
                    color="primary"
                    name="arrow_back"
                    onClick={finishOnboarding}
                />
                <h1>Tutoriales</h1>
            </div>
            <Accordion defaultActiveKey="1">
                {
                    onboardingSteps.map(onboardingStep => <ItemCollapse key={`ItemCollapse${onboardingStep.eventKey}`} {...onboardingStep} />)
                }
            </Accordion>
        </div>
    </OnboardingLayout>
)

const ContextAwareToggle = ({ eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button type="button" className="btn-icon" onClick={decoratedOnClick}>
            <i className="material-icons mr-0">{isCurrentEventKey ? "close" : "add"}</i>
        </button>
    );
}

const ItemCollapse = ({ title, description, embedId, eventKey }) => {
    const currentEventKey = useContext(AccordionContext);

    return (
        <>
            <Card.Header style={{ padding: '10px 0' }}>
                <div className="d-flex justify-content-between">
                    <h2 className="mb-1">{title}</h2>
                    <ContextAwareToggle eventKey={`${eventKey}`} />
                </div>
            </Card.Header>
            <Accordion.Collapse eventKey={`${eventKey}`}>
                <div>
                    <h4>{description}</h4>
                    <hr/>
                    <div className="p-2 pt-4">
                        { currentEventKey === `${eventKey}` &&
                            <IframeCustom embedId={embedId} />
                        }
                    </div>
                </div>
            </Accordion.Collapse>
        </>
    );
}


const IframeCustom = ({ embedId }) => (
    <div className="video-responsive review mb-3">
        <iframe
            src={`https://www.youtube.com/embed/${embedId}?rel=0&modestbranding=1&showinfo=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
)

export default OnboardingReview;