import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';
import LOGO from '../../assets/images/Logo-Positive.svg';
import { IconButton } from '../ui';

function Header(props) {
  return (
    <header className="header">
      <div className="topbar">
        <div className="container">
          <div className="logo">
            <Link to={ROUTES.HOME}>
              <img src={LOGO} alt="GameIF Academy" />
            </Link>
          </div>
          <ul className="list-inline float-right">
            <li className="list-inline-item">
              <button 
                className="nav-user"
                onClick={props.onClickProfile}
              >
                <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=100" alt="Mi Perfil" className="rounded-circle" />
              </button>
            </li>
            <li className="list-inline-item">
              <IconButton 
                name="markunread_mailbox" 
                onClick={props.onClickNotification}
              />
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
      <div className="main-navbar">
        <div className="container">
          {props.children}
        </div>
      </div>
    </header>
  );
}

export default Header;