import React from 'react';
import { Link } from 'react-router-dom';

function TitleNavbar(props) {
  return (
    <div className="navbar title-navbar">
      <div className="nav-back">
        <Link to={props.linkBack}>
          <i className="material-icons arrow-back">arrow_back</i>
        </Link>
      </div>
      <div className="navbar-title">
        <h4>{props.title}</h4>
      </div>
      {
        props.navbarActions &&
        <div className="navbar-actions">
          {props.navbarActions}
        </div>
      }
    </div>
  );
}

export default TitleNavbar;