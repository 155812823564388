export const ENVIROMENT = {
  production: false
};

const ENV_DEV = {
  HOST: 'http://localhost:8080',
  LANDING_PAGE: 'http://localhost'
}

const ENV_STAGE = {
  HOST: 'https://api.gameifacademy.media/',
  LANDING_PAGE: 'http://gameifacademy.media/'
}

let __HOST__;
let __LANDING_PAGE__;

switch (process.env.REACT_APP_STAGE) {
  case 'stage':
    __HOST__         = ENV_STAGE.HOST;
    __LANDING_PAGE__ = ENV_STAGE.LANDING_PAGE;
    break;
  default:
    __HOST__         = ENV_DEV.HOST;
    __LANDING_PAGE__ = ENV_DEV.LANDING_PAGE;
}

export const END_POINT    = __HOST__;
export const LANDING_PAGE = __LANDING_PAGE__;
export const PRIVACY_URL = 'http://gameifacademy.media/aviso-de-privacidad.pdf'; 
export const TERMS_URL = 'http://gameifacademy.media/aviso-de-privacidad.pdf'; 
