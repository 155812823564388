import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const QuestionsServices = {
    create, 
    update, 
    deleteQuestion, 
    uploadImage, 
    getById,
    getByCategory
}

async function getByCategory(category) {
    return await HTTP.get(`${ROUTES_API.QUESTIONS.GET_BY_CATEGORY}${category}`);
}

async function create(question) {
    return await HTTP.post(ROUTES_API.QUESTIONS.CREATE, question)
}

async function update(question) {
    return await HTTP.post(ROUTES_API.QUESTIONS.UPDATE, question)
}

async function deleteQuestion(question) {
    return await HTTP.post(ROUTES_API.QUESTIONS.DELETE, question)
}

async function uploadImage(questionID, image, onUploadProgress) {
    const configUploadProgress = {
        onUploadProgress: onUploadProgress
    };
    return await HTTP.post(`${ROUTES_API.QUESTIONS.UPLOAD_IMAGE}`, image, configUploadProgress);
}

async function getById(_id) {
    return await HTTP.get(`${ROUTES_API.QUESTIONS.GET_BY_ID}${_id}`)
}