import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Avatar, IconButton, TextField } from '../ui';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import DEFAULT_AVATAR from  '../../assets/images/default-avatar.png';

function ListTeams(props) {
  const [expandedIndex, setExpandedIndex] = React.useState("");
  const { teams, onChangeTeams, onMoveMember, onRemoveMember } = props;

  const handleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex("")
    } else {
      setExpandedIndex(index)
    }
  };

  const changeNameTeamHandler = (e, teamIndex) => {
    let value = e.target.value;
    teams[teamIndex].name = value;

    onChangeTeams(teams);
  }

  return (
    <List>
      { teams && teams.map((team, index) => {
        let iconItem = index === expandedIndex ? 'expand_less' : 'expand_more';
        let collapseShow = index === expandedIndex ? 'show' : '';
        return (
          <div key={`team_${index}`}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className="text-primary" icon="people" />
              </ListItemAvatar>
              <ListItemText>
                <TextField
                  placeholder="Nombre del equipo" 
                  name="teamName[]"
                  value={team.name}
                  onChange={(e) => changeNameTeamHandler(e, index)}
                />
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  name={iconItem}
                  onClick={() => handleExpand(index)}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <div className={`collapse ${collapseShow}`}>
              <List className="pl-3">
                { team.members && team.members.map(member => {
                  return (
                    <ListItem key={`member_${member._id}`}>
                      <ListItemAvatar>
                        <Avatar 
                          src={DEFAULT_AVATAR}
                        />
                      </ListItemAvatar>
                      <ListItemText 
                        primary={ member.nickName  }
                        secondary={ member.name && `${member.name} ${member.lastname}` }
                      />
                      <ListItemSecondaryAction>
                        <div className="dropdown-primary-up">
                          <DropdownButton
                            drop='up'
                            variant="icon arrow-none"
                            alignRight
                            title={<i className="material-icons">more_vert</i>}
                            id='dropdown-button-drop'
                          >
                            <Dropdown.Item eventKey="1" >
                              <div 
                                className="d-flex justify-content-between align-items-center" 
                                onClick={() => onMoveMember(member, index)} >
                                <span>Cambiar de equipo</span>
                                <i className="material-icons md-18 ml-2">assignment_late</i>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" >
                              <div 
                                className="d-flex justify-content-between align-items-center" 
                                onClick={() => onRemoveMember(member, index)} >
                                <span>Quitar del equipo</span>
                                <i className="material-icons md-18 ml-2">close</i>
                              </div>
                            </Dropdown.Item>
                          </DropdownButton>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }) 
                }
              </List>
            </div>
          </div>
        );
      }) 
      }
    </List>
  );
}

export default ListTeams;