import React from 'react';
import Icon from './icon';

function Avatar(props) {
  let children = null;
  let classesContainer = 'avatar';

  if (props.src) {
    children = (
      <img src={props.src} alt={props.alt} />
    );
  } else if (props.title) {
    children = props.title;
  } else if (props.icon) {
    children = (<Icon name={props.icon} />);
  }

  if (props.className) {
    classesContainer = `${classesContainer} ${props.className}`;
  }

  return (
    <div className={classesContainer}>
      {children}
    </div>
  );
}

export default Avatar;