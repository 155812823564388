import React, { Component } from 'react';
import swal from '@sweetalert/with-react';
import ModalForm from '../../components/ui/modal-form';
import LANG from '../../const/language.const';
import TextField from '../../components/ui/text-field';
import SelectField from '../../components/ui/select-field';
import LevelService from '../../services/level.service';

class ModalFormQuiz extends Component {
  constructor(props){
    super(props);

    this.state = {
      quiz: {},
      errors: {},
      levels: [],
      selectedLevel: null,
      selectedDegree: null,
    }

    this.getLevels = this.getLevels.bind(this);
    this.getLevelsOptions = this.getLevelsOptions.bind(this);
    this.getDegreesOptions = this.getDegreesOptions.bind(this);
    this.changeFieldHandler = this.changeFieldHandler.bind(this);
    this.changeLevelHandler = this.changeLevelHandler.bind(this);
    this.changeDegreeHandler = this.changeDegreeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  async componentDidMount() {
    let quiz = this.state.quiz;
    let levels = await this.getLevels();
    let selectedLevel = null;
    let selectedDegree = null;
    if (this.props.dataQuiz) {
      quiz = this.props.dataQuiz;
      selectedLevel = this.filterDefaultLevel(levels, quiz.level);
      selectedDegree = this.filterDefaultDegree(levels, quiz.level, quiz.degree);
    }

    this.setState({
      quiz,
      levels,
      selectedLevel,
      selectedDegree,
    });
  }

  filterDefaultLevel(levels, levelID) {
    let level = levels.find(item => item._id === levelID);
    let defaultObj = level ? { label: level.name, value: level._id } : null;
    return defaultObj;
  }

  filterDefaultDegree(levels, levelID, degreeID) {
    let level = levels.find(item => item._id === levelID);
    let defaultObj = null;
    if (level) {
      let degree = level.degrees.find(item => item._id === degreeID);
      defaultObj = degree ? { label: degree.name, value: degree._id } : null;
    }
    
    return defaultObj;
  }

  async getLevels() {
    try {
      const response = await LevelService.getAll();
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        this.onError(error.response.data.message);
      } else {
        this.onError('Error al cargar los datos');
      }
    }
  }

  getLevelsOptions() {
    return this.state.levels.map(item => {
      return {
        value: item._id,
        label: item.name
      }
    });
  }

  getDegreesOptions() {
    const { quiz, levels } = this.state;
    if (quiz.level) { 
      let currentLevel = levels.find(item => item._id === quiz.level);
      if (currentLevel) {
        return currentLevel.degrees.map(item => {
          return {
            value: item._id,
            label: item.name
          }
        });
      }
    }

    return [];
  }

  changeFieldHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    let quiz = this.state.quiz;
    let errors = this.state.errors;
    quiz[name] = value;
    errors[name] = '';
    
    this.setState({ quiz, errors });
  }

  changeLevelHandler(selectedOption) {
    let quiz = this.state.quiz;
    let errors = this.state.errors;
    quiz.level = selectedOption.value;
    quiz.degree = '';
    errors.level = '';

    this.setState({
      selectedLevel: selectedOption,
      quiz,
      errors
    });
  }

  changeDegreeHandler(selectedOption) {
    let quiz = this.state.quiz;
    let errors = this.state.errors;
    quiz.degree = selectedOption.value;
    errors.degree = '';

    this.setState({
      selectedDegree: selectedOption,
      quiz,
      errors
    });
  }

  submitHandler() {
    let errors = this.validateForm();
    if (errors) {
      this.setState({ errors });
      return;
    }

    this.props.saveQuiz(this.state.quiz);
  }

  validateForm() {
    const { quiz } = this.state;
    let validForm = true;
    let errors = {};

    if (!quiz.name) {
      validForm = false;
      errors.name = LANG.ERRORS.REQUIRED_FIELD;
    }

    if (!quiz.level) {
      validForm = false;
      errors.level = LANG.ERRORS.REQUIRED_FIELD;
    }

    if (!quiz.degree) {
      validForm = false;
      errors.degree = LANG.ERRORS.REQUIRED_FIELD;
    }

    if (!quiz.topic) {
      validForm = false;
      errors.topic = LANG.ERRORS.REQUIRED_FIELD;
    }

    return validForm ? null : errors;
  }

  onError(errorMessage) {
    swal({
      text: errorMessage,
      icon: 'error',
      button: 'ok',
    });
  }

  render() {
    const { quiz } = this.state;
    const modalTitle = quiz._id
      ? LANG.QUIZZES.FORM.TITLE_EDIT
      : LANG.QUIZZES.FORM.TITLE_ADD;
    
    return (
      <ModalForm
        title={modalTitle}
        isOpen={this.props.isOpen}
        onHide={this.props.onClose}
      >
        <form className="form form-quizzes px-5">
          <TextField 
            name="name"
            placeholder={LANG.QUIZZES.FORM.NAME}
            value={quiz.name || ''}
            errorMessage={this.state.errors.name}
            onChange={this.changeFieldHandler}
          />
          <SelectField 
            name="level"
            value={this.state.selectedLevel}
            options={this.getLevelsOptions()}
            placeholder={LANG.QUIZZES.FORM.LEVEL}
            onChange={this.changeLevelHandler}
            errorMessage={this.state.errors.level}
          />
          <SelectField 
            name="state"
            value={this.state.selectedDegree}
            options={this.getDegreesOptions()}
            placeholder={LANG.QUIZZES.FORM.DEGREE}
            onChange={this.changeDegreeHandler}
            errorMessage={this.state.errors.degree}
          />
          <TextField 
            name="topic"
            placeholder={LANG.QUIZZES.FORM.TOPIC}
            value={quiz.topic || ''}
            errorMessage={this.state.errors.topic}
            onChange={this.changeFieldHandler}
          />
          <div className="form-actions mt-5">
            <button 
              type="button"
              className="btn btn-outline-primary btn-rounded btn-block"
              onClick={this.submitHandler}
            >
              {LANG.QUIZZES.FORM.BTN_SAVE}
            </button>
          </div>
        </form>
      </ModalForm>
    );
  }
}

export default ModalFormQuiz;