import React from 'react';

function ListItem(props) {
  const { children, className, verticalAlign, ...otherProps } = props;
  let elementClasses = 'list-group-item d-flex justify-content-start';
  
  switch(verticalAlign) {
    case 'bottom':
      elementClasses = `${elementClasses} align-items-end`; 
      break;
    case 'center':
    case 'middle':
      elementClasses = `${elementClasses} align-items-center`; 
      break;
    case 'top':
    default:
      elementClasses = `${elementClasses} align-items-start`; 
      break;
  }

  if (className) {
    elementClasses = `${elementClasses} ${className}`; 
  }


  return (
    <li 
      className={elementClasses}
      { ...otherProps }
    >
      { children }
    </li>
  );
}

export default ListItem;