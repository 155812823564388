import React from 'react';
import FACEBOOK from '../../assets/images/FB.svg';
import TWITTER from '../../assets/images/Twitter.svg';
import INSTAGRAM from '../../assets/images/Instagram.svg';
import { Button, IconButton } from '../ui';
import { avatar } from '../../const/avatars.conts';

function CardStudent({ 
   student,
   removeHandler 
}) {
   let studentName = student.name ? student.name : '';
   studentName += student.lastname ? ` ${student.lastname}` : '';
   let studentPoints = student.points && student.points.length ? student.points[0].points : 0;

   const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

   return (
      <div className="card card-student">
         <div className="card-body ">
            <div className="card-image-student mr-4">
               <img src={avatar[student.avatar]} alt="" />
            </div>
            <div className="card-student-details">
               <h3 className="card-title text-primary">{student.nickName}</h3>
               <p className="text-card-student">{numberWithCommas(studentPoints)} Puntos</p>
               <p className="text-card-student">{studentName}</p>
               <p className="text-card-student">{student.email ? student.email : ''}</p>
               <ul className="data-stats d-flex pt-1">
                  {/* <li className="mr-3">
                     <img src={FACEBOOK} alt="Facebook" />
                  </li>
                  <li className="mr-3" >
                     <img src={TWITTER} alt="Twitter" />
                  </li>
                  <li>
                     <img src={INSTAGRAM} alt="Instargram" />
                  </li> */}
                  <li className="delete-in-card">
                     <IconButton 
                        name="delete"
                        onClick={() => removeHandler(student)}
                     />
                  </li>
               </ul>
            </div>
         </div>
      </div>
   );
}

export default CardStudent;