import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './modal-form-campaign.scss';
import TextFieldDecorate from '../../../components/ui/text-field-decorate';
import Button from '../../../components/ui/button';
import { addDays, addHours } from 'date-fns'
import DateTimePickerField from '../../../components/ui/date-time-picker-field';
import SearchBar from '../../../components/ui/search-bar';
import CardRadioButton from '../../../components/ui/card-radio-button';
import { campaignsService, groupService } from '../../../services';
import LANG from '../../../const/language.const';
import ListQuizzes from '../../../components/list-quizzes/list-quizzes';
import { accentFold } from '../../../utilities/helpers';
import swal from '@sweetalert/with-react';
import EVENT_STATUS from '../../../const/event-status.const';


class ModalFormCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: props.group,
      currentStep: 1,
      campaign: {
        name: '',
        rounds: 1,
        questionsNumber: 2,
        startDate: addHours(new Date(), 1),
        endDate: addDays(addHours(new Date(), 1), 1),
        status: 'active',
        quizzes: []
      },
      quizzes: [],
      filterQuizzes: [],
      searchQuiz: '',
      customNumQuestions: false,
      errors: {},
    };

    this.setInitCampaign = this.setInitCampaign.bind(this);
    this.setStartDateHandler = this.setStartDateHandler.bind(this);
    this.setEndDateHandler = this.setEndDateHandler.bind(this);
    this.selectRounds = this.selectRounds.bind(this);
    this.selectNumQuestions = this.selectNumQuestions.bind(this);
    this.changeCustomNumQuestions = this.changeCustomNumQuestions.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.selectQuizHandler = this.selectQuizHandler.bind(this);
    this.searchQuizHandler = this.searchQuizHandler.bind(this);
    this.saveCampaign = this.saveCampaign.bind(this);
    this.closeHandler = this.closeHandler.bind(this);
    this.getCampaign = this.getCampaign.bind(this);
    this.beforeOpenHandler = this.beforeOpenHandler.bind(this);
  }

  componentDidMount() {
    this.getQuizzes();
  }

  setInitCampaign() {
    let campaign = {
      _id: null,
      name: '',
      rounds: 1,
      questionsNumber: 2,
      startDate: addHours(new Date(), 1),
      endDate: addDays(addHours(new Date(), 1), 1),
      status: 'active',
      quizzes: []
    };

    this.setState({ campaign, currentStep: 1, });
  }

  beforeOpenHandler() {
    if (this.props.editCampaign) {
      this.getCampaign(this.props.editCampaign);
    }
  }

  async getQuizzes() {
    try {
      if (this.props.group) {
        const response = await groupService.getQuizzes(this.props.group);
        this.setState({
          quizzes: response.data,
          filterQuizzes: response.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getCampaign(campaignId) {
    try {
      const response = await campaignsService.getDetail(campaignId);
      if (response.data) {
        response.data.status = EVENT_STATUS.ACTIVE;
        response.data.createdAt = new Date(response.data.createdAt);
        response.data.endDate = new Date(response.data.endDate);
        response.data.startDate = new Date(response.data.startDate);
        this.setState({ campaign: response.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async saveCampaign() {
    if (this.state.campaign.status === 'active' && this.state.campaign.quizzes.length < 1) {
      return null;
    }

    try {
      const { campaign, group } = this.state;
      campaign.group = group;
      await campaignsService.save(campaign);
      this.props.onSaved();
    } catch (error) {
      console.log(error);
    }
  }

  setStartDateHandler(date) {
    console.log('change start date', date);
    let campaign = this.state.campaign;
    campaign.startDate = date;

    if (date > campaign.endDate) {
      campaign.endDate = addHours(date, 1);
    }

    this.setState({ campaign });
  }

  setEndDateHandler(date) {
    let campaign = this.state.campaign;
    let hasErrors = { ...this.state.errors }
    campaign.endDate = date;

    if (date > campaign.startDate) {
      hasErrors.rangeDate = '';
    } else {
      hasErrors.rangeDate = 'La fecha de fin debe ser mayor a la fecha de inicio';
    }

    this.setState({ campaign, errors: hasErrors });
  }

  onChangeHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    let campaign = this.state.campaign;
    let errors = this.state.errors;
    campaign[name] = value;
    errors[name] = '';

    this.setState({ campaign, errors });
  }

  selectRounds(rounds) {
    let { campaign } = this.state;
    campaign.rounds = rounds;

    this.setState({
      campaign
    });
  }

  selectNumQuestions(e) {
    let value = e.target.value;
    let customNumQuestions = false;
    let { campaign } = this.state;
    
    if (value === 'personalizado') {
      campaign.questionsNumber = 3;
      customNumQuestions = true;
    } else {
      campaign.questionsNumber = value;
    }

    this.setState({ 
      campaign, 
      customNumQuestions 
    });
  }

  changeCustomNumQuestions(e) {
    let { campaign } = this.state;
    let value = e.target.validity.valid ? e.target.value : campaign.questionsNumber;
    campaign.questionsNumber = value;

    this.setState({ campaign });
  }

  selectQuizHandler(selectedQuizzes) {
    let { campaign } = this.state;
    campaign.quizzes = selectedQuizzes;

    this.setState({ campaign });
  }

  searchQuizHandler(query) {
    const { quizzes } = this.state;
    let results = quizzes;

    if (query) {
      let strSearch = accentFold(query);
      let expSearch = new RegExp(`${strSearch}.*`, 'i');
      results = quizzes.filter(item => {
        return expSearch.test(accentFold(item.name));
      });
    }

    this.setState({ 
      searchQuiz: query,
      filterQuizzes: results 
    });
  }

  nextStep() {
    const { currentStep, campaign, errors } = this.state;
    let hasErrors = { ...errors };
    let isCorrectRangeDates = campaign.startDate < campaign.endDate;
    
    if (campaign.name && isCorrectRangeDates && currentStep < 3) {
      this.setState({ currentStep: (currentStep + 1), errors: {} });
    } else if(!campaign.name) {
      hasErrors.name = LANG.ERRORS.REQUIRED_FIELD;
      this.setState({ errors: hasErrors });
    } else if (!isCorrectRangeDates) {
      hasErrors.rangeDate = 'La fecha de fin debe ser mayor a la fecha de inicio';
      this.setState({ errors: hasErrors });
    }
  }

  previousStep() {
    const { currentStep } = this.state;
    if (currentStep > 1) {
      this.setState({ currentStep: (currentStep - 1) });
    }
  }

  async closeHandler() {
    if (this.state.campaign.name) {
      let isConfirm = await swal({
        title: "¿Deseas cancelar la creación del evento?",
        text: "Recuerda que puedes retormarlo después guardandolo como borrador",
        icon: "warning",
        buttons: {
          cancel: 'Continuar editando',
          defeat: 'Borrar',
          catch: {
            text: "Guardar como borrador",
            value: "catch",
          },
        },
        dangerMode: true,
      });

      switch (isConfirm) {
        case "defeat":
          this.props.onClose();
          break;
        case "catch":
          let campaign = this.state.campaign;
          campaign.status = 'draft';
          this.setState({ campaign }, this.saveCampaign);
          break;
        default:
          break;
      }
    } else {
      this.props.onClose();
    }
  }

  async onSucces() {
    await swal({
      text: 'Los datos han sido guardados',
      icon: 'success',
      button: 'ok',
    });
  }

  onError(errorMessage) {
    swal({
      text: errorMessage,
      icon: 'error',
      button: 'ok',
    });
  }

  _renderHeader() {
    let titles = [
      'Información del evento',
      'Configuración de rondas',
      'Añadir cuestionarios para jugar'
    ];
    return (
      <>
        <div className="modal-close mb-3">
          <button 
            type="button" 
            className="btn btn-link text-capitalize"
            onClick={this.closeHandler}
          >
            <i className="material-icons">close</i>Cerrar
          </button>
        </div>
        <div className="modal-title">
          <ol className="wizard-indicators float-right">
            <li className={this.state.currentStep >= 1 ? 'active' : ''}></li>
            <li className={this.state.currentStep >= 2 ? 'active' : ''}></li>
            <li className={this.state.currentStep === 3 ? 'active' : ''}></li>
          </ol>
          <h4>{titles[this.state.currentStep - 1]}</h4>
        </div>
        { this.state.currentStep === 3 &&
          <div className="modal-filters">
            <SearchBar 
              showCancelSearch={true}
              search={this.state.searchQuiz}
              onChange={this.searchQuizHandler}
            />
          </div>
        }
      </>
    )
  }

  _renderBody() {
    const { currentStep, campaign, filterQuizzes, customNumQuestions,errors } = this.state;
    return (
      <form>
        <div className={`form-campaign-info ${currentStep === 1 ? '' : 'd-none'}`}>
          <TextFieldDecorate
            type="text"
            name="name"
            errorMessage={errors.name || ''}
            leftDecorate={<i className="material-icons">whatshot</i>}
            placeholder="Nombre del evento"
            value={campaign.name || ''}
            onChange={this.onChangeHandler}
          />
          <p className="text-primary"><strong>Fecha y hora de inicio</strong></p>
          <DateTimePickerField 
            selected={this.state.campaign.startDate}
            onChange={this.setStartDateHandler}
          />
          <p className="text-primary"><strong>Fecha y hora de fin</strong></p>
          <DateTimePickerField 
            selected={campaign.endDate}
            onChange={this.setEndDateHandler}
          />
          { errors.rangeDate &&
            <p className="help-block text-danger">{errors.rangeDate}</p>
          }
        </div>
        <div className={`form-campaign-settings ${currentStep === 2 ? '' : 'd-none'}`}>
          <p className="text-primary">
            <i className="material-icons md-18">whatshot</i>
            <strong>&nbsp;{campaign.name}</strong>
          </p>
          <p className="text-primary">
            <strong>¿Cuántos rounds tendrá cada enfrentamiento?</strong>
          </p>
          <div className="mb-3">
            <div className="card-group">
              { [1,2,3,4,5].map(item => {
                return (
                  <CardRadioButton 
                    key={`crb-${item}`}
                    value={item}
                    checked={campaign.rounds === item}
                    label={item}
                    labelClass="h4 mb-1 mt-2"
                    onClick={this.selectRounds}
                  />
                )
              })
              }
            </div>
          </div>
          <p className="text-primary">
            <strong>¿Cuántas preguntas por ronda?</strong>
          </p>
          <div className="mb-3">
            {
              [2, 4, 6, 8, 'personalizado'].map(item => {
                let isChecked = customNumQuestions && item === 'personalizado'
                  ? true : (item == campaign.questionsNumber); 
                
                return (
                  <div 
                    key={`irnq-${item}`}
                    className="custom-control custom-radio custom-control-inline">
                    <input 
                      type="radio" 
                      name="questionsNumber"
                      id={`irnq-${item}`}
                      value={item}
                      checked={isChecked}
                      className="custom-control-input" 
                      onChange={this.selectNumQuestions}
                    />
                    <label className="custom-control-label" htmlFor={`irnq-${item}`}>
                      { item }
                    </label>
                  </div>
                );
              })
            }
          </div>
          <div className={`custom-num-questions-field ${customNumQuestions ? '' : 'd-none'}`}>
            <TextFieldDecorate 
              type="numeric"
              name="customNumQuestions"
              leftDecorate={<i className="material-icons">live_help</i>}
              pattern="[0-9]*"
              value={campaign.questionsNumber}
              onChange={this.changeCustomNumQuestions}
            />
          </div>
        </div>
        <div className={`form-campaign-quizzes mb-3 ${currentStep === 3 ? '' : 'd-none'}`}>
          <div className="row">
            <div className="col">
              <h6 className="text-primary">Cuestionarios Disponibles</h6>
            </div>
            <div className="col">
              <Button 
                outline
                color="primary"
                icon="save"
                label="Guardar y crear evento"
                onClick={this.saveCampaign}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ListQuizzes 
                quizzes={filterQuizzes}
                selectedQuizzes={campaign.quizzes}
                changeSelected={this.selectQuizHandler}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }

  _renderFooter() {
    return (
      <Modal.Footer>
        <div className="actions">
          { this.state.currentStep > 1 &&
            <Button 
              outline
              color="primary"
              label="Regresar"
              icon="chevron_left"
              onClick={this.previousStep}
            />
          }
          { this.state.currentStep < 3 &&
            <Button 
              color="outline-primary"
              label="Continuar"
              icon="chevron_right"
              onClick={this.nextStep}
            />
          }
        </div>
      </Modal.Footer>
    );
  }

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        onHide={this.closeHandler}
        onExiting={this.setInitCampaign}
        className="modal-form-campaign"
        onEnter={this.beforeOpenHandler}
      >
        <Modal.Header>
          {this._renderHeader()}
        </Modal.Header>
        <Modal.Body>
          {this._renderBody()}
        </Modal.Body>
        { this.state.currentStep !== 3 && 
          this._renderFooter()
        }
      </Modal>
    );
  }
}

export default ModalFormCampaign;