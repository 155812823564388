import React, { Component } from 'react';
import { TextFieldDecorate, Icon, Button } from '../../components/ui'
import LANG from '../../const/language.const';
import AuthService from '../../services/auth.service';
import ROUTES from '../../const/routes.const';
import { LANDING_PAGE, PRIVACY_URL, TERMS_URL } from '../../enviroments/enviroment';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validRequest: true,
      passwordReset: false,
      academic: '',
      uuid: '',
      errors: {},
      error: '',
      password: '',
      confirmPassword: '',
      passwordRules: {
        length: false,
        number: false,
        symbol: false,
        uppercase: false,
      }
    }

    this.processForm = this.processForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.passwordHandler = this.passwordHandler.bind(this);
    this.handlePasswordConfirm = this.handlePasswordConfirm.bind(this);
    this.validateRequest = this.validateRequest.bind(this);
  }

  componentDidMount() {
    this.validateRequest();
  }

  async validateRequest() {
    try {
      let academic = this.props.match.params.academic;
      let uuid = this.props.match.params.uuid;
      let validRequest = false;
      const response = await AuthService.validateResetRequest(academic, uuid);
      if (response.data && response.data.valid) {
        validRequest = true;
      }
      this.setState({ 
        validRequest,
        academic,
        uuid 
      });
    } catch (e) {
      if (e.response && e.response.data) {
        this.setState({ 
          validRequest: false,
          error: e.response.data.message 
        });
      }
    }
  }

  passwordHandler(event) {
    let password = event.target.value
    let passwordRules = this.state.passwordRules;
    let errors = this.state.errors;
    errors.password = true;

    //validacion contraseña
    passwordRules.length = this._isValidLength(password);
    passwordRules.uppercase = this._isValidUpperCharacter(password);
    passwordRules.number = this._isValidDigit(password);
    passwordRules.symbol = this._isValidSpecialCharacter(password);

    if (passwordRules.length && passwordRules.uppercase && passwordRules.number && passwordRules.symbol) {
      errors.password = false
    }

    this.setState({ 
      password, 
      passwordRules,
      errors 
    })
  }

  handlePasswordConfirm(event) {
    let confirmPassword = event.target.value;
    let errors = this.state.errors;
    
    errors.samePassword = !confirmPassword === this.state.password;

    this.setState({
      confirmPassword,
      errors
    })
  }

  _isValidLength(password) {
    return password && password.trim().length >= 8;
  }

  _isValidUpperCharacter(password) {
    const regExp = new RegExp('[A-Z]');
    return regExp.test(password);
  }

  _isValidDigit(password) {
    const regExp = new RegExp('[0-9]');
    return regExp.test(password);
  }

  _isValidSpecialCharacter(password) {
    const regExp = new RegExp('[$!¡$%$&/$($)=?¿@#{}$[/\\]/]');
    return regExp.test(password);
  }

  processForm() {
    const { password, confirmPassword } = this.state;
    let errors = this.state.errors;
    let validForm = true;

    if (!password || errors.password) {
      validForm = false;
      errors.password = true;
    }

    if (!confirmPassword || errors.samePassword) {
      validForm = false;
      errors.samePassword = true;
    }


    if (validForm) {
      this.submitForm();
    } else {
      this.setState({ errors })
    }
  }

  async submitForm() {
    try {
      const { password, academic, uuid } = this.state;
      await AuthService.changePassword(academic, uuid, password);
      this.setState({ 
        error: '',
        passwordReset: true 
      });
    } catch (e) {
      if (e.response && e.response.data) {
        this.setState({ error: e.response.data.message });
      }
    }
  }


  _renderPasswordReset() {
    return (
      <div className="mb-4">
        <h3 className="title-success">Contraseña actualizada</h3>
        <p className="mb-3">La contraseña de su cuenta ha sido actualizada</p>
        <Button
          color="primary"
          label="Iniciar sesión"
          icon="login"
          onClick={() => this.props.history.push(ROUTES.AUTH.LOGIN)}
        />
      </div>
    );
  }

  _renderForm() {
    return (
      <form>
        <TextFieldDecorate
          type="password"
          name="password"
          placeholder={LANG.AUTH.REGISTER.LABEL_PASSWORD}
          leftDecorate={<Icon name="vpn_key" color="primary" />}
          value={this.state.password}
          onChange={this.passwordHandler}
          errorMessage={this.state.errors && this.state.errors.password ? 'Contraeña no válida' : ''}
        />
        <div className="px-3 mb-3">
          <p>{LANG.AUTH.REGISTER.LABEL_PASSWORD_RULES}</p>
          <small  className={(this.state.passwordRules.length ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_LENGTH}</small>
          <small  className={(this.state.passwordRules.uppercase ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_UPPERCASE}</small>
          <small  className={(this.state.passwordRules.number ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_NUMBER}</small>
          <small  className={(this.state.passwordRules.symbol ? 'pass-true' : 'pass-false') + ' form-text '}>{LANG.AUTH.REGISTER.PASSWORD_RULES_SYMBOL}</small>
        </div>
        <TextFieldDecorate
          type="password"
          name="confirmPassword"
          placeholder={LANG.AUTH.REGISTER.LABEL_CONFIRM_PASSWORD}
          leftDecorate={<Icon name="vpn_key" color="primary" />}
          value={this.state.confirmPassword}
          onChange={this.handlePasswordConfirm}
          errorMessage={this.state.errors && this.state.errors.samePassword ? LANG.ERRORS.NO_CONFIRM_PASSWORD : ''}
        />
        <div className="mt-4 text-right">
          <Button
            color="primary"
            label={LANG.AUTH.RESET_PASSWORD.BTN_SUBMIT}
            icon="save"
            onClick={this.processForm}
          />
        </div>
      </form>
    );
  }

  _renderInvalidRequest() {
    return (
      <div className="mb-4">
        <h3 className="title-success">Solicitud no válida</h3>
        <p className="mb-3">{this.state.error}</p>
        <Button
          color="primary"
          label="Iniciar sesión"
          icon="login"
          onClick={() => this.props.history.push(ROUTES.AUTH.LOGIN)}
        />
      </div>
    );
  }

  _renderBody() {
    if (!this.state.validRequest) {
      return this._renderInvalidRequest();
    } else if (this.state.passwordReset) {
      return this._renderPasswordReset();
    } else {
      return this._renderForm();
    }
  }

  render() {
    return (
      <div className="wrap-auth">
        <div className="card card-auth">
          <div className="card-header">
            <div className="mb-3">
              <Button 
                color="link"
                label={LANG.AUTH.FORGOT_PASSWORD.BTN_CANCEL}
                icon="chevron_left"
                onClick={() => this.props.history.push(ROUTES.AUTH.LOGIN)}
              />
            </div>
            <h2>{LANG.AUTH.RESET_PASSWORD.TITLE_FORM}</h2>
          </div>
          <div className="card-body">
            {this._renderBody()}
            <div className="footer-copy">
              <a href={LANDING_PAGE}>
                <img src={require('../../assets/images/Logo-Positive.svg')} alt="GameIF Academy" />
              </a>
              <div className="mt-3">{LANG.AUTH.COPY.TEXT}</div>
              <div>
                <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.TERMS}</a>
                <span> • </span>
                <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.PRIVACY}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;