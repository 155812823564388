import axios from 'axios';
import { END_POINT } from '../enviroments/enviroment';
import LocalStorageService from './local-storage.service';
import { LANDING_PAGE } from '../enviroments/enviroment'; 
 
const HTTP = axios.create({
    baseURL: END_POINT,
    timeout: 5000
});

HTTP.interceptors.request.use(
    (config) => {
        const currentUser = LocalStorageService.getToken();
        if (currentUser) {
            config.headers['Authorization'] = `${currentUser.token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

HTTP.interceptors.response.use(
    response => response,
    error => {
        const { status } = error.response;
        if (status === 401) {
            LocalStorageService.signOff();
            window.location.replace(LANDING_PAGE);
        }
        return Promise.reject(error);
    }
);

export default HTTP;

