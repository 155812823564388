import React from 'react';
import { TextFieldDecorate, DateTimePickerField, Button } from '../../../components/ui';
import { addDays, subDays } from 'date-fns';
import LANG from '../../../const/language.const';

function FormChallengeSettings(props) {
  const [errors, setErrors] = React.useState({});
  const { 
    teamChallenge,
    onChange,
    onNext,
  } = props;

  const changeNameHandler = (e) => {
    let value = e.target.value;
    teamChallenge.name = value;
    if (value !== '') {
      errors.name = '';
      setErrors(errors);
    } else {
      errors.name = LANG.ERRORS.REQUIRED_FIELD;
      setErrors(errors);
    }
    onChange(teamChallenge);
  }

  const changeStartDateHandler = (date) => {
    let challenge = { ...teamChallenge };
    challenge.startDate = date;
    if (date > challenge.endDate) {
      challenge.endDate = addDays(date, 1);
    }
    
    onChange(challenge);
  }

  const changeEndDateHandler = (date) => {
    let challenge = { ...teamChallenge };
    challenge.endDate = date;
    if (date < challenge.startDate) {
      challenge.startDate = subDays(date, 1);
    }
    
    onChange(challenge);
  }

  let disabledNext = teamChallenge.name && teamChallenge.startDate < teamChallenge.endDate ? false : true;

  return (
    <div className="form-challenge-settings">
      <div className="row">
        <div className="col">
          <TextFieldDecorate 
            type="text"
            name="name"
            errorMessage={errors.name || ''}
            leftDecorate={<i className="material-icons">whatshot</i>}
            placeholder="Nombre del evento"
            value={teamChallenge.name || ''}
            onChange={changeNameHandler}
          />
          <p className="text-primary"><strong>Fecha y hora de inicio</strong></p>
          <DateTimePickerField 
            selected={teamChallenge.startDate}
            onChange={changeStartDateHandler}
          />
          <p className="text-primary pt-3"><strong>Fecha y hora de fin</strong></p>
          <DateTimePickerField 
            selected={teamChallenge.endDate}
            onChange={changeEndDateHandler}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col pt-4 text-right">
          <Button 
            color="outline-primary"
            icon="chevron_right"
            label="Continuar"
            className="ml-2"
            disabled={disabledNext}
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
}

export default FormChallengeSettings;