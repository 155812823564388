import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const teamChallengeService = {
  save,
  update,
  getByGroup,
  getDetail,
  getTeams,
  close,
  downloadReport,
  getChallengeStats,
  getRounds
}

async function save(challenge, teams) {
  return await HTTP.post(ROUTES_API.TEAM_CHALLENGES.SAVE, {challenge, teams});
}

async function update(challenge, teams) {
  return await HTTP.post(ROUTES_API.TEAM_CHALLENGES.UPDATE, {challenge, teams});
}

async function getByGroup(group) {
  return HTTP.get(`${ROUTES_API.TEAM_CHALLENGES.GET_BY_GROUP}${group}`);
}

async function getDetail(challenge) {
  return HTTP.get(`${ROUTES_API.TEAM_CHALLENGES.GET_DETAIL}${challenge}`);
}

async function getTeams(challenge) {
  return HTTP.get(ROUTES_API.TEAM_CHALLENGES.GET_TEAMS.replace(':challenge', challenge));
}

async function close(challenge) {
  return HTTP.post(ROUTES_API.TEAM_CHALLENGES.CLOSE, { challenge });
}

async function getChallengeStats(challengeId) {
  return await HTTP.get(ROUTES_API.TEAM_CHALLENGES.GET_STATS.replace(':challenge', challengeId));
}

async function getRounds(challengeId) {
  return await HTTP.get(ROUTES_API.TEAM_CHALLENGES.GET_SCHEDULE.replace(':challenge', challengeId));
}

async function downloadReport(challengeId) {
  return await HTTP.get(ROUTES_API.TEAM_CHALLENGES.REPORT.replace(':challenge', challengeId), {responseType: 'blob'});
}