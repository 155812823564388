import React, { Component } from 'react'
import { Sidebar } from '../../components/layout/sidebar'
import Notifications from './notifications'
import NotificationsForm from './notifications-form';
import NotificationsDetail from './notifications-detail';
import { notificationsServices } from '../../services/notifications.service';
import LocalStorageService from '../../services/local-storage.service';
import { groupService } from '../../services/group.service';

class SidebarNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      academic: null,
      notification: null,
      notifications: [],
      currentView: 'list',
      groups: []
    }

    this.showDetail = this.showDetail.bind(this);
    this.goToList = this.goToList.bind(this);
    this.goToForm = this.goToForm.bind(this);
  }

  async componentDidMount() {
    let academic = LocalStorageService.session();
    let notifications = await this.getNotifications(academic._id);
    let groups = await this.getGroups(academic._id);
    
    this.setState({ academic, notifications, groups });
  }

  async getGroups(academicId) {
    try {
      const response = await groupService.getAllByAcademic(academicId);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getNotifications(academicId) {
    try {
      const response = await notificationsServices.getByAcademic(academicId);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getNotificationDetail(notificationId) {
    try {
      const response = await notificationsServices.getDetail(notificationId);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async goToList() {
    let listNotifications = await this.getNotifications(this.state.academic._id);
    this.setState({ 
      currentView: 'list', 
      notifications: listNotifications,
      notification: null 
    });
  }

  async showDetail(notificationId) {
    let detail = await this.getNotificationDetail(notificationId);
    this.setState({ 
      currentView: 'detail',
      notification:  detail
    });
  }

  goToForm() {
    this.setState({ currentView: 'form' });
  }

  _renderBody() {
    switch (this.state.currentView) {
      case 'detail':
        return (
          <NotificationsDetail 
            notification={this.state.notification}
            onBack={this.goToList}
            onClose={this.props.toogleSidebar}
          />
        );
      case 'form':
        return (
          <NotificationsForm 
            onBack={this.goToList}
            onClose={this.props.toogleSidebar}
            groups={this.state.groups}
            academic={this.state.academic._id}
          />
        );
      default:
        return (
          <Notifications 
            onClose={this.props.toogleSidebar}
            onClickAdd={this.goToForm}
            goToDetail={this.showDetail}
            notifications={this.state.notifications}
          />
        );
    }
  }

  render() {
    return (
      <div className="sidebar-notification">
        <Sidebar height={"100vh"} side="right" active={this.props.activeSidebar}>
          { this._renderBody() }
        </Sidebar>
      </div>
    )
  }
}

export default  SidebarNotification;