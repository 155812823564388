import React from 'react';

function List(props) {
  const { children, className, ...restProps } = props;
  let listClasses = className ? `list-group ${className}` : 'list-group';

  return (
    <ul className={listClasses} {...restProps}>
      { props.children }
    </ul>
  );
}

export default List;