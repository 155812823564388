import React, { Component } from 'react';
import TextField from '../../components/ui/text-field';
import { groupService } from '../../services';
import LANG from '../../const/language.const';
import { Button, IconButton } from '../../components/ui';
import Select from 'react-select';

class CardGroupSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editable: false,
      settings: {
        rounds: 1,
        questionsNumber: 3,
        quizzes: []
      },
      snapSettings: {
        rounds: 1,
        questionsNumber: 3,
        quizzes: []
      },
      optionsQuizzes: [],
      selectedQuizzes: [],
      errors: {}
    };

    this.toogleEdition = this.toogleEdition.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.changeQuizzesHandler = this.changeQuizzesHandler.bind(this);
    this.saveSettings = this.saveSettings.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.getSettingsGroup();
  }

  async getSettingsGroup() {
    try {
      let groupId = this.props.group;
      let options = [];
      let selected = [];

      if (groupId) {
        const response = await groupService.getSettings(groupId);
        for (let i = 0; i < response.data.availableQuizzes.length; i++) {
          const element = response.data.availableQuizzes[i];
          options.push({
            value: element._id,
            label: element.name
          });
          
          if (response.data.quizzes.length && response.data.quizzes.includes(element._id)) {
            selected.push({
              value: element._id,
              label: element.name
            });
          }
        }



        this.setState({
          settings: response.data,
          snapSettings: response.data,
          optionsQuizzes: options,
          selectedQuizzes: selected
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  submitForm() {
    let isValid = true;
    let errors = {};

    if (!this.state.settings.rounds || isNaN(this.state.settings.rounds)) {
      isValid = false;
      errors.rounds = LANG.ERRORS.REQUIRED_FIELD;
    }

    if (!this.state.settings.questionsNumber || isNaN(this.state.settings.questionsNumber)) {
      isValid = false;
      errors.questionsNumber = LANG.ERRORS.REQUIRED_FIELD;
    }

    if (this.state.settings.quizzes < 1) {
      isValid = false;
      errors.quizzes = LANG.ERRORS.REQUIRED_FIELD;
    }

    if (isValid) {
      this.setState({ loading: true });
      this.saveSettings();
    } else {
      this.setState({ 
        errors
      });
    }
  }

  toogleEdition() {
    let selected = this.state.optionsQuizzes.filter(item => this.state.snapSettings.quizzes.includes(item.value))

    this.setState({
      editable: !this.state.editable,
      settings: { ...this.state.snapSettings },
      selectedQuizzes: selected
    });
  }

  changeHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    let settings = this.state.settings;
    settings[name] = value;
    
    this.setState({ 
      settings
    })
  }

  changeQuizzesHandler(newValue, meta) {
    let settings = this.state.settings;
    let selectedQuizzes = newValue;
    settings.quizzes = selectedQuizzes.map(item => { return item.value })

    this.setState({ settings, selectedQuizzes });
  }

  async saveSettings() {
    try {
      let savedSettings = {...this.state.settings};
      delete savedSettings.availableQuizzes;
      await groupService.saveSettings(savedSettings);
      this.setState({
        loading: false,
        editable: false,
        snapSettings: this.state.settings,
      });
    } catch (error) {
      console.log(error);
    }
  }

  _renderActions() {
    if (this.state.loading) {
      return (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    } else if (this.state.editable) {
      return (
        <>
          <IconButton 
            name="clear"
            className="text-accent"
            onClick={this.toogleEdition}
          />
        </>
      );
    } else {
      return (
        <IconButton 
          name="edit"
          className="text-accent"
          onClick={this.toogleEdition}
        />
      );
    }
  }

  _renderInfo() {
    return (
      <div className="info-settings">
        <p className="text-primary">Rondas por reto individual</p>
        <p className="border-primary">{this.state.settings.rounds || ''}</p>
        <p className="text-primary">Preguntas por ronda</p>
        <p className="border-primary">{this.state.settings.questionsNumber || ''}</p>
        <p className="text-primary">Cuestionarios de juego</p>
        <div>
        {
          this.state.selectedQuizzes.map((item, index) => {
            return (
              <div key={index} className="badge badge-secondary">{item.label}</div>
            )
          })
        }
        </div>
      </div>
    );
  }

  _renderForm() {
    return (
      <div className="form-settings">
        <form>
          <TextField
            label="Rondas por reto individual"
            type="number"
            name="rounds"
            value={this.state.settings.rounds}
            onChange={this.changeHandler}
            errorMessage={this.state.errors.rounds}
          />
          <TextField
            label="Preguntas por ronda"
            type="number"
            name="questionsNumber"
            value={this.state.settings.questionsNumber}
            onChange={this.changeHandler}
            errorMessage={this.state.errors.questionsNumber}
          />
          <Select 
            label="Cuestionarios a usar"
            name="quizzes"
            options={this.state.optionsQuizzes}
            value={this.state.selectedQuizzes}
            onChange={this.changeQuizzesHandler}
            isSearchable
            isMulti
          />
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className="card card-group-settings">
        <div className="card-header">
          <div className="actions" style={{float: 'right'}}>
            { this._renderActions() }
          </div>
          <h4>Configuración de retos amistosos</h4>
        </div>
        <div className="card-body">
          { this.state.editable ? this._renderForm() : this._renderInfo() }
          <div className="mt-4 text-right">
            { this.state.editable && !this.state.loading &&
              <Button
                name="save"
                icon="save"
                label="Guardar"
                onClick={this.submitForm}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default CardGroupSettings;