import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';
import LOGO from '../../assets/images/logo_market.svg';
import { Icon, IconButton } from '../ui';

function HeaderMarket() {
  return (
    <header className="header">
      <div className="topbar topbar-mk">
        <div className="container">
          <div className="back-nav">
            <Link to={ROUTES.QUIZZES.ROOT}>
              <Icon name="keyboard_backspace" />
            </Link>
          </div>
          <div className="logo">
            <Link to={ROUTES.MARKETPLACE.ROOT}>
              <img src={LOGO} alt="GameIF Academy" />
            </Link>
          </div>
          <ul className="list-inline float-right">
            <li className="list-inline-item">
              <button className="nav-user">
                <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=100" alt="Mi Perfil" className="rounded-circle" />
              </button>
            </li>
            <li className="list-inline-item">
              <Link className="btn-icon" to={ROUTES.MARKETPLACE.CART}>
                <Icon name="shopping_cart" />
              </Link>
            </li>
            <li className="list-inline-item">
              <IconButton name="markunread_mailbox" />
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
    </header>
  );
}

export default HeaderMarket;