import React from 'react';
import HeaderMarket from './header-market';

function LayoutMarket(props) {
  return (
    <div id="wrapper">
      <HeaderMarket />
      <div className="page-content">
        <div className="container">
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default LayoutMarket;