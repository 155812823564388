import React from 'react';
import EventStatusProgress from '../../components/event-status-progress/event-status-progress';

function TeamChallengeCard(props) {
  const { challenge } = props;
  let startDate = challenge.startDate ? new Date(challenge.startDate) : new Date();
  let endDate = challenge.endDate ? new Date(challenge.endDate) : new Date();
  let createdAt = challenge.createdAt ? new Date(challenge.createdAt) : startDate;
  let completionDate = challenge.completionDate ? new Date(challenge.completionDate) : endDate;

  return (
    <div className="card team-challenge-card" onClick={props.onClick}>
      <div className="card-body text-center px-0 pb-0 pt-3">
        <h4>{challenge.name}</h4>
        <h5>
          <i className="material-icons md-18">people</i>
          <span>Competencia en equipo</span>
        </h5>
        <div className="row block-stats">
          <div className="col-4">
            <h4>{challenge.rounds.length}</h4>
            <span>Rondas por día</span>
          </div>
          <div className="col-4">
            <h4>{challenge.numQuestions}</h4>
            <span>Preguntas por ronda</span>
          </div>
          <div className="col-4">
            <h4>{challenge.quizzes.length}</h4>
            <span>Cuestionarios</span>
          </div>
        </div>
        <EventStatusProgress 
          eventStatus={challenge.status || ''}
          eventStartDate={startDate}
          eventEndDate={endDate}
          eventCreation={createdAt}
          eventCompletionDate={completionDate}
        />
      </div>
    </div>
  );
}

export default TeamChallengeCard;