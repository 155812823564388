import React, { Component } from 'react';
import swal from '@sweetalert/with-react';
import ModalForm from '../../components/ui/modal-form';
import TextField from '../../components/ui/text-field';
import LANG from '../../const/language.const';
import LevelService from '../../services/level.service';
import SelectField from '../../components/ui/select-field';


class ModalFormGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {
        name: '',
        institute: '',
        level: '',
        degree: '',
        shift: '',
        academic: '',
      },
      errors: {},
      levels: [],
      shifts: [],
      selectedLevel: null,
      selectedDegree: null,
      selectedShift: null,
    }

    this.changeFieldHandler = this.changeFieldHandler.bind(this);
    this.getLevels = this.getLevels.bind(this);
    this.getLevelsOptions = this.getLevelsOptions.bind(this);
    this.getDegreesOptions = this.getDegreesOptions.bind(this);
    this.changeLevelHandler = this.changeLevelHandler.bind(this);
    this.changeDegreeHandler = this.changeDegreeHandler.bind(this);
    this.changeShiftHandler = this.changeShiftHandler.bind(this);
    this.changeFieldHandler = this.changeFieldHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  async componentDidMount() {
    let group = this.state.group;
    let levels = await this.getLevels();
    let shifts = [
      { label: 'Matutino', value: 'matutino' },
      { label: 'Vespertino', value: 'vespertino' }
    ]
    let selectedLevel = null;
    let selectedDegree = null;
    let selectedShift = null;
    if (this.props.dataGroup) {
      group = this.props.dataGroup;
      selectedLevel = this.filterDefaultLevel(levels, group.level);
      selectedDegree = this.filterDefaultDegree(levels, group.level, group.degree);
      selectedShift = this.filterDefaultShift(shifts, group.shift);
    }

    this.setState({
      group,
      levels,
      shifts,
      selectedLevel,
      selectedDegree,
      selectedShift,
    });
  }

  filterDefaultLevel(levels, levelID) {
    let level = levels.find(item => item._id === levelID);
    let defaultObj = level ? { label: level.name, value: level._id } : null;
    return defaultObj;
  }

  filterDefaultDegree(levels, levelID, degreeID) {
    let level = levels.find(item => item._id === levelID);
    let defaultObj = null;
    if (level) {
      let degree = level.degrees.find(item => item._id === degreeID);
      defaultObj = degree ? { label: degree.name, value: degree._id } : null;
    }
    
    return defaultObj;
  }

  filterDefaultShift(shifts, shiftID) {
    let shift = shifts.find(item => item.value === shiftID);
    return shift;
  }
  
  async getLevels() {
    try {
      const response = await LevelService.getAll();
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        this.onError(error.response.data.message);
      } else {
        this.onError('Error al cargar los datos');
      }
    }
  }

  getLevelsOptions() {
    return this.state.levels.map(item => {
      return {
        value: item._id,
        label: item.name
      }
    });
  }

  getDegreesOptions() {
    const { group, levels } = this.state;
    if (group.level) { 
      let currentLevel = levels.find(item => item._id === group.level);
      if (currentLevel) {
        let degrees = currentLevel.degrees.sort((a,b) => {
          return a.order - b.order;
        });
        return degrees.map(item => {
          return {
            value: item._id,
            label: item.name
          }
        });
      }
    }

    return [];
  }

  changeFieldHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    let group = this.state.group;
    let errors = this.state.errors;
    group[name] = value;
    errors[name] = '';
    
    this.setState({ group, errors });
  }

  changeLevelHandler(selectedOption) {
    let group = this.state.group;
    let errors = this.state.errors;
    group.level = selectedOption.value;
    group.degree = '';
    errors.level = '';

    this.setState({ 
      selectedLevel: selectedOption,
      group, 
      errors 
    });
  }

  changeDegreeHandler(selectedOption) {
    let group = this.state.group;
    let errors = this.state.errors;
    group.degree = selectedOption.value;
    errors.degree = '';

    this.setState({ 
      selectedDegree: selectedOption,
      group,
      errors 
    });
  }

  changeShiftHandler(selectedOption) {
    let group = this.state.group;
    let errors = this.state.errors;
    group.shift = selectedOption.value;
    errors.shift = '';

    this.setState({ 
      selectedShift: selectedOption,
      group,
      errors 
    });
  }

  submitHandler() {
    let errors = this.validateForm();
    if (errors) {
      this.setState({ errors });
      return;
    }

    this.props.onSave(this.state.group);
  }

  validateForm() {
    const { group } = this.state;
    let validForm = true;
    let errors = {};

    if (!group.name) {
      validForm = false;
      errors.name = 'Campo obligatorio';
    }

    if (!group.level) {
      validForm = false;
      errors.level = 'Campo obligatorio';
    }

    if (!group.degree) {
      validForm = false;
      errors.degree = 'Campo obligatorio';
    }

    if (!group.shift) {
      validForm = false;
      errors.shift = 'Campo obligatorio';
    }

    return validForm ? null : errors;
  }

  onError(errorMessage) {
    swal({
      text: errorMessage,
      icon: 'error',
      button: 'ok',
    });
  }

  render() {
    const { group } = this.state;
    const modalTitle = group._id 
      ? LANG.GROUPS.FORM.TITLE_EDIT 
      : LANG.GROUPS.FORM.TITLE_ADD;

    return (
      <ModalForm 
        title={modalTitle}
        isOpen={this.props.isOpen}
        onHide={this.props.onClose}
      >
        <div className="alert alert-info mb-3" style={{fontSize: '12px'}}>
          Llena este formulario Asignando el nombre o denominación que te permita identificar correctamente al grupo escolar que corresponda, así como el nivel de estudios, grado y turno que correspondan.
        </div>
        <form className="form form-group px-5">
          <TextField 
            name="name"
            placeholder={LANG.GROUPS.FORM.NAME}
            value={group.name}
            errorMessage={this.state.errors.name}
            onChange={this.changeFieldHandler}
          />
          <SelectField 
            name="level"
            value={this.state.selectedLevel}
            options={this.getLevelsOptions()}
            placeholder={LANG.GROUPS.FORM.LEVEL}
            onChange={this.changeLevelHandler}
            errorMessage={this.state.errors.level}
          />
          <SelectField 
            name="degree"
            value={this.state.selectedDegree}
            options={this.getDegreesOptions()}
            placeholder={LANG.GROUPS.FORM.DEGREE}
            onChange={this.changeDegreeHandler}
            errorMessage={this.state.errors.degree}
          />
          <SelectField 
            name="shift"
            value={this.state.selectedShift}
            options={this.state.shifts}
            placeholder={LANG.GROUPS.FORM.SHIFT}
            onChange={this.changeShiftHandler}
            errorMessage={this.state.errors.shift}
          />
          <div className="form-actions mt-5">
            <button 
              type="button"
              className="btn btn-outline-primary btn-rounded btn-block"
              onClick={this.submitHandler}
            >
              {LANG.GROUPS.FORM.BTN_SAVE}
            </button>
          </div>
        </form>
      </ModalForm>
    );
  }
}

export default ModalFormGroup;