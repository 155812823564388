import React from 'react';
import { List, ListItem, Checkbox, IconButton, ListItemIcon, ListItemText, ListItemSecondaryAction } from '../ui/';
import LANG from '../../const/language.const';

function ListQuizzes(props) {
  const [expandedIndex, setExpandedIndex] = React.useState("");
  const handleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex("")
    } else {
      setExpandedIndex(index)
    }
  };

  const handleChangeSelected = (event) => {
    let quiz = event.target.value;
    let selected = props.selectedQuizzes;
    let indexOfQuiz = selected.findIndex(item => item === quiz);
    if (indexOfQuiz < 0) {
      selected.push(quiz);
    } else {
      selected.splice(indexOfQuiz, 1);
    }

    props.changeSelected(selected);
  };

  return (
    <List>
      {props.quizzes.map((quiz, index) => {
        let stylesCollapse = {padding: '0.75rem 1.25rem', display: 'none'};
        let iconItem = index === expandedIndex ? 'expand_less' : 'expand_more';

        if (index === expandedIndex) {
          stylesCollapse.display = 'block';
        }

        return (
          <div key={quiz._id}>
            <ListItem>
              <ListItemIcon>
                <Checkbox 
                  name="campaignQuiz[]"
                  id={`campaignQuiz_${quiz._id}`}
                  value={quiz._id}
                  checked={props.selectedQuizzes.includes(quiz._id)}
                  onChange={handleChangeSelected}
                />
              </ListItemIcon>
              <ListItemText 
                primary={quiz.name} 
                secondary={quiz.topic}
              />
              <ListItemSecondaryAction>
                <IconButton name={iconItem} onClick={() => handleExpand(index)} />
              </ListItemSecondaryAction>
            </ListItem>
            <div style={stylesCollapse}>
              <div className="mb-2">
                <span className="text-primary">{LANG.QUIZZES.FORM.LEVEL}</span><br />
                <span>{quiz.level.name}</span>
              </div>
              <div className="mb-2">
                <span className="text-primary">{LANG.QUIZZES.FORM.DEGREE}</span><br />
                <span className="text-capitalize">{quiz.degree.name}</span>
              </div>
              <div className="mb-2">
                <span className="text-primary">{LANG.QUIZZES.QUESTIONS}</span><br />
                <span>{quiz.questions.length}</span>
              </div>
            </div>
          </div>
        )
      })}
    </List>
  );
}

export default ListQuizzes;