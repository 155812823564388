import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import ROUTES from '../../const/routes.const';
import TextField from '../../components/ui/text-field';

class CampaignWizard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      group: {
        _id: this.props.match.params.group || '',
        pin: '',
        institute: {
          _id: ''
        },
      },
      currentStep: 1,
      campaign: {
        name: '',
        rounds: 3,
        questionsNumber: 5,
        startDate: new Date(),
        endDate: new Date(),
        quizzes: [],
        group: this.props.match.params.group || '',
        status: 'draft'
      }
    }

    this.changeHandler = this.changeHandler.bind(this);
  }

  componentDidMount() {
    //TODO
  }

  changeHandler(e) {
    let name = e.target.name;
    let value = e.target.value;
    let campaign = this.state.campaign;

    campaign[name] = value;

    this.setState({ campaign });
  }

  _renderNavbar() {
    return (
      <div className="navbar navbar-groups">
        <div className="nav-back">
          <Link to={ROUTES.GROUPS.CAMPAIGNS.replace(':group', this.state.group._id)}>
            <i className="material-icons arrow-back">arrow_back</i>
          </Link>
        </div>
        <div className="navbar-title">
          <h4>Nuevo evento individual</h4>
        </div>
        <div className="wizard-steps">
          <ul className="nav">
            <li className={`nav-link ${this.state.currentStep === 1 ? 'active' : ''}`}>Paso 1</li>
            <li className={`nav-link ${this.state.currentStep === 2 ? 'active' : ''}`}>Paso 2</li>
            <li className={`nav-link ${this.state.currentStep === 3 ? 'active' : ''}`}>Paso 3</li>
          </ul>
        </div>
      </div>
    );
  }

  _renderStepOne() {
    return (
      <div className="card card-wizard-step1">
        <div className="card-body">
          <p>¿Cómo se llamará el evento?</p>
          <TextField 
            type="text"
            name="name"
            value={this.state.campaign.name}
            onChange={this.changeHandler}
          />
          <p>¿Cuántos rounds tendrá cada enfrentamiento?</p>
          <div className="select-btns-rounds">
          <div className="btn-group" role="group" aria-label="Rounds select">
            <button type="button" className="btn btn-default">1</button>
            <button type="button" className="btn btn-default">2</button>
            <button type="button" className="btn btn-default">3</button>
            <button type="button" className="btn btn-default">4</button>
            <button type="button" className="btn btn-default">5</button>
          </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Layout
        navBar={this._renderNavbar()}
      >
        <div className="row justify-content-center">
          <div className="col-md-8">
            { this.state.currentStep === 1 && this._renderStepOne()}
          </div>
        </div>
      </Layout>
    );
  }
}

export default CampaignWizard;