import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalForm = (props) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.isOpen}
      onHide={props.onHide}
      className="modal-forms"
    >
      <div className="modal-container">
        <Modal.Header closeButton>
          {props.title && <Modal.Title>{props.title}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {props.children}
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default ModalForm;
