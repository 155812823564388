export const avatar = {
  _1: require('../assets/images/avatars/avatar-1.png'),
  _2: require('../assets/images/avatars/avatar-2.png'),
  _3: require('../assets/images/avatars/avatar-3.png'),
  _4: require('../assets/images/avatars/avatar-4.png'),
  _5: require('../assets/images/avatars/avatar-5.png'),
  _6: require('../assets/images/avatars/avatar-6.png'),
  _7: require('../assets/images/avatars/avatar-7.png'),
  _8: require('../assets/images/avatars/avatar-8.png'),
  _9: require('../assets/images/avatars/avatar-9.png'),
  _10: require('../assets/images/avatars/avatar-10.png'),
};