import React, { Component } from 'react'
import { Icon, IconButton, TextField, ListItem, ListItemAvatar, Checkbox, ListItemText, List } from '../../components/ui'
import LANG from '../../const/language.const';
import { notificationsServices } from '../../services/notifications.service';
import { store } from 'react-notifications-component';

class NotificationsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroups: [], 
      title: '',
      message: '',
      errors: {},
    }

    this.handleChangeSelected = this.handleChangeSelected.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.saveNotification = this.saveNotification.bind(this);
  }

  submitForm() {
    let errors = {};
    let validForm = true;

    if (!this.state.title) {
      errors.title = LANG.ERRORS.REQUIRED_FIELD;
      validForm = false;
    }

    if (!this.state.message) {
      errors.message = LANG.ERRORS.REQUIRED_FIELD;
      validForm = false;
    }

    if (this.state.selectedGroups.length < 1) {
      errors.groups = 'Debe seleccionar al menos un grupo';
      validForm = false;
    }

    if (validForm) {
      this.saveNotification();
    } else {
      this.setState({ errors });
    }

  }

  async saveNotification() {
    let params = {
      title: this.state.title,
      message: this.state.message,
      groups: this.state.selectedGroups,
      academic: this.props.academic,
      date: this.dateToFormat(new Date())
    };

    try {
      const response = await notificationsServices.save(params);
      if (response.data.status) {
        store.addNotification({
          message: "Se ha enviado la notificación",
          type: "success",
          insert: "top",
          container: "bottom-center",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      }

      this.props.onBack();
    } catch (error) {
      console.log(error);
    }
  }

  handleChangeInput(event) {
    const name = event.target.name;
    const value = event.target.value;
    let errors = this.state.errors;

    errors[name] = '';

    this.setState({ 
      [name]: value,
      errors
    });
  }

  handleChangeSelected(event) {
    let errors = this.state.errors;
    let group = event.target.value;
    let selected = this.state.selectedGroups;
    let indexOfGroup = this.state.selectedGroups.findIndex(item => item === group);
    if (indexOfGroup < 0) {
      selected.push(group);
    } else {
      selected.splice(indexOfGroup, 1);
    }

    if (selected.length) {
      errors.groups = '';
    }

    this.setState({
      selectedGroups: selected,
      errors
    });
  }

  dateToFormat(date) {
    let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    let hours = date.getHours();
    let min = date.getMinutes();

    return '' + y + '/' + (m<=9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + ' ' + (hours <= 9 ? '0' + hours : hours) + ':' + (min <= 9 ? '0' + min : min);
  }

  _renderGroups() {
    return this.props.groups.map(item => {
      return (
        <div key={`group-${item._id}`}>
          <ListItem>
            <ListItemAvatar>
              <Checkbox 
                name="group[]"
                id={`group-${item._id}`}
                value={item._id}
                checked={this.state.selectedGroups.includes(item._id)}
                onChange={this.handleChangeSelected}
              />
            </ListItemAvatar>
            <ListItemText primary={item.name} />
          </ListItem>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="notifications">
        <div className="notifications-header">
          <IconButton 
            name="close"
            onClick={this.props.onClose}
          />
          <IconButton 
            name="arrow_back" 
            color="primary"
            onClick={this.props.onBack}
          />
          <h3>Nueva notificación</h3>
        </div>
        <div className="notifications-form">
          <TextField 
            name="title"
            placeholder="Título"
            errorMessage={this.state.errors.title}
            value={this.state.title}
            onChange={this.handleChangeInput}
          />
          <TextField 
            name="message"
            placeholder="Mensaje"
            errorMessage={this.state.errors.message}
            value={this.state.message}
            onChange={this.handleChangeInput}
          />
          <p>
            { this.state.errors.groups && 
              <span className="text-danger">{this.state.errors.groups}</span> 
            }
          </p>
          <List>
            { this._renderGroups() }
          </List>
        </div>
        <div className="notifications-footer">
          <button 
            type="button" 
            className="btn btn-primary btn-block"
            onClick={this.submitForm}>
            Enviar
            <Icon name="save" />
          </button>
        </div>
      </div>
    )
  }
}

export default NotificationsForm;