import React, { Component } from 'react'
import { TextFieldDecorate, Icon, Button } from '../../components/ui'
import LANG from '../../const/language.const';
import AuthService from '../../services/auth.service';
import LocalStorageService from '../../services/local-storage.service';
import ROUTES from '../../const/routes.const';
import { LANDING_PAGE, PRIVACY_URL, TERMS_URL } from '../../enviroments/enviroment';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: '',
      invalidEmail: false,
      recovery: false,
    }

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  componentDidMount() {
    const session = LocalStorageService.session();
    if (session) {
      this.props.history.push(ROUTES.HOME);
    }
  }

  onChangeHandler(event) {
    const value = event.target.value;
    let invalidEmail = !this._isValidEmail(value);

    this.setState({
      email: value,
      invalidEmail
    });
  }

  async submitForm() {
    try {
      const { email } = this.state;
      const response = await AuthService.forgotPassword(email);
      let data = response.data;
      if (data.sendMail) {
        this.setState({ recovery: true, error: '' });
      }
    } catch (e) {
      if (e.response && e.response.data) {
        this.setState({error: e.response.data.message});
      }
    }
  }

  backToLogin() {
    this.props.history.push(ROUTES.AUTH.LOGIN);
  }

  _isValidEmail(email) {
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    return emailRegex.test(email);
  }

  _renderBody() {
    if (this.state.recovery) {
      return (
        <div>
          <h3 className="title-success">{LANG.AUTH.FORGOT_PASSWORD.SUCCESS_TITLE}</h3>
          <p>{LANG.AUTH.FORGOT_PASSWORD.SUCCESS_MESSAGE}</p>
          <Button 
            color="primary"
            label={LANG.AUTH.FORGOT_PASSWORD.SUCCESS_BTN}
            icon='mood'
            onClick={this.backToLogin}
          />
        </div>
      );
    } else {
      return (
        <form>
          <TextFieldDecorate
            type="email" 
            name="email"
            placeholder={LANG.AUTH.FORGOT_PASSWORD.LABEL_EMAIL}
            leftDecorate={<Icon name="person" color="primary" />}
            value={this.state.email}
            errorMessage={this.state.invalidEmail ? LANG.ERRORS.INVALID_EMAIL : ''}
            onChange={this.onChangeHandler}
          />
          <div className="mt-4">
            <Button 
              color="primary" 
              label={LANG.AUTH.FORGOT_PASSWORD.BTN_SUBMIT}
              icon="login"
              disabled={this.state.invalidEmail}
              onClick={this.submitForm}
            />
          </div>
        </form>
      );
    }
  }

  render() {
    return (
      <div className="wrap-auth">
        <div className="card card-auth">
          <div className="card-header">
            <div className="mb-3">
              <Button 
                color="link"
                label={LANG.AUTH.FORGOT_PASSWORD.BTN_CANCEL}
                icon="chevron_left"
                onClick={this.backToLogin}
              />
            </div>
            <h2 className="mb-1">{LANG.AUTH.FORGOT_PASSWORD.TITLE_FORM}</h2>
            { !this.state.recovery &&
              <small>{LANG.AUTH.FORGOT_PASSWORD.SUBTITLE}</small>}
          </div>
          <div className="card-body">
            <div className="alert-messages">
              { this.state.error && 
                <div className="alert alert-danger">{ this.state.error }</div>
              }
            </div>
            { this._renderBody() }
            <div className="footer-copy">
              <a href={LANDING_PAGE}>
                <img src={require('../../assets/images/Logo-Positive.svg')} alt="GameIF Academy" />
              </a>
              <div className="mt-3">{LANG.AUTH.COPY.TEXT}</div>
              <div>
                <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.TERMS}</a>
                <span> • </span>
                <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer">{LANG.AUTH.COPY.PRIVACY}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPassword;