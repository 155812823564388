import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, ListItemSecondaryAction, Avatar, IconButton } from '../../components/ui';
import DEFAULT_AVATAR from  '../../assets/images/default-avatar.png';
import { avatar } from '../../const/avatars.conts';

function ListTeamsRanking({ teams }) {
  const [expandedIndex, setExpandedIndex] = React.useState("");
  
  const handleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex("")
    } else {
      setExpandedIndex(index)
    }
  };

  return (
    <List>
      { teams && teams.map((team, index) => {
        let iconItem = index === expandedIndex ? 'expand_less' : 'expand_more';
        let collapseShow = index === expandedIndex ? 'show' : '';
        let position = index + 1;
        return (
          <div key={`team_${index}`}>
            <ListItem className={index < 3 ? 'ranking-top' : ''}>
              <ListItemAvatar>
                <Avatar title={position} className="text-primary font-weight-bold" />
              </ListItemAvatar>
              <ListItemText>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="list-group-item-text__primary">{team.name}</div>
                  <div>
                    <span className="ranking-score h4 text-primary">
                      {`${team.points} pts`}
                    </span>
                  </div>
                </div>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton
                  name={iconItem}
                  onClick={() => handleExpand(index)}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <div className={`collapse ${collapseShow}`}>
              <List className="pl-3">
                { team.ranking && team.ranking.map(member => {
                  return (
                    <ListItem key={`member_${member._id}`}>
                      <ListItemAvatar>
                        <Avatar 
                          src={avatar[member.avatar]}
                        />
                      </ListItemAvatar>
                      <ListItemText 
                        primary={ member.nickName  }
                      />
                      <ListItemSecondaryAction>
                        <span className="ranking-score h4 text-primary">
                          {`${member.points} pts`}
                        </span>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                }) 
                }
              </List>
            </div>
          </div>
        );
      }) 
      }
    </List>
  );
}

export default ListTeamsRanking;