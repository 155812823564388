import React, { Component } from 'react';
import LayoutMarket from '../../components/layout-market/layout-market';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';
import { Icon } from '../../components/ui';
import CheckoutSummary from './checkout/chekout-summary';

class MarketplaceCheckout extends Component {
  render() {
    return (
      <LayoutMarket>
        <div className="mk-page-title">
          <Link to={ROUTES.MARKETPLACE.ROOT}>
            <Icon name="arrow_back_ios" />
          </Link>
          <h2>Mi Carrito</h2>
        </div>
        <div className="row">
          <div className="col-md-4">
            <CheckoutSummary />
          </div>
          <div className="col-md-8">
            <div className="mk-checkout-payment">
              <h3>
                <Icon name="payment" />
                Forma de pago
              </h3>
              <div className="card card-type-payment mb-3">
                <div className="card-body">
                  <div className="payment-desc">
                    <Icon name="radio_button_checked" />
                    <span>Pagar con tarjeta</span>
                    <div className="float-right">
                      <Icon name="credit_card" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-type-payment">
                <div className="card-body">
                  <div className="payment-desc">
                    <Icon name="radio_button_unchecked" />
                    <span>Pagar con Paypal</span>
                    <div className="float-right">
                      <Icon name="credit_card" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutMarket>
    );
  }
}

export default MarketplaceCheckout;