import React from 'react';

function ListItemText(props) {
  const { 
    children, 
    className, 
    primary: primaryProp,
    secondary: secondaryProp,
    ...other 
  } = props;

  let primary = primaryProp != null 
    ? (<span className="list-group-item-text__primary">{primaryProp}</span>) 
    : children;

  let secondary = secondaryProp != null 
    ? (<span className="list-group-item-text__secondary">{secondaryProp}</span>) 
    : null;

  return (
    <div 
      className={`list-group-item-text ${className ? className : ''}`}
      {...other}
    >
      { primary }
      { secondary }
    </div>
  );
}

export default ListItemText;