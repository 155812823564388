import React from 'react';
import { Icon } from '../../../components/ui';

function ChekoutSummary(props) {
  return (
    <div className="mk-cart--summary">
      <div className="mk-cart--summary-title">
        <Icon name="receipt" size={20} />
        <span>Pagar 2 productos</span>
      </div>
      <div className="card mb-4">
        <div className="card-body">
          <h5>Subtotal</h5>
          <div className="row">
            <div className="col-3">1</div>
            <div className="col-9 text-right">$ 100.00 mxn</div>
          </div>
          <div className="row">
            <div className="col-3">1</div>
            <div className="col-9 text-right">$ 0.00 mxn</div>
          </div>
          <h5 className="mt-3">Total</h5>
          <div className="text-right">
            $ 100.00 mxn
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChekoutSummary;