import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LocalStorageService from '../services/local-storage.service';
import ROUTES from '../const/routes.const';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        LocalStorageService.getToken()
            ? <Component {...props} />
            : <> {exit()} </>
    )} />
)

const exit = () => { return (<Redirect to={ROUTES.AUTH.LOGIN} />) }

export default PrivateRoute;