import React, { useState, useEffect } from 'react';
import Onboarding from './onboarding';
import OnboardingReview from './onboardingReview';
import LocalStorageService from '../../services/local-storage.service';
import { academicServices } from '../../services';
import ROUTES from '../../const/routes.const';

function OnboardingScreen({ history, match }) {
    const [currentStep, setCurrentStep] = useState(1);
    const review = match.params.review;
    const currentAcademic = LocalStorageService.session();

    useEffect(() => {
        if (!review && currentAcademic.onboardingVerified) {
            history.push(ROUTES.ONBOARDING.REVIEW);
        }
    }, [review, currentAcademic.onboardingVerified, history])

    const onboardingSteps = [
        {
            eventKey: 1,
            title: "Crear institución",
            description: "Aprende a crear una instituciones, las cuales te ayudarán a mantener organizados tus grupos.",
            embedId: "T3l5zwJVLqI"
        },
        {
            eventKey: 2,
            title: "Crear grupo",
            description: "Aprende a crear grupos, donde mantendrás organizados a tus alumnos.",
            embedId: "Y1XgGHQUWyI"
        },
        {
            eventKey: 3,
            title: "Crear cuestionario",
            description: "Aprende a crear cuestionarios, los cuales serán la base de las preguntas del juego.",
            embedId: "XQbz2tUpO9Q"
        },
        {
            eventKey: 4,
            title: "Compartir cuestionarios",
            description: "Aprende a añadir los cuestionarios con los que jugaran los grupos.",
            embedId: "pTLgtUkUZ94"
        },
    ]

    function nextStep() {
        let step = currentStep;
        step++;
        step <= 5
            ? setCurrentStep(step)
            : finishOnboarding();
    }

    function goToStep(step) {
        setCurrentStep(step);
    }

    async function finishOnboarding() {
        let { finishedTutorials } = await setFinishedTutorials();
        if (finishedTutorials) {
            let session = LocalStorageService.getInfoSession();
            updateSession(session);
            history.push('/');
        };
    }

    async function setFinishedTutorials() {
        try {
            const res = await academicServices.setFinishedTutorials(currentAcademic._id);
            return res.data;
        } catch (error) {
            console.log(error.response)
        }
    }

    function updateSession(session) {
        let currentSession = session;
        if (!currentSession.academic.onboardingVerified) {
            currentSession.academic.onboardingVerified = true;
            LocalStorageService.loginSession(currentSession);
        }
    }

    return (
        <>
            {
                review
                    ? <OnboardingReview {...{ currentStep, goToStep, onboardingSteps, finishOnboarding }} />
                    : <Onboarding {...{ currentStep, nextStep, goToStep, onboardingSteps, currentAcademic }} />
            }
        </>
    )
}

export default OnboardingScreen;