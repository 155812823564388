const LANG = {
  NAVBAR: {
    INSTITUTES: 'Instituciones',
    QUIZZES: 'Cuestionarios',
    GROUPS: 'Grupos',
    DASHBOARD: 'Dashboard',
    ALUMNS: 'Alumnos',
    BATTLES: 'Batallas',
    CHALLENGES: 'Competencias en equipo ',
  },
  SEARCH: {
    PLACEHOLDER: 'Buscar',
  },
  AUTH: {
    LOGIN: {
      TITLE_FORM: 'Iniciar sesión',
      LABEL_EMAIL: 'Escribe tu correo',
      LABEL_PASSWORD: 'Escribe tu contraseña',
      BTN_LOGIN: 'Iniciar sesión',
      BTN_REGISTER: 'No tengo cuenta',
      BTN_FORGOT_PASSWORD: 'No recuerdo mi contraseña'
    },
    REGISTER: {
      TITLE_FORM: 'Crear cuenta como profesor',
      LABEL_NAME: 'Nombre',
      LABEL_LASTNAME: 'Apellido Paterno',
      LABEL_SURNAME: 'Apellino Materno',
      LABEL_EMAIL: 'Correo',
      LABEL_PASSWORD: 'Contraseña',
      LABEL_PASSWORD_RULES: 'La contraseña debe contener',
      PASSWORD_RULES_LENGTH: '8 caracteres minimo',
      PASSWORD_RULES_NUMBER: '1 Número',
      PASSWORD_RULES_UPPERCASE: '1 Mayúscula',
      PASSWORD_RULES_SYMBOL: '1 Simbolo (!¡$%&/()=?¿@#{}}[])',
      LABEL_CONFIRM_PASSWORD: 'Confirmar contraseña',
      BTN_CREATE_ACCOUNT: 'Crear cuenta',
    },
    FORGOT_PASSWORD: {
      TITLE_FORM: 'Recuperar contraseña',
      SUBTITLE: 'No te preocupes, enseguida recuperaremos tu contraseña',
      BTN_SUBMIT: 'Recuperar contraseña',
      BTN_CANCEL: 'Cancelar',
      LABEL_EMAIL: 'Escribe tu correo',
      SUCCESS_TITLE: 'Solicitud recibida',
      SUCCESS_MESSAGE: 'Se enviará un correo electrónico con más instrucciones. Por favor verifica tu aplicación de correo',
      SUCCESS_BTN: 'Entendido',
    },
    RESET_PASSWORD: {
      TITLE_FORM: 'Actualizar contraseña',
      BTN_SUBMIT: 'Actualizar contraseña',
      BTN_CANCEL: 'Cancelar',
    },
    COPY: {
      TEXT: '2019 © GameIf Acamdemic • Loft Media Latam | Todos los derechos reservados',
      TERMS: 'Términos y condiciones',
      PRIVACY: 'Aviso de privacidad'
    }
  },
  INSTITUTES: {
    BTN_ADD: 'Institución',
    CARD: {
      GROUPS: 'Grupos',
      STUDENTS: 'Alumnos'
    },
    FORM: {
      TITLE: 'Agregar Institución',
      TITLE_NEW: 'Agregar Institución',
      TITLE_EDIT: 'Editar Institución',
      NAME: 'Nombre',
      COUNTRY: 'País',
      STATE: 'Estado/Provincia',
      ADDRESS: 'Dirección',
      BTN_SAVE: 'Guardar',
    },
    SAVE_SUCCESS: '¡Insituto Guardado!',
  },
  QUIZZES: {
    EDIT: 'Editar',
    DELETE: 'Borrar',
    BTN_ADD: 'Cuestionario',
    BTN_LINK: 'Vincular cuestionario',
    QUESTIONS: 'Preguntas',
    QUESTIONS_TITLE: 'Preguntas del cuestionario',
    REFERENCES: 'Bibliografía',
    ADD_QUESTION: 'Agregar pregunta',
    NEWSPAPER_ARTICLE_FORMAT: 'formato para articulo de periodico',
    FORM: {
      TITLE: 'Datos del cuestionario',
      TITLE_EDIT: 'Editar cuestionario',
      TITLE_ADD: 'Nuevo cuestionario',
      NAME: 'Nombre del cuestionario',
      TOPIC: 'Tema',
      LEVEL: 'Nivel Educativo',
      DEGREE: 'Grado',
      BTN_SAVE: 'Guardar',
      CANCEL: 'Cancelar',
      FEEDBACK_INCORRECT: 'Feedback respuesta incorrecta',
      FEEDBACK_CORRECT: 'Feedback respuesta correcta',
      INCORRECT_ANSWERS: 'Respuestas incorrectas',
      CORRECT_ANSWER: 'Respuesta correcta',
      SELECT_IMAGE: 'Seleccionar imagen',
      REQUIRED_IMAGE: 'Requiere imagen',
      NEW_QUESTION: 'Nueva pregunta',
      NEW_REFERENCE: 'Nueva bibliografía',
      QUESTION_TITLE: 'Titulo de la pregunta máximo 90 caracteres',
      WRITE_CORRECT_ANSWER: 'Escribe la respuesta correcta - máximo 90 caracteres',
      WRITE_INCORRECT_ANSWER: 'Escribe una respuesta incorrecta - máximo 90 caracteres',
      COMPLEMENTARY_FEEDBACK: 'Escribe una retroalimentación complementaria - máximo 90 caracteres',
      POSITIVE_FEEDBACK: 'Escribe una retroalimentación positiva  - máximo 90 caracteres',
      TITLE_REFERENCE: 'Titulo de la bibliografía',
      ALERT_LENGTH: 'Cuidado excediste el numero maximo de caracteres',
    },
    SAVE_SUCCESS: '¡Cuestionario Guardada!',
    DELETE_SUCCESSFULL: '¡Cuestionario eliminado!',
    INVALID_CATEGORY: 'Cuestionario ingresado invalido',
    ERROR_DELETING: '¡Error al borrar la cuestionario!',
    INVLAID_EMAIL: 'correo no válido',
    NO_CONFIRM_PASSWORD: 'La contraseña debe ser la misma',
    IMPORT_SUCCESS: 'El archivo se ha importado de forma correcta'
  },
  GROUPS: {
    NAV: {
      DASHBOARD: 'Dashboard',
      STUDENTS: 'Alumnos',
      QUIZZES: 'Cuestionarios',
      EVENTS: 'Eventos especiales' 
    },
    NAME_INSTITUTE: 'Nombre de la institución',
    BTN_ADD: 'Nuevo Grupo',
    CARD: {
      PIN: 'Pin',
      STUDENTS: 'Alumnos',
      BATTLE_NAME: 'Nombre de la batalla',
      CATEGORY_NAME: 'Nombre de la cuestionario',
      QUESTIONS: 'Preguntas',
    },
    FORM: {
      TITLE: 'Crear grupo',
      TITLE_ADD: 'Crear grupo',
      TITLE_EDIT: 'Editar grupo',
      NAME: 'Nombre del grupo',
      LEVEL: 'Nivel de estudios',
      DEGREE: 'Grado',
      SHIFT: 'Turno',
      BTN_SAVE: 'Guardar',
      NAMES: 'Nombre(s)*',
      APELLIDO_PATERNO: 'Apellido paterno*',
      APELLIDO_MATERNO: 'Apellido Materno*',
      EMAIL: 'Correo*',
      MATRICULA: 'Matricula',
      FACEBOOK: 'Facebook',
      INSTAGRAM: 'Instagram',
      TWITTER: 'Twitter',
      WITHOUT_QUIZZES: 'Sin cuestionarios seleccionadas',
      MY_QUIZZES: 'Mis cuestionarios',
      SHOP: 'Tienda',
    },
    SAVE_SUCCESS: 'Grupo Guardado!',
    DETAILS: {
      ADD_ALUMN: 'Agregar alumno',
      ADD_QUIZZES: 'Agregar cuestionarios',
      ADD_BATTLE: 'Agregar Batalla',
      ADD_CHALLENGE: 'Agregar Competencia',
      BATTLE: 'Batalla',
      ALUMN: 'Alumno',
      CATEGORY: 'cuestionario',
      CHALLENGE: 'Competencia',
    },
  },
  ERRORS: {
    UNEXPECTED_ERROR: 'Ocurrio un error inesperado, vuelva a intentarlo más tarde.',
    REQUIRED_FIELD: 'Campo requerido',
    INVALID_EMAIL: 'Ingrese un correo válido',
  },
  QUESTIONS: {
    FORM: {
      NEW_QUESTION: 'Nueva pregunta',
      EDIT_QUESTION: 'Editar pregunta',
      FEEDBACK_INCORRECT: 'Feedback respuesta incorrecta',
      FEEDBACK_CORRECT: 'Feedback respuesta correcta',
      INCORRECT_ANSWERS: 'Respuestas incorrectas',
      CORRECT_ANSWER: 'Respuesta correcta',
      SELECT_IMAGE: 'Seleccionar imagen',
      REQUIRED_IMAGE: 'Requiere imagen',
      QUESTION_TITLE: 'Titulo de la pregunta máximo 90 caracteres',
      PLACEHOLDER_CORRECT_ANSWER: 'Escribe la respuesta correcta - máximo 90 caracteres',
      PLACEHOLDER_INCORRECT_ANSWER: 'Escribe una respuesta incorrecta - máximo 90 caracteres',
      PLACEHOLDER_FEEDBACK_INCORRECT: 'Escribe una retroalimentación complementaria - máximo 90 caracteres',
      PLACEHOLDER_FEEDBACK_CORRECT: 'Escribe una retroalimentación positiva  - máximo 90 caracteres',
    },
    DELETE_SUCCESSFULL: '¡Pregunta eliminada!',
    ERROR_DELETING: '¡Error al borrar la pregunta!',
    BTN_SAVE: 'Guardar',
    BTN_CANCEL: 'Cancelar',
  },
  REFERENCES: {
    ADD_REFERENCE: 'Nueva bibliografía',
    EDIT_REFERENCE: 'Editar bibliografía', 
    REFERENCE_BOOK: 'Libros',
    REFERENCE_MAGAZINE: 'Revistas',
    REFERENCE_NEWSPAPER: 'Periódico',
    REFERENCE_ENCYCLOPEDIA: 'Enciclopedia',
    REFERENCE_CHAPTERS: 'Capitulos/articulos de libro',
    REFERENCE_WEB: 'Artículo de página web',
    APA_BOOK: '(Formato si tu fuente es un LIBRO)',
    APA_MAGAZINE: '(Formato si tu fuente es un artículo de REVISTA)',
    APA_NEWSPAPER: '(Formato si tu fuente es un PERIÓDICO)',
    APA_ENCYCLOPEDIA: '(Formato si tu fuente es un artículo de ENCICLOPEDIA)',
    APA_CHAPTERS: '(Formato si tu fuente es un artículo o capítulo de un LIBRO)',
    APA_WEB: '(Formato si tu fuente es un artículo de Página WEB)',
    BTN_LABEL_ADD: 'Agregar bibliografía',
    FORM_REFERENCE: {
      AUTHORS: {
        LABEL: 'Autor(es)',
        PLACEHOLDER: 'Nombre(s) del autor(es)'
      },
      YEAR_PUBLICATION: {
        LABEL: 'Año de publicación',
        PLACEHOLDER: 'YYYY'
      },
      REFERENCE_TITLE_LABEL: 'Título',
      REFERENCE_TITLE: {
        LABEL: {
          magazine: 'Título del artículo',
          newspaper: 'Título del artículo',
          encyclopedia: 'Título del artículo',
          chapters: 'Título del capítulo',
          web: 'Título del artículo',
        },
        PLACEHOLDER: {
          magazine: 'Escribe el título del artículo',
          newspaper: 'Escribe el título del artículo',
          encyclopedia: 'Escribe el título del artículo',
          chapters: 'Escribe el título del capítulo',
          web: 'Escribe el título del artículo',
        }
      },
      PLACE_PUBLICATION: {
        LABEL: 'Lugar de publicación',
        PLACEHOLDER: 'Escribe el lugar de publicación'
      },
      EDITORIAL: {
        LABEL: 'Editorial',
        PLACEHOLDER: 'Escribe la editorial'
      },
      RECOVERY_DATE: {
        LABEL: 'Fecha de recuperación',
        PLACEHOLDER: 'YYYY.MM.DD'
      },
      VOLUME: {
        LABEL: 'Volumen',
        PLACEHOLDER: 'Escribe el volumen'
      },
      PAGES: {
        LABEL: 'Páginas',
        PLACEHOLDER: 'Escribe el número de páginas consultadas'
      },
      SOURCE_TITLE_LABEL: 'Título del recurso',
      SOURCE_TITLE: {
        LABEL: {
          book: 'Título del libro',
          chapters: 'Título del libro',
          magazine: 'Nombre de la revista',
          newspaper: 'Título del periódico',
          encyclopedia: 'Nombre de la enciclopdia',
        },
        PLACEHOLDER: {
          book: 'Escribe el título del libro',
          chapters: 'Escribe el título del libro',
          magazine: 'Escribe el nombre de la revista',
          newspaper: 'Escribe el nombre del periódico',
          encyclopedia: 'Escribe el nombre de la enciclopdia',
        }
      },
      PUBLISHING_ASSOCIATION: {
        LABEL: 'Asosiación que publica el artículo',
        PLACEHOLDER: 'Asosiación que publica el artículo'
      },
      URL: {
        LABEL: 'URL',
        PLACEHOLDER: 'Escribe la direccion de la pagina consultada'
      },
      BTN_SAVE: 'GUARDAR'
    },
    NAV_TYPE_REFERENCES: {
      BOOK: 'Nueva bibliografía a un libro',
      MAGAZINE: 'Nueva bibliografía a una revista',
      NEWSPAPER: 'Nueva bibliografía a un periódico',
      ENCYCLOPEDIA: 'Nueva bibliografía a una enciclopedia',
      CHAPTERS: 'Nueva bibliografía a un capítulo de un libro',
      WEB: 'Editar bibliografía a una página web'
    },
    DELETE_SUCCESSFULL: 'Bibliografía eliminada!',
    ERROR_DELETING: '¡Error al borrar bibliografía!',
  }
}

export default LANG;