import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';
import LANG from '../../const/language.const';
import MenuNav from './menu-nav';
import SearchBar from '../ui/search-bar';

function NavbarGroup(props) {
  const menuOptions = [
    {
      label: LANG.GROUPS.NAV.DASHBOARD,
      icon: 'dashboard',
      link: ROUTES.GROUPS.DASHBOARD.replace(':group', props.groupID)
    },
    {
      label: LANG.GROUPS.NAV.STUDENTS,
      icon: 'face',
      link: ROUTES.GROUPS.STUDENTS.replace(':group', props.groupID)
    },
    {
      label: LANG.GROUPS.NAV.QUIZZES,
      icon: 'view_module',
      link: ROUTES.GROUPS.QUIZZES.replace(':group', props.groupID)
    },
    {
      label: LANG.GROUPS.NAV.EVENTS,
      icon: 'whatshot',
      link: ROUTES.GROUPS.CAMPAIGNS.replace(':group', props.groupID)
    }
  ];
  const showSearchBar = props.searchBar === false ? props.searchBar : true;

  return (
    <div className="navbar navbar-groups">
      <div className="nav-back">
        <Link to={props.linkBack}>
          <i className="material-icons arrow-back">arrow_back</i>
        </Link>
      </div>
      <div className="navbar-title">
        <h4>{props.titleNav}</h4>
        <div className="keycode">Pin <strong className="text-accent">{props.groupPin}</strong></div>
      </div>
      { showSearchBar &&
        <div className="navbar-search">
          <SearchBar
            value={props.searchQuery}
            showCancelSearch={true}
            onChange={props.searchHandler}
          />
        </div>
      }
      <MenuNav 
        navOptions={menuOptions}
        currentUri={props.currentOption}
      />
    </div>
  );
}

export default NavbarGroup;