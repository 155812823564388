import React, { Component } from 'react';
import REFERENCE_FIELDS from '../../const/reference-fields.const';
import { referencesServices } from '../../services';
import ROUTES from '../../const/routes.const';
import TitleNavbar from '../../components/navbar/title-navbar';
import Layout from '../../components/layout/layout';

class ReferenceDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reference: {
        type: 'book',
        quiz: ''
      }
    };
  }

  componentDidMount() {
    this.getReference()
  }

  async getReference() {
    try {
      let referencesId = this.props.match.params.reference;
      const response = await referencesServices.getById(referencesId);
      this.setState({
        reference: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  _renderNavbar() {
    let linkToBack = ROUTES.QUIZZES.REFERENCES.replace(':quiz', this.state.reference.quiz);
    let titleNavbar = 'Detalle de referencia';

    return (
      <TitleNavbar 
        linkBack={linkToBack}
        title={titleNavbar}
      />
    );
  }

  _renderFields() {
    const {reference} = this.state;
    let fields = REFERENCE_FIELDS[reference.referenceType];
    if (!fields) {
      return null;
    }

    return fields.map((item, index) => {
      return (
        <div key={index} className="mb-3 pb-2 border-bottom">
          <p>
            <i className="material-icons md-18 text-primary">{item.icon}</i>
            <strong className="text-accent ml-2">{item.label}</strong>
          </p>
          <p>{reference[item.name] || ''}</p>
        </div>
      )
    });
  }

  render() {
    return (
      <Layout navBar={this._renderNavbar()} >
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8">
            {this._renderFields() }
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReferenceDetail;