import React from 'react';
import ListQuizzes from '../../../components/list-quizzes/list-quizzes';
import { Button } from '../../../components/ui';

function FormChallengeQuizzes(props) {
  const { 
    quizzes,
    selected,
    onSelect,
    onBack,
    onNext,
   } = props;

   let disabledNext = selected && selected.length > 0 ? false : true;

  return (
    <div className="form-challenge-quizzes">
      <div className="row mb-3">
        <div className="col">
          <strong className="text-primary">Cuestionarios disponibles</strong><br/>
          <span>{selected.length} seleccionados</span>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ListQuizzes 
            quizzes={quizzes}
            selectedQuizzes={selected}
            changeSelected={onSelect}
          />
        </div>
      </div>
      <div className="row">
        <div className="col pt-4 text-right">
          <Button 
            color="outline-primary"
            icon="chevron_right"
            label="Regresar"
            className="ml-2"
            onClick={onBack}
          />
          <Button 
            color="outline-primary"
            icon="chevron_right"
            label="Continuar"
            className="ml-2"
            disabled={disabledNext}
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
}

export default FormChallengeQuizzes;