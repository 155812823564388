import React, { Component } from 'react';
import AuthService from '../services/auth.service';
import LocalStorageService from '../services/local-storage.service';
import ROUTES from '../const/routes.const';
import { LANDING_PAGE } from '../enviroments/enviroment';

class ValidateLogin extends Component {

    componentDidMount() {
        this.validateSession();
    }

    async validateSession() {
        await AuthService.validateSession(this.props.match.params).then(response => {
            LocalStorageService.loginSession(response.data);
            this.props.history.push(ROUTES.HOME);
        }).catch(() => {
            window.location.replace(LANDING_PAGE);
        })
    }

    render() {return (<></>)}
}

export default ValidateLogin;