import React, { Component } from 'react';
import { addDays, addHours, getHours, getMinutes, setHours, setMinutes } from 'date-fns';
import FormChallengeSettings from './form-challenge-settings';
import ModalEvents from '../../../components/modal-events/modal-events';
import FormChallengeTeams from './form-challenge-teams';
import { studentsServices, groupService, teamChallengeService } from '../../../services';
import FormChallengeQuizzes from './form-challenge-quizzes';
import FormChallengeSchedule from './form-challenge-schedule';
import swal from '@sweetalert/with-react';
import EVENT_STATUS from '../../../const/event-status.const';

class ModalFormTeamChallenge extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.loadData = this.loadData.bind(this);
    this.changeChallengeHandler = this.changeChallengeHandler.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.selectStudentsHandler = this.selectStudentsHandler.bind(this);
    this.createTeamHandler = this.createTeamHandler.bind(this);
    this.changeTeamsHandler = this.changeTeamsHandler.bind(this);
    this.calculateMembersByTeam = this.calculateMembersByTeam.bind(this);
    this.createRandomTeamsHandler = this.createRandomTeamsHandler.bind(this);
    this.selectQuizzesHandler = this.selectQuizzesHandler.bind(this);
    this.hideModalHandler = this.hideModalHandler.bind(this);
    this.removeTeamMemberHandler = this.removeTeamMemberHandler.bind(this);
    this.changeStudentsHandler = this.changeStudentsHandler.bind(this);
    this.changeScheduleHandler = this.changeScheduleHandler.bind(this);
    this.saveHandler = this.saveHandler.bind(this);
    this.resetData = this.resetData.bind(this);
  }

  async loadData() {
    await this.getStudents();
    this.getQuizzes();
    if (this.props.editChallenge) {
      this.getChallenge(this.props.editChallenge);
    }
  }

  getInitialState() {
    let today = new Date();
    return {
      challenge: {
        name: '',
        group: this.props.group,
        startDate: today,
        endDate: addDays(today, 1),
        status: EVENT_STATUS.ACTIVE,
        rounds: [
          {
            startTime: today,
            endTime: addHours(today, 1)
          }
        ]
      },
      errors: {},
      currentStep: 1,
      students: [],
      quizzes: [],
      teams: [],
      selectedStudents: [],
      selectedQuizzes: [],
    };
  }

  resetData() {
    this.setState(this.getInitialState());
  }

  async getStudents() {
    try {
      const response = await studentsServices.getStudentsGroup(this.props.group);
      this.setState({ students: response.data });
    } catch (error) {
      console.log(error);
    }
  }

  async getQuizzes() {
    try {
      const response = await groupService.getQuizzes(this.props.group);
      this.setState({ quizzes: response.data });
    } catch (error) {
      console.log(error);
    }
  }

  async getChallenge(challengeId) {
    try {
      let response = await teamChallengeService.getDetail(challengeId);
      if (response.data) {
        response.data.status = EVENT_STATUS.ACTIVE;
        response.data.createdAt = new Date(response.data.createdAt);
        response.data.endDate = new Date(response.data.endDate);
        response.data.startDate = new Date(response.data.startDate);
        let rounds = [];
        for (let i = 0; i < response.data.rounds.length; i++) {
          const element = response.data.rounds[i];
          rounds.push({
            startTime: this.minutesToDate(element.startTime),
            endTime: this.minutesToDate(element.endTime)
          });
        }
        response.data.rounds = rounds;
        let teams = await teamChallengeService.getTeams(response.data._id);
        let filterStudents = this.filterTeamsStudents(this.state.students, teams.data);
        this.setState({ 
          challenge: response.data,
          teams: teams.data,
          students: filterStudents 
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  filterTeamsStudents(students, teams) {
    let filterStudents = students;

    if (teams && teams.length) {
      for (let i = 0; i < teams.length; i++) {
        const members = teams[i].members;
        for (let j = 0; j < members.length; j++) {
          filterStudents = filterStudents.filter(item => item._id !== members[j]._id);
        }
      }
    }

    return filterStudents;
  }

  async saveHandler() {
    try {
      let challenge = this.state.challenge;
      let rounds = challenge.rounds;
      for (let i = 0; i < rounds.length; i++) {
        rounds[i].startTime = this.dateToMinutes(rounds[i].startTime);
        rounds[i].endTime = this.dateToMinutes(rounds[i].endTime); 
      }
      challenge.rounds = rounds;
      challenge.quizzes = this.state.selectedQuizzes;
      let teams = this.formatTeamsObj(this.state.teams);

      if (challenge._id) {
        await teamChallengeService.update(challenge, teams);
      } else {
        await teamChallengeService.save(challenge, teams);
      }
      
      this.props.onSaved();
    } catch (error) {
      console.log(error)
    }
  }

  formatTeamsObj(teams) {
    let format = []
    
    for (let i = 0; i < teams.length; i++) {
      let team = teams[i];
      team.members = team.members.map(item => item._id);
      format.push(team);
    }

    return format;
  }

  dateToMinutes(date) {
    let hours = getHours(date);
    let minutes = getMinutes(date);

    return (hours * 60) + minutes;
  }

  minutesToDate(minutes) {
    let hours = Math.floor(minutes / 60);
    let restMinutes = minutes % 60;
    let date = setHours(setMinutes(new Date(), restMinutes), hours);

    return date;
  }

  changeChallengeHandler(challenge) {
    this.setState({ challenge });
  }

  nextStep() {
    if (this.state.currentStep < 4) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  }

  previousStep() {
    if (this.state.currentStep > 1) {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  }

  selectStudentsHandler(selected) {
    this.setState({
      selectedStudents: selected
    });
  }

  createTeamHandler() {
    let {teams, selectedStudents, students} = this.state;
    let filterStudents = [];
    let members = [];

    for (let index = 0; index < students.length; index++) {
      if (selectedStudents.includes(students[index]._id)) {
        members.push(students[index]);
      } else {
        filterStudents.push(students[index]);
      }
    }

    let team = {
      name: `Equipo ${teams.length + 1}`,
      members
    } 
    teams.push(team);


    this.setState({
      teams,
      students: filterStudents,
      selectedStudents: [],
    });
  }

  changeTeamsHandler(teams) {
    this.setState({ teams });
  }

  calculateMembersByTeam(numTeams) {
    let totalStudents = this.state.students.length;
    let numStudents = Math.floor(totalStudents / numTeams);
    let extraStudents = totalStudents % numTeams;
    let eqMembers = numTeams - extraStudents;
    let teams = [];

    if (eqMembers === numTeams) {
      teams.push({
        numTeams: numTeams,
        numMembers: numStudents
      });
    } else {
      teams.push({
        numTeams: extraStudents,
        numMembers: numStudents + 1
      });

      teams.push({
        numTeams: numTeams - extraStudents,
        numMembers: numStudents
      });
    }
    
    return teams;
  }

  shuffleStudents(students) {
    for (let i = students.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = students[i];
        students[i] = students[j];
        students[j] = temp;
    }

    return students;
  }

  createRandomTeamsHandler(numTeams) {
    let students = this.state.students;
    students = this.shuffleStudents(students);
    let teams = [];
    let calculatedTeams = this.calculateMembersByTeam(numTeams);

    for (let i = 0; i < calculatedTeams.length; i++) {
      let qtyTeams = calculatedTeams[i].numTeams;
      for (let j = 0; j < qtyTeams; j++) {
        let members = students.splice(0, calculatedTeams[i].numMembers);
        teams.push({
          name: `Equipo ${teams.length + 1}`,
          members
        });
      }
    }

    this.setState({
      teams,
      students
    });
  }

  removeTeamMemberHandler(member, team) {
    let {teams, students} = this.state;
    teams[team].members = teams[team].members.filter(item => item._id !== member._id);
    students.push(member);
    
    if (teams[team].members.length < 1) {
      teams = teams.filter((item, index) => index !== team);
    }

    this.setState({
      teams,
      students
    });
  }

  changeStudentsHandler(students) {
    let filterSelected = this.state.selectedStudents.filter(item => {
      return students.find(student => student._id === item);
    });

    this.setState({
      students,
      selectedStudents: filterSelected
    });
  }

  selectQuizzesHandler(selectedQuizzes) {
    this.setState({ selectedQuizzes });
  }

  changeScheduleHandler(rounds) {
    let challenge = this.state.challenge;
    challenge.rounds = rounds;
    this.setState({ challenge });
  }

  async hideModalHandler() {
    if (this.state.challenge.name) {
      let isConfirm = await swal({
        title: "¿Deseas cancelar la creación del evento?",
        text: "Recuerda que puedes retormarlo después guardandolo como borrador",
        icon: "warning",
        buttons: {
          cancel: 'Continuar editando',
          defeat: 'Borrar',
          catch: {
            text: "Guardar como borrador",
            value: "catch",
          },
        },
        dangerMode: true,
      });

      switch (isConfirm) {
        case "defeat":
          this.setState(this.getInitialState(), this.props.onHide());
          break;
        case "catch":
          let challenge = this.state.challenge;
          challenge.status = EVENT_STATUS.DRAFT
          this.setState({ challenge }, this.saveHandler);
          break;
        default:
          break;
      }
    } else {
      this.setState(this.getInitialState(), this.props.onHide());
    }
  }

  _renderBody() {
    switch (this.state.currentStep) {
      case 1:
        return (
          <FormChallengeSettings 
            teamChallenge={this.state.challenge}
            fieldsErros={this.state.errors}
            onChange={this.changeChallengeHandler}
            onNext={this.nextStep}
          />
        );
      case 2:
        return (
          <FormChallengeTeams 
            students={this.state.students}
            selectedStudents={this.state.selectedStudents}
            onSelectStudent={this.selectStudentsHandler}
            onCreateTeam={this.createTeamHandler}
            teams={this.state.teams}
            onChangeTeams={this.changeTeamsHandler}
            onChangeStudents={this.changeStudentsHandler}
            onRemoveTeamMember={this.removeTeamMemberHandler}
            calculateRandomTeams={this.calculateMembersByTeam}
            onCreateRandomTeams={this.createRandomTeamsHandler}
            onNext={this.nextStep}
            onBack={this.previousStep}
          />
        );
      case 3: 
        return (
          <FormChallengeQuizzes 
            quizzes={this.state.quizzes}
            selected={this.state.selectedQuizzes}
            onSelect={this.selectQuizzesHandler}
            onNext={this.nextStep}
            onBack={this.previousStep}
          />
        );
      case 4:
        return (
          <FormChallengeSchedule 
            schedule={this.state.challenge.rounds}
            onChange={this.changeScheduleHandler}
            onBack={this.previousStep}
            onSave={this.saveHandler}
          />
        );
      default:
        return null;
    }
  }

  render() {
    let titlesSteps = [
      'Información del evento',
      'Configurar los equipos',
      'Cuestionarios',
      'Horarios'
    ];

    let title = titlesSteps[this.state.currentStep - 1];

    return (
      <ModalEvents
        show={this.props.isOpen}
        onHide={this.hideModalHandler}
        onEntering={this.loadData}
        onExiting={this.resetData}
        title={title}
        showBullets={true}
        numBullets={4}
        currectBullet={this.state.currentStep}
      >
        <div className="form-team-challenge">
          { this._renderBody() }
        </div>
      </ModalEvents>
    );
  }
}

export default ModalFormTeamChallenge;