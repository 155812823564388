import React from 'react';
import { Modal } from 'react-bootstrap';
import { Icon } from '../ui';
import './modal-type-event.scss';

function ModalTypeEvent(props) {
  return (
    <Modal
      size="lg"
      show={props.isOpen}
      onHide={props.onClose}
      className="modal-type-event"
    >
      <Modal.Header className="bg-primary">
          <h3>Nuevo Evento</h3>
          <span>Elige el tipo de competencia</span>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-6">
            <div className="card" onClick={() => props.onSelectType('single')}>
              <div className="card-body text-primary">
                <Icon name="person" />
                <div>
                  <h4 className="text-primary">Individual</h4>
                  <p className="text-muted">Cada alumno del grupo compite para obtener la mejor puntuación.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card" onClick={() => props.onSelectType('team')}>
              <div className="card-body text-primary">
                <Icon name="people" />
                <div>
                  <h4 className="text-primary">En equipo</h4>
                  <p className="text-muted">Los alumnos del grupo compiten en equipos para obtener la mejor puntuación.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalTypeEvent;