import React from 'react';
import CardQuizShopInfo from './card-quiz-shop-info';

function CardQuizFeatured(props) {
  return (
    <div className="card card-mk-quiz">
      <div className="row no-gutters">
        <div className="col-4">
          <div className="card-body">
            <CardQuizShopInfo 
              quiz={props.quiz} 
              enabledCartBtn={props.enabledCartBtn}
            />
          </div>
        </div>
        <div className="col-8">
          <img src="https://via.placeholder.com/440x300" className="card-img" alt="portada" />
        </div>
      </div>
    </div>
  );
}

export default CardQuizFeatured;