const ROUTES = {
  AUTH: {
    VALIDATE_LOGIN: "/validate-login/:token/:hash",
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password/:academic/:uuid",
    VALIDATE_ACCOUNT: "/validate-account/:academic/:hash"
  },
  HOME: '/',
  INSTITUTES: {
    ROOT: "/institutes",
    GROUPS: "/institutes/:id/groups"
  },
  QUIZZES: {
    ROOT: "/quizzes",
    QUESTIONS: "/quizzes/:quiz",
    REFERENCES: "/quizzes/:quiz/references",
    COMMUNITY: "/quizzes/:quiz/community",
  },
  QUESTIONS: {
    ROOT: "/questions/:quiz",
    DETAIL: "/questions-detail/:question",
    ADD: "/questions-add/:quiz",
    EDIT: "/questions-edit/:question",
  },
  REFERENCES: {
    ROOT: "/references",
    DETAIL: "/references-detail/:reference",
    ADD: "/references-add/:type/:quiz",
    EDIT: "/references-edit/:reference"
  },
  GROUPS: {
    ROOT: "/groups/",
    INSTITUTE: "/groups/institute/:institute",
    DASHBOARD: "/groups/dashboard/:group",
    STUDENTS: "/groups/students/:group",
    QUIZZES: "/groups/quizzes/:group",
    CAMPAIGNS: "/groups/campaigns/:group",
    DETAIL_ROOT: "/group-detail/",
    DETAIL: "/group-detail/:groupID/",
    DETAIL_ALUMNS: "/group-detail/:groupID/alumns",
    DETAIL_CATEGORIES: "/group-detail/:groupID/quizzes",
    DETAIL_BATTLES: "/group-detail/:groupID/battles",
    DETAIL_CHALLENGES: "/group-detail/:groupID/challenges",
  },
  CAMPAIGNS: {
    ADD: "/campaigns-add/:group",
    DETAIL: "/campaigns/:campaign/detail",
    STATS: "/campaigns/:campaign/stats",
  },
  TEAM_CHALLENGES: {
    DETAIL: "/team-challenge/:challenge/detail",
    STATS: "/team-challenge/:challenge/stats"
  },
  MARKETPLACE: {
    ROOT: "/market",
    CART: "/market/cart",
    CHECKOUT: "/market/checkout",
    CHECKOUT_COMPLETE: "/market/checkout-complete"
  },
  ONBOARDING: {
    ROOT: "/onboarding/:review?",
    REVIEW: "/onboarding/review",
  }
}

export default ROUTES;