import React from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction } from '../ui';
import DEFAULT_AVATAR from '../../assets/images/default-avatar.png';
import { avatar } from '../../const/avatars.conts';

function CampaignRanking(props) {
  const { studentsList } = props;
  return (
    <div className="campaign-ranking">
      <List>
        { studentsList.map((item, index) => {
          let position = index + 1;
          return (
            <ListItem 
              key={`item-ranking-${position}`} 
              verticalAlign="center"
              className={index < 3 ? 'ranking-top' : ''}
            >
              <ListItemAvatar>
                <Avatar title={position} className="text-primary font-weight-bold" />
              </ListItemAvatar>
              <ListItemAvatar>
                <Avatar src={avatar[item.avatar]} />
              </ListItemAvatar>
              <ListItemText 
                primary={item.name}
                secondary={item.nickName}
              />
              <ListItemSecondaryAction>
                <span className="ranking-score h4 text-primary">
                  {`${item.points} pts`}
                </span>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }) 
        }
      </List>
    </div>
  );
}

export default CampaignRanking;