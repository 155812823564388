import React from 'react';
import Icon from './icon';

function IconButton(props) {
  const { color, disabled, name, size, onClick, className } = props;
  let btnClasses = 'btn-icon';
  if (className) {
    btnClasses = `${btnClasses} ${className}`;
  }
  return (
    <button
      type="button"
      className={btnClasses}
      disabled={ disabled ? true : false }
      onClick={onClick}
    >
      <Icon 
        color={color}
        name={name}
        size={size}
      />
    </button>
  );
}

export default IconButton;