import React from 'react';

const OnboardingLayout = ({ children }) => (
    <div className="wrap-onboarding ondas-bg">
        <div className="container">
            <div className="row">
                <div className="wrap-onboarding">
                        {children}
                </div>
            </div>
        </div>
    </div>
)

export default OnboardingLayout