import React from 'react';

function CardRadioButton(props) {
  const  { 
    label, 
    labelClass, 
    checked, 
    value, 
    onClick, 
    ...radioProps 
  } = props;
  const isChecked = checked ? true : false;
  const icon = isChecked ? 'radio_button_checked' : 'radio_button_unchecked';
  

  return (
    <div className="card" onClick={() => onClick(value)}>
      <div className="card-body text-center">
        <input 
          type="radio"  
          defaultChecked={isChecked}
          value={value}
          style={{display: 'none'}}
          { ...radioProps }
        />
        <div 
          className="card-radio-icon text-primary"
          style={{position: 'absolute', top: '10px', left: '10px'}}
        >
          <i className="material-icons">{icon}</i>
        </div>
        <div className={labelClass}>{label}</div>
      </div>
    </div>
  );
}

export default CardRadioButton;