import React from 'react';
import DatePickerField from './datepicker-field';
import TimePickerField from './timepicker-field';

function DateTimePickerField(props) {
  return (
    <div className="form-inline form-datetime">
      <DatePickerField 
        selected={props.selected}
        onChange={props.onChange}
      />
      <TimePickerField
        selected={props.selected}
        onChange={props.onChange}
      />
    </div>
  );
}

export default DateTimePickerField;