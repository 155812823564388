import React, { Component } from 'react'
import { Icon, IconButton } from '../../components/ui'

class NotificationsDetail extends Component {
  render() {
    return (
      <div className="notifications">
        <div className="notifications-header">
          <IconButton name="close" 
            onClick={this.props.onClose}
          />
          <IconButton 
            name="arrow_back" 
            color="primary"
            onClick={this.props.onBack}
          />
          <h3>Regresar</h3>
        </div>
        <div className="notifications-detail">
          <div className="notifications-detail--title">
            <div className="pr-2">
              <Icon name="notifications" color="primary" />
            </div>
            <div>
              <h4>{this.props.notification.title}</h4>
              <span>{this.props.notification.date}</span>
            </div>
          </div>
          <div className="notifications-detail--desc">
            <p>{this.props.notification.message}</p>
            <h5 className="text-primary">Enviado a</h5>
            <ul>
              { this.props.notification && this.props.notification.groups &&
                this.props.notification.groups.map(item => {
                  return (
                    <li>{item.name}</li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default NotificationsDetail;