import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';
import Layout from '../../components/layout/layout';
import { ButtonToolbar, DropdownButton, Dropdown } from 'react-bootstrap'
import LANG from '../../const/language.const';
import { QuestionsServices } from '../../services';
import NavbarQuiz from '../../components/navbar/navbar-quiz';

class QuestionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: {
        _id: this.props.match.params.__idQuestion || "",
        title: "¿¿De que color es el caballo blanco de napoleon??",
        imageRequired: false,
        image: "",
        answers: [
          { text: "Respuesta correcta de prueba", correct: true },
          { text: "Respuesta incorrecta 1", correct: false },
          { text: "Respuesta incorrecta 1", correct: false },
          { text: "Respuesta incorrecta 1", correct: false },
        ],
        feedbackComplementary: "Retroalimentación cuando el usuario contesta correctamente",
        feedbackConstructive: "Retroalimentación cuando el usuario contesta incorrectamente",
        quiz: "",
      }
    }

    this.editQuizHandler = this.editQuizHandler.bind(this);
  }

  componentDidMount() {
    this.getQuestion();
  }

  searchHandler(search) {
    console.log(search);
  }

  async getQuestion() {
    try {
      const question = this.props.match.params.question;
      const response = await QuestionsServices.getById(question);
      this.setState({ question: response.data });
    } catch (error) {
      console.log(error);
    }
  }

  editQuizHandler() {
    this.props.history.push(ROUTES.QUESTIONS.EDIT.replace(':question', this.state.question._id));
  }

  render() {
    const { question } = this.state;
    let backTo = ROUTES.QUIZZES.QUESTIONS.replace(':quiz', question.quiz);
    return (
      <Layout
        navBar={
          <NavbarQuiz
            linkBack={backTo}
            titleNav={question.title}
            onlyTitle
            quiz={question.quiz}
            updateQuiz={this.editQuizHandler}
          />
        }
      >
        <div>
          {
            question.imageRequired &&
            <React.Fragment>
              <div className="px-3">
                <div className="img-question">
                  <img src={question.image} alt="" />
                </div>
              </div>
              <hr className="my-4" />
            </React.Fragment>
          }
          <div className="px-3 mb-3">
            <p className="d-flex align-items-center text-accent">
              <i className="material-icons text-primary mr-1">check_circle</i><strong>*Respuesta correcta</strong>
            </p>
            <p>{`${question.answers[0].text}`}</p>
          </div>
          <div className="px-3 mb-3">
            <p className="d-flex align-items-center text-accent">
              <i className="material-icons text-primary mr-1">sentiment_very_satisfied</i><strong>Feedback respuesta correcta</strong>
            </p>
            <p>{question.feedbackComplementary}</p>
          </div>
          <hr className="my-4" />
          <div className="px-3 mb-3">
            <p className="d-flex align-items-center text-accent">
              <i className="material-icons mr-1 text-primary">cancel</i><strong>Respuestas incorrectas</strong>
            </p>
            {
              question.answers.map((opt, i) => {
                if (opt.correct) {
                  return null
                } else {
                  return (
                    <p key={i} className="d-flex align-items-center">
                      <span className="text-accent mr-2"><strong>{i}</strong></span>{opt.text }
                    </p>
                  )
                }
              })
            }
          </div>
          <div className="px-3 mb-3">
            <p className="d-flex align-items-center text-accent">
              <i className="material-icons mr-1 text-primary">sentiment_very_dissatisfied</i><strong>Feedback respuesta incorrecta</strong>
            </p>
            <p>{question.feedbackConstructive}</p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default QuestionDetail;