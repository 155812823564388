import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarGroup from '../../components/navbar/navbar-group';
import ROUTES from '../../const/routes.const';
import swal from '@sweetalert/with-react';
import { groupService, studentsServices } from '../../services';
import CardStudent from '../../components/cards/card-student';
import LANG from '../../const/language.const';

class GroupStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: {
        _id: this.props.match.params.group || '',
        name: '',
        pin: '',
      },
      students: [],
      filterStudents: [],
      search: '',
    }

    this.searchHandler = this.searchHandler.bind(this);
    this.removeStudent = this.removeStudent.bind(this);
  }

  componentDidMount() {
    this.getGroup();
    this.getStudents();
  }

  async getGroup() {
    try {
      const groupId = this.props.match.params.group;
      const response = await groupService.getGroupDetail(groupId);
      this.setState({
        group: response.data
      });
    } catch(error) {
      console.log(error);
    }
  }

  async getStudents() {
    try {
      const groupId = this.props.match.params.group;
      const response = await studentsServices.getStudentsGroup(groupId);
      let data = response.data;
      data.sort((a,b) => {
        if (a.nickName > b.nickName) {
          return 1;
        }
        if (a.nickName < b.nickName) {
          return -1;
        }
        return 0
      });

      this.setState({
        students: data,
        filterStudents: data
      });
    } catch (error) {
      console.log(error);
    }
  }

  searchHandler(search) {
    let students = this.state.students;
    let results = students;

    if (search !== '') {
       let expSearch = new RegExp(`${search}.*`, 'i');
       results = students.filter(item => {
          return expSearch.test(item.nickName);
       });
    } 

    this.setState({
       search,
       filterStudents: results,
    });
 }

 async removeStudent(student) {
  const confirm = await swal({
    text: `¿Seguro que quieres eliminar al alumno "${student.nickName}" del grupo?`,
    icon: 'warning',
    dangerMode: true,
    buttons: [
      'Cancelar',
      'Si, eliminar'
    ]
  });

  if (!confirm) {
    return false;
  }

  try {
    await groupService.removeStudent(this.state.group._id, student._id);
    this.onSucces(`Se ha eliminado el alumno "${student.nickName}" del grupo`);
    this.getStudents();
  } catch (error) {
    if (error.response && error.response.data) {
      this.onError(error.response.data.message);
    } else {
      this.onError(LANG.ERRORS.UNEXPECTED_ERROR);
    }
  }
}

async onSucces(message) {
  await swal({
    text: message,
    icon: 'success',
  });
}

onError(message) {
  swal({
    text: message,
    icon: 'error',
  });
}

 _renderStudents() {
    return this.state.filterStudents.map(item => {
       return (
          <div className="col-12 col-md-4 col-xl-3" key={item._id}>
             <CardStudent 
              student={item} 
              removeHandler={this.removeStudent}
            />
          </div>
       );
    });
 }

  _renderEmpty() {
    return (
      <div className="col-md-6 offset-md-3 pt-5">
        <div className="text-center">Aún no hay alumnos en este grupo. Recuerda que para que los alumnos puedan ingresar es importante que descarguen la aplicación "Gameif Academy" e ingresen en ella el siguiente <strong>Pin: { `${this.state.group.pin}` }</strong></div>
      </div>
    );
  }

  render() {
    const { group, students } = this.state;
    
    return (
      <Layout
        navBar={
          <NavbarGroup 
            linkBack={ROUTES.GROUPS.DASHBOARD.replace(':group', group._id)}
            titleNav={group.name}
            groupPin={group.pin}
            currentOption={this.props.match.url}
            groupID={group._id}
            searchHandler={this.searchHandler}
          />
        }
      >
        <div className="row">
          { students.length > 0 ? this._renderStudents() : this._renderEmpty() }
        </div>
      </Layout>
    );
  }
}

export default GroupStudents;