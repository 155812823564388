import React from 'react';

function Button(props) {
  const { children, color, outline, label, icon, className, ...restProps } = props;
  let classColor = color ? `btn-${color}` : 'btn-primary';
  let classOutline = outline ? 'btn-outline' : '';
  let classNames = `btn btn-rounded ${classColor} ${classOutline} ${className ? className : ''}`;

  return (
    <button type="button" className={classNames} {...restProps}>
      { icon && <i className="material-icons">{icon}</i> }
      { label }
    </button>
  );
}

export default Button;