import React from 'react';

export const Sidebar = ({ height, active, children, side }) => {
  return (
    <div className={`sidebar-wrapper ${active ? 'active' : ''}`}>
      <div className={`sidebar ${side === 'left' ? 'sidebar-left' : 'sidebar-right'} ${active ? 'active' : ''}`} style={{ minHeight: height}}>
        <React.Fragment>{children}</React.Fragment>
      </div>
    </div>
  );
};