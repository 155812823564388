import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarCampaign from '../../components/navbar/navbar-campaign';
import ROUTES from '../../const/routes.const';
import { Icon, Button } from '../../components/ui';
import CampaignRanking from '../../components/campaign-ranking/campaign-ranking';
import EventStatusProgress from '../../components/event-status-progress/event-status-progress';
import { campaignsService, statsServices } from '../../services';
import swal from '@sweetalert/with-react';

class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {
        _id: this.props.match.params.campaign || '',
        name: 'Nombre del evento',
        group: '',
      },
      ranking: [],
      stats: null
    }

    this.closeEvent = this.closeEvent.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.getCampaignStats = this.getCampaignStats.bind(this);
  }
  
  componentDidMount() {
    this.getCampaign();
    this.getCampaignStats();
  }

  async getCampaign() {
    const campaignID = this.props.match.params.campaign;
    try {
      const response = await campaignsService.getDetail(campaignID);
      const resRanking = await campaignsService.ranking(campaignID);
      this.setState({
        campaign: response.data,
        ranking: resRanking.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getCampaignStats() {
    const campaignID = this.props.match.params.campaign;
    try {
      const response = await statsServices.getCampaignStats(campaignID);
      console.log(response.data)
      let usersByDay = 0;
      let campaignStats = {};
      for (let i = 0; i < response.data.usersByDay.length; i++) {
        const element = response.data.usersByDay[i];
        usersByDay += element.count;
      }

      campaignStats.students_actives = usersByDay ? Math.round(usersByDay / response.data.usersByDay.length) : 0;

      let accuracy = 0;
      let totalQuestions = 0;
      let corrects = 0;
      for (let i = 0; i < response.data.usersStats.length; i++) {
        const element = response.data.usersStats[i];
        corrects += element.correct;
        totalQuestions += element.total;
      }

      accuracy = corrects ? Math.round(corrects * 100 / totalQuestions) : 0;
      campaignStats.accuracy = accuracy;
      campaignStats.challengesPlayed = response.data.challengesPlayed;
      campaignStats.challengesOpen = response.data.challengesOpen;
      campaignStats.studentsPlayed = response.data.usersStats.length;
      
      this.setState({
        stats: campaignStats
      });
    } catch (error) {
      console.log(error);
    }
  }

  async closeEvent() {
    let isConfirm = await swal({
      title: "Detener evento",
      text: "Al detener el evento, se cerran los retos que aún no esten terminados y no se podrá volver a activar nuevamente.",
      icon: "warning",
      buttons: ["Cancelar", "Detener"],
      dangerMode: true,
    });

    if (isConfirm) {
      await campaignsService.close(this.state.campaign._id);
      this.getCampaign();
      swal({
        title: "Evento finalizado",
        icon: "success",
        buttons: ["Cancelar", "Ok"]
      });
    }
  }

  async downloadReport() {
    try {
      const response = await campaignsService.downloadReport(this.state.campaign._id);
      const urlReport = window.URL.createObjectURL(new Blob([response.data]));
      const linkReport = document.createElement('a');
      linkReport.href = urlReport;
      const fileNameReport = `Reporte_evento_${this.state.campaign._id}.csv`//your file name
      linkReport.setAttribute('download', fileNameReport);
      document.body.appendChild(linkReport);
      linkReport.click();
      linkReport.remove();
    } catch (error) {
      console.log(error);
      swal({
        title: "Error",
        text: "Ocurrio un error al descargar el reporte, intentalo más tarde",
        icon: "error",
      });
    }
  }

  render() {
    const { campaign, stats, ranking } = this.state;
    let today = new Date()
    let startDate = campaign.startDate ? new Date(campaign.startDate) : new Date();
    let endDate = campaign.endDate ? new Date(campaign.endDate) : new Date();
    let createdAt = campaign.createdAt ? new Date(campaign.createdAt) : startDate;
    let completionDate = campaign.completionDate ? new Date(campaign.completionDate) : endDate;
    let sharePercentage = (ranking && ranking.length && stats) ? Math.round(stats.studentsPlayed * 100 / ranking.length) : 0 ;

    console.log(stats);

    return (
      <Layout 
        navBar={
          <NavbarCampaign 
            linkBack={ROUTES.GROUPS.CAMPAIGNS.replace(':group', campaign.group)}
            titleNav={campaign.name}
            currentOption={this.props.match.url}
            campaignID={campaign._id}
          />
        }
      >
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-primary">
              <Icon name="info" size={18} /> Acerca del evento
            </h4>
            <div className="card border-primary mb-3">
              <div className="card-body text-center p-0">
                <EventStatusProgress 
                  eventStatus={campaign.status || ''}
                  eventStartDate={startDate}
                  eventEndDate={endDate}
                  eventCreation={createdAt}
                  eventCompletionDate={completionDate}
                />
              </div>
            </div>
            <div className="card widget-campaign-stats">
              <div className="card-body text-center">
                <div className="row">
                  <div className="col">
                    <span className="stat-val">{campaign.rounds || 0}</span>
                    <span className="stat-desc">Rondas por enfrentamiento</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{campaign.questionsNumber || 0}</span>
                    <span className="stat-desc">Preguntas por ronda</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{(campaign.quizzes && campaign.quizzes.length) || 0}</span>
                    <span className="stat-desc">Cuestionarios agregados</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card widget-campaign-stats">
              <div className="card-body text-center">
                <div className="row">
                  <div className="col">
                    <span className="stat-val">{ stats && stats.students_actives }</span>
                    <span className="stat-desc">Alumnos activos por día</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{ stats && stats.accuracy }%</span>
                    <span className="stat-desc">Responden correctamente</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{ sharePercentage }%</span>
                    <span className="stat-desc">Alumnos participaron</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card widget-campaign-stats">
              <div className="card-body text-center">
                <div className="row">
                  <div className="col">
                    <span className="stat-val">{ stats && stats.challengesPlayed.completed_challenges }</span>
                    <span className="stat-desc">Retos completos entre alumnos</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{ stats && stats.challengesOpen }</span>
                    <span className="stat-desc">Retos pendientes entre alumnos</span>
                  </div>
                  <div className="col align-self-center">
                    <Button color="outline-primary" icon="system_update_alt" label="Reporte" onClick={this.downloadReport} />
                  </div>
                </div>
              </div>
            </div>
            <div className="py-3">
              { campaign.status === 'active' && endDate > today &&
                <Button 
                  color="outline-primary" 
                  label="Detener" 
                  icon="alarm_off"
                  onClick={this.closeEvent}
                />
              }
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="text-primary">
              <Icon name="star" size={18} />Posiciones de los alumnos
            </h4>
            <CampaignRanking 
              studentsList={ranking}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default CampaignDetail;