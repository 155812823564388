import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarTeamChallenge from '../../components/navbar/navbar-team-challenge';
import ROUTES from '../../const/routes.const';
import { Icon, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction } from '../../components/ui';
import EventStatusProgress from '../../components/event-status-progress/event-status-progress';
import { teamChallengeService } from '../../services';
import swal from '@sweetalert/with-react';
import ListTeamsRanking from './list-teams-ranking';

class TeamChallengeDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challenge: {
        _id: this.props.match.params.challenge || '',
        name: 'Nombre del evento',
        group: '',
      },
      teams: [],
      statsChallenge: null,
      schedule: null,
      roundsPlayed: 0,
      sharePercentage: 0
    }

    this.closeEvent = this.closeEvent.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
  }
  
  componentDidMount() {
    this.getChallenge();
    this.getTeams();
    this.getStats();
  }

  async getChallenge() {
    const challengeID = this.props.match.params.challenge;
    try {
      const response = await teamChallengeService.getDetail(challengeID);
      this.setState({
        challenge: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getTeams() {
    const challengeID = this.props.match.params.challenge;
    try {
      const response = await teamChallengeService.getTeams(challengeID);
      let teams = response.data;
      teams = teams.sort((a,b) => b.points - a.points);
      this.setState({ teams });
    } catch (error) {
      console.log(error);
    }
  }

  async getStats() {
    const challengeID = this.props.match.params.challenge;
    try {
      const response = await teamChallengeService.getChallengeStats(challengeID);
      const resRounds = await teamChallengeService.getRounds(challengeID);
      let rounds = resRounds.data;
      rounds.sort((a, b) => { return (new Date(a.startDate) - new Date(b.startDate)) });
      let roundsPlayed = 0;
      for (let i = 0; i < rounds.length; i++) {
        if (new Date(rounds[i].endDate) < new Date()) {
          roundsPlayed += 1;
        }
      }

      let totalStudents = 0;
      let playedStudents = 0;
      for (let i = 0; i < response.data.length; i++) {
        const element = response.data[i];
        totalStudents += element.members.length;
        for (let j = 0; j < element.members.length; j++) {
          const student = element.members[j];
          if (student.contribution.length) {
            playedStudents += 1;
          }
        }
      }

      let sharePercentage = totalStudents ? Math.round(playedStudents * 100 / totalStudents) : 0;

      this.setState({
        statsChallenge: response.data,
        schedule: rounds,
        roundsPlayed,
        sharePercentage
      });
    } catch (error) {
      console.log(error);
    }
  }

  async closeEvent() {
    let isConfirm = await swal({
      title: "Detener evento",
      text: "Al detener el evento en equipo, ya no se podrán jugar las rondas restantes y no se podrá volver a activar nuevamente.",
      icon: "warning",
      buttons: ["Cancelar", "Detener"],
      dangerMode: true,
    });

    if (isConfirm) {
      await teamChallengeService.close(this.state.challenge._id);
      this.getChallenge();
      swal({
        title: "Evento finalizado",
        icon: "success",
        buttons: ["Cancelar", "Ok"]
      });
    }
  }

  async downloadReport() {
    try {
      const response = await teamChallengeService.downloadReport(this.state.challenge._id);
      const urlReport = window.URL.createObjectURL(new Blob([response.data]));
      const linkReport = document.createElement('a');
      linkReport.href = urlReport;
      const fileNameReport = `Reporte_evento_equipos_${this.state.challenge._id}.csv`//your file name
      linkReport.setAttribute('download', fileNameReport);
      document.body.appendChild(linkReport);
      linkReport.click();
      linkReport.remove();
    } catch (error) {
      console.log(error);
      swal({
        title: "Error",
        text: "Ocurrio un error al descargar el reporte, intentalo más tarde",
        icon: "error",
      });
    }
  }

  _renderSchedule() {
    if (this.state.schedule) {
      return (
        <List>
          { this.state.schedule.map((item, index) => {
            let roundPlayed = new Date(item.endDate) < new Date(); 
            return (
              <ListItem 
                key={`row-sch-${index}`} 
                verticalAlign="center"
              >
                <ListItemText>
                  <div className="row">
                    <div className="col-md-4">
                      <h3 className="text-primary schedule-title">Ronda {item.roundDay}</h3>
                      <h4 className="text-primary schedule-subtitle">Día {item.dayNumber}</h4>
                    </div>
                    <div className="col-md-8">
                      <div><strong>Inicio:</strong> {new Date(item.startDate).toLocaleString()}</div>
                      <div><strong>Fin:</strong> {new Date(item.endDate).toLocaleString()}</div>
                    </div>
                  </div>
                </ListItemText>
                <ListItemSecondaryAction>
                  <Icon
                    name={roundPlayed ? 'check' : 'access_time'} size={18}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          }) 
          }
        </List>
      );
    }

    return null;
  }
  
  render() {
    const { challenge, teams, schedule } = this.state;
    let today = new Date();
    let startDate = challenge.startDate ? new Date(challenge.startDate) : new Date();
    let endDate = challenge.endDate ? new Date(challenge.endDate) : new Date();
    let createdAt = challenge.createdAt ? new Date(challenge.createdAt) : startDate;
    let completionDate = challenge.completionDate ? new Date(challenge.completionDate) : endDate;

    return (
      <Layout 
        navBar={
          <NavbarTeamChallenge 
            linkBack={ROUTES.GROUPS.CAMPAIGNS.replace(':group', challenge.group)}
            titleNav={challenge.name}
            currentOption={this.props.match.url}
            challengeID={challenge._id}
          />
        }
      >
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-primary">
              <Icon name="info" size={18} /> Acerca del evento
            </h4>
            <div className="card border-primary mb-3">
              <div className="card-body text-center p-0">
                <EventStatusProgress 
                  eventStatus={challenge.status || ''}
                  eventStartDate={startDate}
                  eventEndDate={endDate}
                  eventCreation={createdAt}
                  eventCompletionDate={completionDate}
                />
              </div>
            </div>
            <div className="card widget-campaign-stats">
              <div className="card-body text-center">
                <div className="row">
                  <div className="col">
                    <span className="stat-val">{(challenge.rounds && challenge.rounds.length) || 0}</span>
                    <span className="stat-desc">Rondas por día</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{challenge.numQuestions || 0}</span>
                    <span className="stat-desc">Preguntas por ronda</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{(challenge.quizzes && challenge.quizzes.length) || 0}</span>
                    <span className="stat-desc">Cuestionarios agregados</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card widget-campaign-stats">
              <div className="card-body text-center">
                <div className="row">
                  <div className="col">
                    <span className="stat-val">{ this.state.roundsPlayed }</span>
                    <span className="stat-desc">Rondas jugadas</span>
                  </div>
                  <div className="col">
                    <span className="stat-val">{this.state.sharePercentage}%</span>
                    <span className="stat-desc">Alumnos participaron</span>
                  </div>
                  <div className="col align-self-center">
                    <Button color="outline-primary" icon="system_update_alt" label="Reporte" onClick={this.downloadReport} />
                  </div>
                </div>
              </div>
            </div>
            
            <div className="card card-schedule-event">
              <div className="card-body">
              <h4 className="text-primary">Rondas del evento</h4>
              { this._renderSchedule() }
              </div>
            </div>

            <div className="py-3">
              { challenge.status === 'active' && endDate > today &&
                <Button 
                  color="outline-primary" 
                  label="Detener" 
                  icon="alarm_off"
                  onClick={this.closeEvent}
                />
              }
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="text-primary">
              <Icon name="star" size={18} />Posiciones de los equipos
            </h4>
            <div className="campaign-ranking">
              <ListTeamsRanking teams={teams} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default TeamChallengeDetail;