import React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom'
import ROUTES from './const/routes.const';
// import 'bootstrap/dist/css/bootstrap.css';
// import './scss/bootstrap/bootstrap.scss';
import './scss/style.scss';

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// import Home from './views/home';
// import Groups from './views/groups/groups';
import ValidateLogin from './views/validate-login';
import NotFound from './views/not-found/not-found';
import PrivateRoute from './views/PrivateRoute';
import Institutes from './views/institutes/institutes';
import InstituteDetail from './views/institutes/institute-detail';
import Quizzes from './views/quizzes/quizzes';
import Questions from './views/questions/questions';
import QuestionDetail from './views/questions/question-detail';
import QuestionForm from './views/questions/question-form';
import References from './views/references/references';
import ReferenceForm from './views/references/reference-form';
import ReferenceDetail from './views/references/reference-detail';

import GroupDashboard from './views/groups/group-dashboard';
import GroupStudents from './views/groups/group-students';
import GroupEvents from './views/groups/group-events';
import GroupQuizzes from './views/groups/group-quizzes';
import CampaignWizard from './views/campaigns/campaign-wizard';
import CampaignDetail from './views/campaigns/campaign-detail';
import CampaignStats from './views/campaigns/campaign-stats';
import TeamChallengeDetail from './views/team-challenges/team-challenge-detail';
import TeamChallengeStats from './views/team-challenges/team-challenge-stats';
import Marketplace from './views/marketplace/marketplace';
import MarketplaceCart from './views/marketplace/marketplace-cart';
import MarketplaceCheckoutComplete from './views/marketplace/marketplace-checkout-complete';
import MarketplaceCheckout from './views/marketplace/marketplace-checkout';
import QuizCommunity from './views/quizzes/quiz-community';
import Login from './views/auth/login';
import Register from './views/auth/register';
import ForgotPassword from './views/auth/forgot-password';
import ResetPassword from './views/auth/reset-password';
import OnboardingScreen from './views/onboarding';

function App() {
  return (
    <div className="App">
      <ReactNotification />
      <Switch>
        {/* Auth */}
        <Route exact path={ROUTES.AUTH.LOGIN} component={Login} />
        <Route exact path={ROUTES.AUTH.VALIDATE_LOGIN} component={ValidateLogin} />
        <Route exact path={ROUTES.AUTH.REGISTER} component={Register} />
        <Route exact path={ROUTES.AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={ROUTES.AUTH.RESET_PASSWORD} component={ResetPassword} />

        {/* rutas institutos */}
        <PrivateRoute exact path={ROUTES.INSTITUTES.ROOT} component={Institutes} />
        <PrivateRoute exact path={ROUTES.INSTITUTES.GROUPS} component={InstituteDetail} />
        
        {/* rutas de grupos */}
        <PrivateRoute exact path={ROUTES.GROUPS.DASHBOARD} component={GroupDashboard} />
        <PrivateRoute exact path={ROUTES.GROUPS.STUDENTS} component={GroupStudents} />
        <PrivateRoute exact path={ROUTES.GROUPS.QUIZZES} component={GroupQuizzes} />
        <PrivateRoute exact path={ROUTES.GROUPS.CAMPAIGNS} component={GroupEvents} />

        {/* <PrivateRoute exact path={`${ROUTES.GROUPS.ROOT}:instituteID`} component={Groups} /> */}
        {/* <PrivateRoute exact path={`${ROUTES.GROUPS.DETAIL}:section`} component={GroupsDetail} /> */}
        
        {/* cuestionarios */}
        <PrivateRoute exact path={ROUTES.HOME} component={Quizzes} />
        <PrivateRoute exact path={ROUTES.QUIZZES.ROOT} component={Quizzes} />
        <PrivateRoute exact path={ROUTES.QUIZZES.QUESTIONS} component={Questions} />
        <PrivateRoute exact path={ROUTES.QUIZZES.REFERENCES} component={References} />
        <PrivateRoute exact path={ROUTES.QUIZZES.COMMUNITY} component={QuizCommunity} />

        {/* questions */}
        <PrivateRoute exact path={`${ROUTES.QUESTIONS.DETAIL}`} component={QuestionDetail} />
        <PrivateRoute exact path={`${ROUTES.QUESTIONS.ADD}`} component={QuestionForm} />
        <PrivateRoute exact path={`${ROUTES.QUESTIONS.EDIT}`} component={QuestionForm} />
        {/* references */}
        <PrivateRoute exact path={`${ROUTES.REFERENCES.ADD}`} component={ReferenceForm} />
        <PrivateRoute exact path={`${ROUTES.REFERENCES.EDIT}`} component={ReferenceForm} />
        <PrivateRoute exact path={`${ROUTES.REFERENCES.DETAIL}`} component={ReferenceDetail} />
        
        {/* Campaigns */}
        <PrivateRoute exact path={`${ROUTES.CAMPAIGNS.ADD}`} component={CampaignWizard} />
        <PrivateRoute exact path={ROUTES.CAMPAIGNS.DETAIL} component={CampaignDetail} />
        <PrivateRoute exact path={ROUTES.CAMPAIGNS.STATS} component={CampaignStats} />

        {/* Team challenges */}
        <PrivateRoute exact path={ROUTES.TEAM_CHALLENGES.DETAIL} component={TeamChallengeDetail} />
        <PrivateRoute exact path={ROUTES.TEAM_CHALLENGES.STATS} component={TeamChallengeStats} />

        {/* Marketplace */}
        <PrivateRoute exact path={ROUTES.MARKETPLACE.ROOT} component={Marketplace} />
        <PrivateRoute exact path={ROUTES.MARKETPLACE.CART} component={MarketplaceCart} />
        <PrivateRoute exact path={ROUTES.MARKETPLACE.CHECKOUT} component={MarketplaceCheckout} />
        <PrivateRoute exact path={ROUTES.MARKETPLACE.CHECKOUT_COMPLETE} component={MarketplaceCheckoutComplete} />

        {/* Onboarding */}
        <PrivateRoute exact path={ROUTES.ONBOARDING.ROOT} component={OnboardingScreen} />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
