import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const campaignsService = {
  getCampaignsGroup,
  getDetail,
  getOne,
  save,
  close,
  ranking,
  downloadReport
}

async function getDetail(campaignId) {
  return await HTTP.get(`${ROUTES_API.CAMPAIGNS.GET_DETAIL}${campaignId}`);
}

async function getOne(campaignId) {
  return await HTTP.get(`${ROUTES_API.CAMPAIGNS.GET_DETAIL}${campaignId}`);
}

async function getCampaignsGroup(groupId) {
  return await HTTP.get(`${ROUTES_API.CAMPAIGNS.GET_BY_GROUP}${groupId}`);
}

async function save(campaign) {
  return await HTTP.post(ROUTES_API.CAMPAIGNS.SAVE, campaign);
}

async function close(campaignId) {
  let params = { campaign: campaignId };
  return await HTTP.post(ROUTES_API.CAMPAIGNS.CLOSE, params);
}

async function ranking(campaignId) {
  return await HTTP.get(ROUTES_API.CAMPAIGNS.RANKING.replace(':campaign', campaignId));
}

async function downloadReport(campaignId) {
  return await HTTP.get(ROUTES_API.CAMPAIGNS.REPORT.replace(':campaign', campaignId), {responseType: 'blob'});
}