import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './questions-import-modal.scss';
import * as XLSX from 'xlsx';
import { quizzesServices } from '../../../services/quizzes.service';

class QuestionsImportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileImport: null,
      errorsFile: [],
      warningsFile: [],
      disableImport: true,
      uploading: false,
    }

    this.onChangeFileHandler = this.onChangeFileHandler.bind(this);
    this.processFile = this.processFile.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  onSelectFileHandler() {
    document.getElementById('inputFileImport').click();
  }

  onChangeFileHandler(event) {
    let file = event.target.files.length ? event.target.files[0] : null;
    let disable = file ? false : true;
    
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        let errorsFile = this.validateFile(event);
        if (errorsFile.length > 0) {
          this.setState({
            disableImport: true,
            errorsFile
          });
        } else {
          let warnings = this.warningsFile(event);

          this.setState({
            errorsFile: [],
            warningsFile: warnings
          });
        }
      };

      reader.onerror = function(ex) {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    }

    this.setState({
      fileImport: file,
      disableImport: disable
    });
  }

  getReadableFileSizeString(fileSizeInBytes) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }

  async processFile() {
    try {
      if (this.state.fileImport) {
        this.setState({ uploading: true });
        let file = new FormData();
        file.append('csv', this.state.fileImport);
        await quizzesServices.importQuiz(this.props.quiz, file);
        this.resetForm();
        this.props.onImportSuccess();
      }
    } catch(error) {
      console.log(error);
    }
  }

  warningsFile(e) {
    const header = {
      'Feedback_Respuesta_Correcta' : 'Feedback_Respuesta_Correcta',
      'Feedback_Respuesta_Incorrecta' : 'Feedback_Respuesta_Incorrecta',
      'Respuesta_Correcta' : 'Respuesta_Correcta',
      'Respuesta_Incorrecta_1' : 'Respuesta_Incorrecta_1',
      'Respuesta_Incorrecta_2' : 'Respuesta_Incorrecta_2',
      'Respuesta_Incorrecta_3' : 'Respuesta_Incorrecta_3',
      'Titulo_Pregunta' : 'Titulo_Pregunta',
    };
    const warnings = [];
    let index = 2;
    const workbook = XLSX.read(e.target.result, { type: 'binary' });
    const sheet_name_list = workbook.SheetNames;

    const xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], { blankrows: true});

    for (const row of xlData) {
      if ( row[header.Feedback_Respuesta_Correcta]  === undefined &&
          row[header.Feedback_Respuesta_Incorrecta] === undefined &&
          row[header.Respuesta_Correcta] === undefined &&
          row[header.Respuesta_Incorrecta_1] === undefined &&
          row[header.Respuesta_Incorrecta_2] === undefined &&
          row[header.Respuesta_Incorrecta_3] === undefined &&
          row[header.Titulo_Pregunta] === undefined ) {
          continue;
      }

      if (row[header.Titulo_Pregunta].length > 90 ||
        row[header.Respuesta_Correcta].length > 80 ||
        row[header.Respuesta_Incorrecta_1].length > 80 ||
        row[header.Respuesta_Incorrecta_2].length > 80 ||
        row[header.Respuesta_Incorrecta_3].length > 80 ||
        row[header.Feedback_Respuesta_Correcta].length > 90  ||
        row[header.Feedback_Respuesta_Incorrecta].length > 90
        ) {
          warnings.push(`En la línea ${index} se encontró valores que exceden la longitud recomendada.`);
        }


      index++;
    }

    return warnings;
  }

  validateFile(e) {
    const header = {
      'Feedback_Respuesta_Correcta' : 'Feedback_Respuesta_Correcta',
      'Feedback_Respuesta_Incorrecta' : 'Feedback_Respuesta_Incorrecta',
      'Respuesta_Correcta' : 'Respuesta_Correcta',
      'Respuesta_Incorrecta_1' : 'Respuesta_Incorrecta_1',
      'Respuesta_Incorrecta_2' : 'Respuesta_Incorrecta_2',
      'Respuesta_Incorrecta_3' : 'Respuesta_Incorrecta_3',
      'Titulo_Pregunta' : 'Titulo_Pregunta',
    };
    const errors = [];
    const workbook = XLSX.read(e.target.result, { type: 'binary' });
    const sheet_name_list = workbook.SheetNames;
    const headerData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], { header: 1, range: 'A1:Z1'});

    const keys = headerData[0];

    if ( keys.indexOf(header.Titulo_Pregunta) === -1 ) {
      errors.push(`No existe la columna ${header.Titulo_Pregunta}`);
    }
    if ( keys.indexOf(header.Respuesta_Correcta) === -1 ) {
      errors.push(`No existe la columna ${header.Respuesta_Correcta}`);
    }
    if ( keys.indexOf(header.Respuesta_Incorrecta_1) === -1 ) {
      errors.push(`No existe la columna ${header.Respuesta_Incorrecta_1}`);
    }
    if ( keys.indexOf(header.Respuesta_Incorrecta_2) === -1 ) {
      errors.push(`No existe la columna ${header.Respuesta_Incorrecta_2}`);
    }
    if ( keys.indexOf(header.Respuesta_Incorrecta_3) === -1 ) {
      errors.push(`No existe la columna ${header.Respuesta_Incorrecta_3}`);
    }
    if ( keys.indexOf(header.Feedback_Respuesta_Correcta) === -1 ) {
      errors.push(`No existe la columna ${header.Feedback_Respuesta_Correcta}`);
    }
    if ( keys.indexOf(header.Feedback_Respuesta_Incorrecta) === -1 ) {
      errors.push(`No existe la columna ${header.Feedback_Respuesta_Incorrecta}`);
    }

    if (errors.length > 0) {
      return errors;
    }

    const xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]], { blankrows: true});
    const xlDataTmp = xlData.filter( r => {
      return r[header.Feedback_Respuesta_Correcta]  !== undefined &&
          r[header.Feedback_Respuesta_Incorrecta] !== undefined &&
          r[header.Respuesta_Correcta] !== undefined &&
          r[header.Respuesta_Incorrecta_1] !== undefined &&
          r[header.Respuesta_Incorrecta_2] !== undefined &&
          r[header.Respuesta_Incorrecta_3] !== undefined &&
          r[header.Titulo_Pregunta] !== undefined;
    } );

    if (xlDataTmp.length === 0) {
      errors.push('El archivo que tratas de subir esta vacío.');
      return errors;
    }

    for (let i = 0; i < xlData.length; i++) {
      if ( xlData[i][header.Feedback_Respuesta_Correcta]  === undefined &&
          xlData[i][header.Feedback_Respuesta_Incorrecta] === undefined &&
          xlData[i][header.Respuesta_Correcta] === undefined &&
          xlData[i][header.Respuesta_Incorrecta_1] === undefined &&
          xlData[i][header.Respuesta_Incorrecta_2] === undefined &&
          xlData[i][header.Respuesta_Incorrecta_3] === undefined &&
          xlData[i][header.Titulo_Pregunta] === undefined )   {
            continue;
          }
      if ( xlData[i][header.Feedback_Respuesta_Correcta] === undefined ||
          xlData[i][header.Feedback_Respuesta_Incorrecta] === undefined ||
          xlData[i][header.Respuesta_Correcta] === undefined ||
          xlData[i][header.Respuesta_Incorrecta_1] === undefined ||
          xlData[i][header.Respuesta_Incorrecta_2] === undefined ||
          xlData[i][header.Respuesta_Incorrecta_3] === undefined ||
          xlData[i][header.Titulo_Pregunta]  === undefined ) {
            errors.push(`El archivo contiene 1 o más campos vacíos en la fila ${ (i + 2) }`);
          }
    }

    return errors;
  }

  resetForm() {
    this.setState({
      fileImport: null,
      errorsFile: [],
      warningsFile: [],
      disableImport: true,
      uploading: false,
    })
  }

  closeModal() {
    if (!this.state.uploading) {
      this.resetForm();
      this.props.onHide();
    }
  }

  _renderForm() {
    return (
      <div>
        { this.state.errorsFile.length > 0  &&
          <div className="alert alert-danger mb-3">
            { this.state.errorsFile.map((error, index) => (<div key={`error-${index}`}>{error}</div>)) }
          </div>
        }
        { this.state.warningsFile.length > 0 &&
          <div className="alert alert-warning mb-4">
            <div className="rules-xlsx">
              Titulo_Pregunta	(Máximo recomendado 90 caracteres)<br/>
              Respuesta_Correcta (Máximo recomendado 80 caracteres)<br/>
              Respuesta_Incorrecta_1 (Máximo recomendado 80 caracteres)<br/>
              Respuesta_Incorrecta_2 (Máximo recomendado 80 caracteres)<br/>
              Respuesta_Incorrecta_3 (Máximo recomendado 80 caracteres)<br/>
              Feedback_Respuesta_Correcta (Máximo recomendado 90 caracteres)<br/>
              Feedback_Respuesta_Incorrecta (Máximo recomendado 90 caracteres)<br/><br/>
            </div>
            { this.state.warningsFile.map((error, index) => (<div key={`warn-${index}`}>{error}</div>)) }
          </div>
        }
        <div className="file-upload">
          { !this.state.uploading && 
            <button 
              className="btn btn-rounded btn-primary"
              onClick={this.onSelectFileHandler}
            >
              <i className="material-icons">add</i>
              <span>Seleccionar archivo</span>
            </button>
          }
          <input
            type="file"
            className="d-none"
            id="inputFileImport"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            multiple
            onChange={this.onChangeFileHandler} />
          { this.state.fileImport &&
            <div className={`file-info ${this.state.errorsFile.length > 0 ? 'border-danger' : 'border-success'}`}>
              <i className="material-icons" style={{fontSize: '18px'}}>description</i>&nbsp;
              {`${this.state.fileImport.name} (${this.getReadableFileSizeString(this.state.fileImport.size)})`}
            </div>
          }
        </div>
        { this.state.uploading 
          ? <div className="upload-proccess">
            <p>Importando Archivo ...</p>
            <div className="loader">
              <div className="loaderBar"></div>
            </div>
          </div>
          : <div className="actions">
            <button 
              className="btn btn-rounded btn-secondary"
              onClick={this.closeModal}
            >
              Cancelar
            </button>
            <button 
              className="btn btn-rounded btn-primary"
              disabled={this.state.disableImport}
              onClick={this.processFile}
            >
              Importar
            </button>
          </div>
        }
      </div>
    );
  }

  render() {
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.closeModal}
        className="questions-import-modal"
      >
        <div className="modal-container">
          <Modal.Header closeButton>
            <Modal.Title className="text-primary">Importar Archivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-help">
              <p>Para realizar una carga masiva de preguntas asegúrate de utilizar el template correcto. Si no cuentas con él, &nbsp;
                <a className="btn-link" target="_blank" href={`${process.env.PUBLIC_URL}/gameif-academy-template-questions.xlsx`} rel="noopener noreferrer">
                descárgalo aquí <i className="material-icons" style={{ fontSize: '18px' }}>download</i>
                </a>
              </p>
              <strong>IMPORTANTE:</strong><br/>
              <ul>
                <li>No modificar ningún texto de la fila 1 del archivo para que la plataforma reconozca y cargue correctamente el archivo.</li>
                <li>El archivo no debe ser de más de 5MB, solo se permiten archivos .xls y .xlsx</li>
              </ul>
            </div>
            {this._renderForm()}
          </Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default QuestionsImportModal;