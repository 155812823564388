import React from 'react';
import { Link } from 'react-router-dom';

function MenuNav({ navOptions, currentUri }) {
  return (
    <ul className="nav">
      {
        navOptions.map((item, index) => {
          let activeClass = item.link === currentUri ? 'active' : '';
          return (
            <li className="nav-item" key={index}>
              <Link 
                className={`nav-link ${activeClass} d-flex flex-column align-items-center`}
                to={item.link}
              >
                <i className="material-icons">{item.icon}</i>
                <span>{item.label}</span>
              </Link>
            </li>
          )
        })
      }
    </ul>
  );
}

export default MenuNav