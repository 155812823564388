import React, { Component } from 'react';
import ModalForm from '../../components/ui/modal-form';
import TextField from '../../components/ui/text-field';
import LANG from '../../const/language.const';
import { countriesServices } from '../../services';
import SelectField from '../../components/ui/select-field';


class ModalFormInstitute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institute: {
        name: '',
        country: '',
        state: '',
        address: ''
      },
      errors: {},
      countries: [],
      selectedCountry: null,
      selectedState: null,
    }

    this.changeFieldHandler = this.changeFieldHandler.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.getCountriesOptions = this.getCountriesOptions.bind(this);
    this.getStatesOptions = this.getStatesOptions.bind(this);
    this.changeCountryHandler = this.changeCountryHandler.bind(this);
    this.changeStateHandler = this.changeStateHandler.bind(this);
    this.changeFieldHandler = this.changeFieldHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  async componentDidMount() {
    let institute = this.state.institute;
    let countries = await this.getCountries();
    countries = countries.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
    let selectedCountry = null;
    let selectedState = null;
    if (this.props.dataInstitute) {
      institute = this.props.dataInstitute;
      selectedCountry = this.filterDefaultCountry(countries, institute.country);
      selectedState = this.filterDefaultState(countries, institute.country, institute.state);
    }

    this.setState({
      institute,
      countries,
      selectedCountry,
      selectedState
    });
  }

  filterDefaultCountry(countries, countryID) {
    let country = countries.find(item => item._id === countryID);
    let defaultObj = country ? { label: country.name, value: country._id } : null;
    return defaultObj;
  }

  filterDefaultState(countries, countryID, stateID) {
    let country = countries.find(item => item._id === countryID);
    let defaultObj = null;
    if (country) {
      let province = country.states.find(item => item._id === stateID);
      defaultObj = province ? { label: province.name, value: province._id } : null;
    }
    
    return defaultObj;
  }
  
  async getCountries() {
    try {
      const response = await countriesServices.getCountries();
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        this.onError(error.response.data.message);
      } else {
        this.onError('Error al cargar los datos');
      }
    }
  }

  getCountriesOptions() {
    return this.state.countries.map(item => {
      return {
        value: item._id,
        label: item.name
      }
    });
  }

  getStatesOptions() {
    const { institute, countries } = this.state;
    if (institute.country) { 
      let currentCountry = countries.find(item => item._id === institute.country);
      if (currentCountry) {
        return currentCountry.states.map(item => {
          return {
            value: item._id,
            label: item.name
          }
        });
      }
    }

    return [];
  }

  changeFieldHandler(event) {
    const name = event.target.name;
    const value = event.target.value;
    let institute = this.state.institute;
    let errors = this.state.errors;
    institute[name] = value;
    errors[name] = '';
    
    this.setState({ institute, errors });
  }

  changeCountryHandler(selectedOption) {
    let institute = this.state.institute;
    let errors = this.state.errors;
    institute.country = selectedOption.value;
    institute.state = '';
    errors.country = '';

    this.setState({ 
      selectedCountry: selectedOption,
      selectedState: null,
      institute, 
      errors 
    });
  }

  changeStateHandler(selectedOption) {
    let institute = this.state.institute;
    let errors = this.state.errors;
    institute.state = selectedOption.value;
    errors.state = '';

    this.setState({ 
      selectedState: selectedOption,
      institute,
      errors 
    });
  }

  submitHandler() {
    let errors = this.validateForm();
    if (errors) {
      this.setState({ errors });
      return;
    }

    this.props.saveInstitute(this.state.institute);
  }

  validateForm() {
    const { institute, selectedCountry } = this.state;
    let validForm = true;
    let errors = {};

    if (!institute.name) {
      validForm = false;
      errors.name = 'Campo obligatorio';
    }

    if (!institute.country) {
      validForm = false;
      errors.country = 'Campo obligatorio';
    }

    if (selectedCountry && selectedCountry.label === 'México') {
      if (!institute.state) {
        validForm = false;
        errors.state = 'Campo obligatorio';
      }
    }

    if (!institute.address) {
      validForm = false;
      errors.address = 'Campo obligatorio';
    }

    return validForm ? null : errors;
  }

  render() {
    const { institute } = this.state;
    const modalTitle = institute._id 
      ? LANG.INSTITUTES.FORM.TITLE_EDIT 
      : LANG.INSTITUTES.FORM.TITLE_NEW;

    return (
      <ModalForm 
        title={modalTitle}
        isOpen={this.props.isOpen}
        onHide={this.props.onClose}
      >
        <div className="mb-3 alert alert-info" style={{fontSize: '12px'}}>
          Llena este formulario con el nombre y ubicación de la Institución. Recuerda que una institución es una escuela, colegio, universidad u organización educativa en la que impartes clases.
        </div>
        <form className="form form-create-institution px-5">
          <TextField 
            name="name"
            placeholder={LANG.INSTITUTES.FORM.NAME}
            value={institute.name}
            errorMessage={this.state.errors.name}
            onChange={this.changeFieldHandler}
          />
          <SelectField 
            name="country"
            value={this.state.selectedCountry}
            options={this.getCountriesOptions()}
            placeholder={LANG.INSTITUTES.FORM.COUNTRY}
            onChange={this.changeCountryHandler}
            errorMessage={this.state.errors.country}
          />
          { this.state.selectedCountry && this.state.selectedCountry.label === 'México' &&
            <SelectField 
              name="state"
              value={this.state.selectedState}
              options={this.getStatesOptions()}
              placeholder={LANG.INSTITUTES.FORM.STATE}
              onChange={this.changeStateHandler}
              errorMessage={this.state.errors.state}
            />
          }
          <TextField 
            name="address"
            placeholder={LANG.INSTITUTES.FORM.ADDRESS}
            value={institute.address}
            errorMessage={this.state.errors.address}
            onChange={this.changeFieldHandler}
          />
          <div className="form-actions mt-5">
            <button 
              type="button"
              className="btn btn-outline-primary btn-rounded btn-block"
              onClick={this.submitHandler}
            >
              {LANG.INSTITUTES.FORM.BTN_SAVE}
            </button>
          </div>
        </form>
      </ModalForm>
    );
  }
}

export default ModalFormInstitute;