import React from 'react';
import { Button, List, ListItem, ListItemSecondaryAction, IconButton, ListItemText, ListItemAvatar, Avatar } from '../../../components/ui';
import TimePickerField from '../../../components/ui/timepicker-field';
import { setHours, setMinutes, addMinutes } from 'date-fns';

function FormChallengeSchedule(props) {
  const removeScheduleHandler = (item) => {
    let schedule = [];
    if (props.schedule.length > 1) {
      for (let i = 0; i < props.schedule.length; i++) {
        if (i !== item) {
          schedule.push(props.schedule[i])
        }
      }
      
      props.onChange(schedule);
    } 
  }

  const addScheduleHandler = () => {
    let schedule = props.schedule;
    
    schedule.push({
      startTime: schedule[schedule.length - 1].endTime,
      endTime: addMinutes(schedule[schedule.length - 1].endTime, 15)
    });

    props.onChange(schedule)
  }

  const changeStartTimeHandler = (date, item) => {
    let schedule = props.schedule;
    schedule[item].startTime = date;
    props.onChange(schedule);
  }

  const changeEndTimeHandler = (date, item) => {
    let schedule = props.schedule;
    schedule[item].endTime = date;
    props.onChange(schedule);
  }

  let maxSchedule = setHours(setMinutes(new Date(), 35), 23);
  let lastSchedule = props.schedule[props.schedule.length - 1].endTime;
  let disabledAdd = lastSchedule < maxSchedule ? false : true;

  return (
    <div className="form-challenge-schedule">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <strong className="text-primary">Rondas a jugar por día</strong>
        </div>
        <div>
          <Button 
            color="outline-primary"
            icon="add"
            label="Agregar ronda"
            disabled={disabledAdd}
            onClick={addScheduleHandler}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <List>
            { 
              props.schedule && props.schedule.map((item, index) => {
                let minTime = props.schedule[index-1] ? props.schedule[index-1].endTime : setHours(setMinutes(new Date(), 0), 0);
                let maxTime = props.schedule[index+1] ? props.schedule[index+1].startTime : setHours(setMinutes(new Date(), 55), 23);

                return (
                  <ListItem key={`schedule_${index}`}>
                    { props.schedule.length > 1 && 
                    <ListItemSecondaryAction>
                      <IconButton 
                        name="close"
                        onClick={() => removeScheduleHandler(index)}
                      />
                    </ListItemSecondaryAction>
                    }
                    <ListItemAvatar>
                      <Avatar className="text-primary" title={index + 1} />
                    </ListItemAvatar>
                    <ListItemText>
                      <div className="form-inline">
                        <TimePickerField 
                          selected={item.startTime}
                          minTime={minTime}
                          maxTime={maxTime}
                          onChange={date => changeStartTimeHandler(date, index)}
                        />
                        <TimePickerField 
                          selected={item.endTime}
                          minTime={item.startTime}
                          maxTime={maxTime}
                          onChange={date => changeEndTimeHandler(date, index)}
                        />
                      </div>
                    </ListItemText>
                  </ListItem>
                )
              })
            }
          </List>
        </div>
      </div>
      <div className="row">
        <div className="col pt-5 text-right">
          <Button 
            color="outline-primary"
            icon="chevron_right"
            label="Regresar"
            onClick={props.onBack}
          />
          <Button 
            className="ml-2"
            color="outline-primary"
            icon="save"
            label="Guardar y crear evento"
            onClick={props.onSave}
          />
        </div>
      </div>
    </div>
  );
}

export default FormChallengeSchedule;