import LANG from './language.const';

const REFERENCE_FORM_FIELDS = {
  AUTHORS: {
    name: 'authors',
    label: LANG.REFERENCES.FORM_REFERENCE.AUTHORS.LABEL,
    icon: 'portrait',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.AUTHORS.PLACEHOLDER
  },
  YEAR_PUBLICATION: {
    name: 'yearOfPublication',
    label: LANG.REFERENCES.FORM_REFERENCE.YEAR_PUBLICATION.LABEL,
    icon: 'event',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.YEAR_PUBLICATION.PLACEHOLDER
  },
  TITLE_ARTICLE: {
    name: 'referenceTitle',
    label: LANG.REFERENCES.FORM_REFERENCE.REFERENCE_TITLE.LABEL.magazine,
    icon: 'line_weight',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.REFERENCE_TITLE.PLACEHOLDER.magazine,
  },
  TITLE_CHAPTER: {
    name: 'referenceTitle',
    label: LANG.REFERENCES.FORM_REFERENCE.REFERENCE_TITLE.LABEL.chapters,
    icon: 'line_weight',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.REFERENCE_TITLE.PLACEHOLDER.chapters,
  },
  PLACE_PUBLICATION: {
    name: 'placeOfPublication',
    label: LANG.REFERENCES.FORM_REFERENCE.PLACE_PUBLICATION.LABEL,
    icon: 'room',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.PLACE_PUBLICATION.PLACEHOLDER
  },
  EDITORIAL: {
    name: 'editorial',
    label: LANG.REFERENCES.FORM_REFERENCE.EDITORIAL.LABEL,
    icon: 'bookmark',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.EDITORIAL.PLACEHOLDER
  },
  RECOVERY_DATE : {
    name: 'recoveryDate',
    label: LANG.REFERENCES.FORM_REFERENCE.RECOVERY_DATE.LABEL,
    icon: 'event_note',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.RECOVERY_DATE.PLACEHOLDER
  },
  VOLUME : {
    name: 'volume',
    label: LANG.REFERENCES.FORM_REFERENCE.VOLUME.LABEL,
    icon: 'storage',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.VOLUME.PLACEHOLDER
  },
  PAGES : {
    name: 'pages',
    label: LANG.REFERENCES.FORM_REFERENCE.PAGES.LABEL,
    icon: 'layers',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.PAGES.PLACEHOLDER
  },
  SOURCE_TITLE_BOOK : {
    name: 'sourceTitle',
    label: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.LABEL.book,
    icon: 'short_text',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.PLACEHOLDER.book,
  },
  SOURCE_TITLE_CHAPTERS : {
    name: 'sourceTitle',
    label: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.LABEL.chapters,
    icon: 'short_text',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.PLACEHOLDER.chapters,
  },
  SOURCE_TITLE_MAGAZINE : {
    name: 'sourceTitle',
    label: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.LABEL.magazine,
    icon: 'short_text',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.PLACEHOLDER.magazine,
  },
  SOURCE_TITLE_NEWSPAPER : {
    name: 'sourceTitle',
    label: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.LABEL.newspaper,
    icon: 'short_text',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.PLACEHOLDER.newspaper,
  },
  SOURCE_TITLE_ENCYCLOPEDIA: {
    name: 'sourceTitle',
    label: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.LABEL.encyclopedia,
    icon: 'short_text',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.SOURCE_TITLE.PLACEHOLDER.encyclopedia,
  },
  PUBLISHING_ASSOCIATION : {
    name: 'publishingAssociation',
    label: LANG.REFERENCES.FORM_REFERENCE.PUBLISHING_ASSOCIATION.LABEL,
    icon: 'account_balance',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.PUBLISHING_ASSOCIATION.PLACEHOLDER
  },
  URL : {
    name: 'url',
    label: LANG.REFERENCES.FORM_REFERENCE.URL.LABEL,
    icon: 'link',
    placeholder: LANG.REFERENCES.FORM_REFERENCE.URL.PLACEHOLDER
  }
};

const REFERENCE_FIELDS = {
  book: [
    REFERENCE_FORM_FIELDS.AUTHORS, REFERENCE_FORM_FIELDS.YEAR_PUBLICATION, 
    REFERENCE_FORM_FIELDS.SOURCE_TITLE_BOOK, REFERENCE_FORM_FIELDS.PLACE_PUBLICATION, 
    REFERENCE_FORM_FIELDS.EDITORIAL
  ],
  magazine: [
    REFERENCE_FORM_FIELDS.AUTHORS, REFERENCE_FORM_FIELDS.RECOVERY_DATE,
    REFERENCE_FORM_FIELDS.TITLE_ARTICLE, REFERENCE_FORM_FIELDS.SOURCE_TITLE_MAGAZINE, 
    REFERENCE_FORM_FIELDS.VOLUME, REFERENCE_FORM_FIELDS.PAGES],
  newspaper: [
    REFERENCE_FORM_FIELDS.AUTHORS, REFERENCE_FORM_FIELDS.YEAR_PUBLICATION,
    REFERENCE_FORM_FIELDS.TITLE_ARTICLE, REFERENCE_FORM_FIELDS.SOURCE_TITLE_NEWSPAPER, 
    REFERENCE_FORM_FIELDS.PAGES
  ],
  encyclopedia: [
    REFERENCE_FORM_FIELDS.AUTHORS, REFERENCE_FORM_FIELDS.YEAR_PUBLICATION,
    REFERENCE_FORM_FIELDS.TITLE_ARTICLE, REFERENCE_FORM_FIELDS.SOURCE_TITLE_ENCYCLOPEDIA, 
    REFERENCE_FORM_FIELDS.VOLUME, REFERENCE_FORM_FIELDS.PAGES, REFERENCE_FORM_FIELDS.PLACE_PUBLICATION, 
    REFERENCE_FORM_FIELDS.EDITORIAL
  ],
  chapters: [
    REFERENCE_FORM_FIELDS.AUTHORS, REFERENCE_FORM_FIELDS.YEAR_PUBLICATION,
    REFERENCE_FORM_FIELDS.TITLE_CHAPTER, REFERENCE_FORM_FIELDS.SOURCE_TITLE_CHAPTERS, 
    REFERENCE_FORM_FIELDS.PAGES, REFERENCE_FORM_FIELDS.PLACE_PUBLICATION, 
    REFERENCE_FORM_FIELDS.EDITORIAL
  ],
  web: [
    REFERENCE_FORM_FIELDS.AUTHORS, REFERENCE_FORM_FIELDS.YEAR_PUBLICATION,
    REFERENCE_FORM_FIELDS.TITLE_ARTICLE, REFERENCE_FORM_FIELDS.RECOVERY_DATE, 
    REFERENCE_FORM_FIELDS.PUBLISHING_ASSOCIATION, REFERENCE_FORM_FIELDS.URL
  ],
}

export default REFERENCE_FIELDS;