import React from 'react';
import DatePicker, { registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import Icon from './icon';
registerLocale('es', es)

function DatePickerField(props) {
  return (
    <div className="form-group">
      <div className="fg-line fg-line-datepicker">
        <Icon name="event" />
        <DatePicker 
          className="form-control"
          locale="es"
          dateFormat="dd/MM/yyyy"
          selected={props.selected} 
          onChange={date => props.onChange(date)} 
        />
      </div>
    </div>
  );
}

export default DatePickerField;