import React from 'react';
import EventStatusProgress from '../../components/event-status-progress/event-status-progress';

function CampaignCard(props) {
  const { campaign } = props;
  let startDate = campaign.startDate ? new Date(campaign.startDate) : new Date();
  let endDate = campaign.endDate ? new Date(campaign.endDate) : new Date();
  let createdAt = campaign.createdAt ? new Date(campaign.createdAt) : startDate;
  let completionDate = campaign.completionDate ? new Date(campaign.completionDate) : endDate;

  return (
    <div className="card campaign-card" onClick={props.onClick}>
      <div className="card-body text-center px-0 pb-0 pt-3">
        <h4>{campaign.name}</h4>
        <h5>
          <i className="material-icons md-18">person</i>
          <span>Competencia individual</span>
        </h5>
        <div className="row block-stats">
          <div className="col-4">
            <h4>{campaign.rounds}</h4>
            <span>Rondas por enfrentamiento</span>
          </div>
          <div className="col-4">
            <h4>{campaign.questionsNumber}</h4>
            <span>Preguntas por ronda</span>
          </div>
          <div className="col-4">
            <h4>{campaign.quizzes.length}</h4>
            <span>Cuestionarios</span>
          </div>
        </div>
        <EventStatusProgress 
          eventStatus={campaign.status || ''}
          eventStartDate={startDate}
          eventEndDate={endDate}
          eventCreation={createdAt}
          eventCompletionDate={completionDate}
        />
      </div>
    </div>
  );
}

export default CampaignCard;