import React, { Component } from 'react';
import CardGroup from '../../components/cards/card-group';
import ROUTES from '../../const/routes.const';
import LANG from '../../const/language.const';
import Layout from '../../components/layout/layout';
import LocalStorageService from '../../services/local-storage.service';
import swal from '@sweetalert/with-react';
import { academicServices, groupService } from '../../services';
import NavbarInstituteGroups from '../../components/navbar/navbar-institute-groups';
import ModalFormGroup from '../groups/modal-form-group';
import { Icon } from '../../components/ui';

class InstituteDetail extends Component {
  constructor(props) {
    super(props);
    this.currentAcademic = LocalStorageService.session();
    this.state = {
      institutes: [],
      institutesOptions: [],
      instituteSelected: this.props.match.params.id || "",
      groupsSelected: [],
      isModalOpen: false,
      search: '',
      editGroup: null,
    }
    
    this.openModalGroup = this.openModalGroup.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.save = this.save.bind(this);
    this.redirectGroup = this.redirectGroup.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.newGroupHandler = this.newGroupHandler.bind(this);
    this.editGroupHandler = this.editGroupHandler.bind(this);
    this.closeModalGroup = this.closeModalGroup.bind(this);
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    let institutes = await this._getInstitutes(this.currentAcademic._id);
    let institutesOptions = institutes.map(item => {
      return {
        label: item.name,
        value: item._id
      }
    });
    
    this.setState({ 
      institutes,
      institutesOptions, 
      search: '', 
      editGroup: null
    });

    this._filterGroups(this.props.match.params.id);
  }

  async save(group) {
    let { instituteSelected } = this.state;
    group.institute = instituteSelected;
    
    if (group._id) {
      group = await this.update(group);
    } else {
      delete group._id;
      group = await this.new(group);
    }

    if (group) {
      this.openModalGroup();
      this.getData();
      this.onSuccess();
    }
  }

  async _getInstitutes(idAcademic) {
    try {
      const response = await academicServices.getAcademicInstitutes(idAcademic);
      return response.data;
    } catch (error) {
      this.onError(error);
    }
  }

  async new(group) {
    try {
      const response = await groupService.save(group);
      return response.data;
    } catch (error) {
      this.onError('Ocurrio un error al guardar los datos');
    }
  }

  async update(group) {
    try {
      const response = await groupService.update(group);
      return response.data;
    } catch (error) {
      this.onError('Ocurrio un error al actualizar los datos.');
    }
  }

  async onSuccesDelete() {
    await swal({
      text: 'Grupo borrado',
      icon: 'success',
      button: 'ok',
    });
    this.getData();
  }

  async deleteHandler(groupID) {
    const confirm = await swal({
      text: `¿Seguro que quieres eliminar el grupo?`,
      icon: 'warning',
      dangerMode: true,
      buttons: [
        'Cancelar',
        'Si, borrar'
      ]
    });

    if (!confirm) {
      return false;
    }

    try {
      await groupService.deleteGroup({ _id: groupID });
      this.onSuccesDelete();
    } catch (error) {
      this.onError('Ocurrio un error al eliminar el grupo');
    }
  }

  newGroupHandler() {
    let editGroup = {
      name: "",
      institute: this.state.instituteSelected,
      level: "",
      degree: "",
      shift: "",
      academic: this.currentAcademic._id || null,
    };

    this.setState({
      editGroup,
      isModalOpen: true
    });
  }

  editGroupHandler(group) {
    let editGroup = Object.assign({}, group);
    editGroup.level = editGroup.level._id;
    editGroup.degree = editGroup.degree._id;
    editGroup.institute = this.state.instituteSelected;
    editGroup.academic = this.currentAcademic._id || null;
    delete editGroup.categories;
    delete editGroup.enrolled;

    this.setState({
      editGroup,
      isModalOpen: true
    });
  }

  openModalGroup() {
    const isOpen = this.state.isModalOpen;
    this.setState({
      isModalOpen: !isOpen
    });
  }

  closeModalGroup() {
    this.setState({
      editGroup: null,
      isModalOpen: false
    });
  }

  onError(error) {
    swal({
      title: LANG.ERRORS.UNEXPECTED_ERROR,
      text: error.message,
      icon: "error"
    });
    this.setState({ errorSave: error })
  }

  handleChangeSelect(option) {
    this._filterGroups(option.value);
    this.setState({ instituteSelected: option.value });
    this.props.history.push(ROUTES.INSTITUTES.GROUPS.replace(':id', option.value))//`${ROUTES.GROUPS.ROOT}${option.value}`);
  }

  _filterGroups(instituteSelected) {
    let { institutes } = this.state;
    let institute = institutes.find(institute => institute._id === instituteSelected)
    let groupsSelected = institute ? institute.groups : [];
    this.setState({ groupsSelected })
  }

  onSuccess() {
    swal({
      title: LANG.INSTITUTES.SAVE_SUCCESS,
      icon: 'success',
      button: 'ok'
    });
  }

  searchHandler(value) {
    let groupsSelected = [];
    if (value !== "") {
      const filter = value.toLowerCase();
      let { institutes, instituteSelected } = this.state;
      let institute = institutes.find(institute => institute._id === instituteSelected);
      groupsSelected = institute.groups.filter(item => {
        const lc = item.name.toLowerCase();
        return lc.includes(filter);
      })
    } else {
      return this._filterGroups(this.props.match.params.id);
    }
    this.setState({ groupsSelected });
  }

  redirectGroup(group) {
    this.props.history.push(ROUTES.GROUPS.DASHBOARD.replace(':group', group))
  }

  _renderNav() {
    const { institutesOptions, instituteSelected, search } =  this.state;
    let selected = institutesOptions.find(item => item.value === instituteSelected);

    return (
      <NavbarInstituteGroups 
        selectOptions={institutesOptions}
        selectedOption={selected}
        selectChange={this.handleChangeSelect}
        linkBack={ROUTES.INSTITUTES.ROOT}
        searchQuery={search}
        searchHandler={this.searchHandler}
        actionHandler={this.newGroupHandler}
        actionText={LANG.GROUPS.BTN_ADD}
      />
    );
  }

  _renderCards(groups) {
    return (
      <React.Fragment>
        {groups ? groups.map(group => {
          return (
            <div key={group._id} className="col-12 col-sm-6 col-md-4">
              <CardGroup
                group={group}
                redirectGroup={this.redirectGroup}
                delete={this.deleteHandler}
                update={this.editGroupHandler}
              />
            </div>
          );
        }) : <></>}
      </React.Fragment>
    )
  }

  _renderEmpty() {
    const { instituteSelected, institutes } = this.state;
    let currentInstitute = '';
    if (instituteSelected) {
      let findInst = institutes.find(item => item._id === instituteSelected);
      if (findInst) {
        currentInstitute = findInst.name;
      }
    }

    return (
      <div className="col-md-6 offset-md-3 pt-5">
        <div className="text-center">{`Aún no has agregado ningún grupo dentro de ${currentInstitute}.` } <br/>Presiona el botón "+ NUEVO GRUPO" para crear tu primer grupo.</div>
      </div>
    );
  }

  render() {
    const { groupsSelected, instituteSelected, institutes } = this.state;
    let currentInstitute = '';
    if (instituteSelected) {
      let findInst = institutes.find(item => item._id === instituteSelected);
      if (findInst) {
        currentInstitute = findInst.name;
      }
    }

    return (
      <Layout
        navBar={this._renderNav()}>
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-info d-flex">
              <div className="mr-3"><Icon name="info" /></div>
              <p style={{fontSize: '0.85rem'}}>{`Es probable que impartas clases a más de un grupo dentro de una misma institución, dentro de Gameif Academy puedes administrar tus grupos de la misma forma. Crea un grupo aquí por cada grupo que tengas asignado en ${currentInstitute}.`}</p>
            </div>
          </div>
        </div>
        <div className="row">
          { groupsSelected.length > 0 ? this._renderCards(groupsSelected) : this._renderEmpty() }
        </div>
        {
          this.state.editGroup &&
          <ModalFormGroup 
            dataGroup={this.state.editGroup}
            isOpen={this.state.isModalOpen}
            onClose={this.closeModalGroup}
            onSave={this.save}
          />
        }
      </Layout>
    );
  }
}

export default InstituteDetail;