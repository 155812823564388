import React from 'react';
import CardQuizShopInfo from './card-quiz-shop-info';

function CardQuizShop(props) {
  return (
    <div className="card card-mk-quiz">
      <img src="https://via.placeholder.com/440x300" className="card-img-top" alt="..." />
      <div className="card-body">
        <CardQuizShopInfo 
          quiz={props.quiz} 
          enabledCartBtn={props.enabledCartBtn}
        />
      </div>
    </div>
  );
}

export default CardQuizShop