import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import NavbarTeamChallenge  from '../../components/navbar/navbar-team-challenge';
import ROUTES from '../../const/routes.const';
import {teamChallengeService} from '../../services';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';

class TeamChallengeStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      challenge: {
        _id: this.props.match.params.challenge || '',
        group: '',
        name: ''
      },
      teams: [],
      statsChallenge: null,
      schedule: null,
      statsGraph: []
    }
  }

  componentDidMount() {
    this.getChallenge();
    this.getTeams();
    this.getStats();
  }

  async getChallenge() {
    const challengeID = this.props.match.params.challenge;
    try {
      const response = await teamChallengeService.getDetail(challengeID);
      this.setState({
        challenge: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getTeams() {
    const challengeID = this.props.match.params.challenge;
    try {
      const response = await teamChallengeService.getTeams(challengeID);
      let teams = response.data;
      teams = teams.sort((a,b) => b.points - a.points);
      this.setState({ teams });
    } catch (error) {
      console.log(error);
    }
  }

  async getStats() {
    const challengeID = this.props.match.params.challenge;
    try {
      const response = await teamChallengeService.getChallengeStats(challengeID);
      const resRounds = await teamChallengeService.getRounds(challengeID);

      let teamStats = [];

      for (let i = 0; i < response.data.length; i++) {
        const element = response.data[i];
        let corrects = 0;
        let incorrects = 0;
        let questions = 0;
        for (let j = 0; j < element.members.length; j++) {
          const student = element.members[j];
          if (student.contribution.length > 0) {
            for (let k = 0; k < student.contribution[0].rounds.length; k++) {
              const round = student.contribution[0].rounds[k];
              questions += round.questions.length;
              corrects += round.correctAnswers;
            }
          }
        }

        incorrects = questions ? questions - corrects : 0;
        let accuracy = questions ? Math.round(corrects * 100 / questions) : 0;
        teamStats.push({
          team: element.name,
          points: element.points,
          corrects,
          incorrects,
          questions,
          accuracy
        });
      }

      this.setState({
        statsChallenge: response.data,
        statsGraph: teamStats,
        schedule: resRounds.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  customTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label"><strong>{label}</strong></p>
          <p>Correctas: <span>{(payload[0].dataKey === 'corrects' ? payload[0].value : payload[1].value)}</span>
          <br/>Incorrectas: <span>{(payload[0].dataKey === 'corrects' ? payload[1].value : payload[0].value)}</span>
          <br/>Precisión: <span>{payload[0].payload.accuracy}%</span></p>
          {/* <p className="intro">{getIntroOfPage(label)}</p>
          <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      );
    }
  
    return null;
  };

  renderLegendText(value, entry) {
    const { color } = entry;
    let label = value === 'corrects' ? 'Correctas' : 'Incorrectas';

    return <span style={{ color }}>{label}</span>;
  }

  _renderPodium() {
    const { teams } = this.state
    if (teams && teams.length) {
      return teams.map((item, index) => {
        if (index < 3) {
          let position = index + 1;
          let medalColor = index === 0 ? '#c9a224' : ( index === 1 ? '#bcb9b1' : '#886915' );
          return (
            <div className="col-md-4" key={`team-${index}`}>
              <div className="card bg-primary" style={{ minHeight: '100px' }}>
                <div className="card-body" style={{ position: 'relative' }}>
                  <div className="medalla" style={{ backgroundColor: `${medalColor}`, borderRadius: '50%', width: '32px', height: '32px', position: 'absolute', top: '-15px', left: '-10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><span>{position}</span></div>
                  <h4 className="text-center" style={{ fontSize: '1.2rem' }}>{item.name}</h4>
                </div>
              </div>
            </div>
          )
        }

        return null;
      })
    }

    return null;
  }

  render() {
    const { challenge, teams, schedule, statsGraph } = this.state;
    return (
      <Layout 
        navBar={
          <NavbarTeamChallenge 
            linkBack={ROUTES.GROUPS.CAMPAIGNS.replace(':group', challenge.group)}
            titleNav={challenge.name}
            currentOption={this.props.match.url}
            challengeID={challenge._id}
          />
        }
      >
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card" style={{height: '100%', backgroundColor: 'rgba(158,81,255,.8)'}}>
                      <div className="card-body text-right text-light">
                        <h2 className="m-0">{ teams && teams.length }</h2>
                        <small>Total de equipos</small>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                  <div className="card" style={{height: '100%', backgroundColor: 'rgba(158,81,255,.8)'}}>
                      <div className="card-body text-right text-light">
                        <h2 className="m-0">{ schedule && schedule.length }</h2>
                        <small>Rondas a jugar</small>
                        {/* <div>
                          <button className="btn btn-sm btn-link" style={{ color: '#fff' }}>Ver más</button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="card" style={{height: '100%', backgroundColor: 'rgba(158,81,255,.8)'}}>
                      <div className="card-body text-right text-light">
                        <div className="row">
                          { this._renderPodium() }
                        </div>
                        <h3 className="mt-3 mb-0" style={{ fontSize: '16px' }}>Equipos destacados</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      layout="vertical"
                      data={statsGraph}
                      margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis dataKey="team" type="category" />
                      <Tooltip content={this.customTooltip} />
                      <Legend formatter={this.renderLegendText} />
                      <Bar dataKey="corrects" fill="#9E51FF" minPointSize={3} />
                      <Bar dataKey="incorrects" fill="#ffc658" minPointSize={3} />
                    </BarChart>
                  </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TeamChallengeStats;