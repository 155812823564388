import React from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, Checkbox } from '../ui';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import DEFAULT_AVATAR from  '../../assets/images/default-avatar.png';

function ListSudents(props) {

  const handleChangeSelected = (event) => {
    let quiz = event.target.value;
    let selected = props.selected;
    let indexOf = selected.findIndex(item => item === quiz);
    if (indexOf < 0) {
      selected.push(quiz);
    } else {
      selected.splice(indexOf, 1);
    }

    props.onSelectStudent(selected);
  };

  return (
    <List>
      { props.students &&
        props.students.map(item => {
          return (
            <ListItem key={item._id}>
              <ListItemSecondaryAction>
                <Checkbox 
                  name="itemStudent[]" 
                  id={`itemStudent_${item._id}`}
                  value={item._id}
                  checked={props.selected.includes(item._id)}
                  onChange={handleChangeSelected}
                />
              </ListItemSecondaryAction>
              <ListItemAvatar>
                <Avatar 
                  src={DEFAULT_AVATAR}
                />
              </ListItemAvatar>
              <ListItemText 
                primary={ item.nickName  }
                secondary={ item.name && `${item.name} ${item.lastname}` }
              />
              <ListItemSecondaryAction>
                <div className="dropdown-primary-up">
                  <DropdownButton
                    drop='up'
                    variant="icon arrow-none"
                    alignRight
                    title={<i className="material-icons">more_vert</i>}
                    id='dropdown-button-drop'
                  >
                    <Dropdown.Item eventKey="1" >
                      <div 
                        className="d-flex justify-content-between align-items-center" 
                        onClick={() => props.onAssignTeam(item)} >
                        <span>Asignar equipo</span>
                        <i className="material-icons md-18 ml-2">assignment_late</i>
                      </div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
    </List>
  );
}

export default ListSudents;