import React, { Component } from 'react';
import LayoutMarket from '../../components/layout-market/layout-market';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';
import { Icon, Button, IconButton } from '../../components/ui';
import CardQuizShop from './card-quiz/card-quiz-shop';
import CheckoutSummary from './checkout/chekout-summary';

class MarketplaceCart extends Component {
  constructor(props) {
    super(props);

    this.processCart = this.processCart.bind(this);
  }

  processCart() {
    this.props.history.push(ROUTES.MARKETPLACE.CHECKOUT);
  }
  
  render() {
    return (
      <LayoutMarket>
        <div className="mk-page-title">
          <Link to={ROUTES.MARKETPLACE.ROOT}>
            <Icon name="arrow_back_ios" />
          </Link>
          <h2>Mi Carrito</h2>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <div className="mk-cart--item">
                  <CardQuizShop quiz={null} />
                  <IconButton name="close" className="btn-remove-cart" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <CheckoutSummary />
            <Button 
              color="primary btn-block"
              icon="shopping_cart"
              label="Tramitar pedido"
              onClick={this.processCart}
            /> 
          </div>
        </div>
      </LayoutMarket>
    );
  }
}

export default MarketplaceCart;