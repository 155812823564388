import React, { Component } from 'react';
import LANG from '../../const/language.const';
import { quizzesServices, QuestionsServices } from '../../services';
import Layout from '../../components/layout/layout';
import NavbarQuiz from '../../components/navbar/navbar-quiz';
import TextField from '../../components/ui/text-field';
import TextFieldDecorate from '../../components/ui/text-field-decorate';
import ImageUploader from '../../components/image-uploader/image-uploader';
import swal from '@sweetalert/with-react';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';

class QuestionForm extends Component {
  constructor(props) {
    super(props);
    // const quizID = this.props.match.params._idQuiz || '';
    this.state = {
      question: {
        title: '',
        imageRequired: false,
        image: '',
        answers: [
          { text: '', correct: true },
          { text: '', correct: false },
          { text: '', correct: false },
          { text: '', correct: false },
        ],
        feedbackComplementary: '',
        feedbackConstructive: '',
        quiz: ''
      },
      quiz: {
        _id: '',
        name: ''
      },
      errors: null,
      fileImage: null,
    };

    this.changeInputHandler = this.changeInputHandler.bind(this);
    this.changeAnswerHandler = this.changeAnswerHandler.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.saveQuestion = this.saveQuestion.bind(this);
  }

  async componentDidMount() {
    const quizID = this.props.match.params.quiz;
    const questionID = this.props.match.params.question;
    let {question, quiz} = this.state;

    console.log('quizID', quizID);
    console.log('questionID', questionID);

    if (quizID) {
      quiz = await this.getQuiz(quizID);
    } else if (questionID) {
      question = await this.getQuestion(questionID);
      quiz = await this.getQuiz(question.quiz);
    }

    this.setState({
      quiz,
      question
    });
  }

  async getQuiz(quizID) {
    try {
      const response = await quizzesServices.getById(quizID);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getQuestion(questionID) {
    try {
      const response = await QuestionsServices.getById(questionID);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  changeAnswerHandler(index, value, isCorrect) {
    console.log('changeAnswer:', index, value, isCorrect);
    let question = this.state.question;
    question.answers[index] = {
      text: value,
      correct: isCorrect
    };
    this.setState({ question });
  }

  changeInputHandler(event) {
    let question = this.state.question;
    let name = event.target.name;
    let value = event.target.value;

    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    }
    
    question[name] = value;
    this.setState({ question });
  }

  onChangeImage(pictureFiles, pictureDataURLs) {
    console.log('upload image:', pictureFiles, pictureDataURLs);
    let question = this.state.question;
    let file = pictureFiles.length ? pictureFiles[0] : null;
    question.image = pictureDataURLs.length ? pictureDataURLs[0] : '';

    this.setState({
      question,
      fileImage: file
    });
  }

  submitForm() {
    const {question} = this.state;
    let errors = {};
    let isValid = true;
    
    if (!question.title) {
      errors.title = LANG.ERRORS.REQUIRED_FIELD;
      isValid = false;
    }

    if (!question.feedbackComplementary) {
      errors.feedbackComplementary = LANG.ERRORS.REQUIRED_FIELD;
      isValid = false;
    }

    if (!question.feedbackConstructive) {
      errors.feedbackConstructive = LANG.ERRORS.REQUIRED_FIELD;
      isValid = false;
    }

    if (isValid) {
      this.saveQuestion();
    } else {
      this.setState({
        errors
      });
    }
  }

  async saveQuestion() {
    let question = this.state.question;
    question.quiz = this.state.quiz._id;
    try {
      const response = await QuestionsServices.create(question);
      this.setState({
        question: response.data
      });
      this.onSuccess();
    } catch (error) {
      console.log(error);
    }
  }

  async onSuccess() {
    await swal({
      title: 'Éxito',
      text: 'La información de la pregunta ha sido guardada',
      icon: 'success',
      button: 'ok',
    });

    this.props.history.push(ROUTES.QUIZZES.QUESTIONS.replace(':quiz', this.state.quiz._id));
  }

  _renderForm() {
    const { question, errors } = this.state;
    let indexCorrect = question.answers.findIndex(item => item.correct);
    let bulletNumIncorrect = 0;
    let propsPreviewImage = {
      withPreview: true
    };

    if (question.image) {
      propsPreviewImage.defaultImages= [question.image];
    }

    return (
      <form>
        <TextField 
          name="title"
          placeholder={LANG.QUESTIONS.FORM.QUESTION_TITLE}
          counter
          counterLimit={90}
          errorMessage={errors && errors.title}
          value={question.title}
          onChange={this.changeInputHandler}
        />
        <div className="d-flex justify-content-start align-items-center">
          <div className="check-ok">
            <input
              type="checkbox"
              id="c1"
              name="imageRequired"
              checked={question.imageRequired}
              onChange={this.changeInputHandler} 
            />
            <label htmlFor="c1" className="mb-0 d-flex justify-content-start align-items-center ">
              <i className="material-icons check">check_circle</i>
              <i className="material-icons no-check">radio_button_unchecked</i>
              <span className="ml-2 f-poppins">{LANG.QUIZZES.FORM.REQUIRED_IMAGE} </span>
            </label>
          </div>
        </div>
        { question.imageRequired &&
          <ImageUploader
            withPreview={true}
            onChange={this.onChangeImage}
            withIcon={false}
            buttonClassName="btn btn-primary btn-rounded btn-material-icon d-inline-flex"
            buttonText={
              <>
                <i className="material-icons">add_photo_alternate</i>
                <span>Seleccionar imagen</span>
              </>
            }
            fileSizeError=" el archivo es demasiado grande"
            fileTypeError=" el tipo de archivo no es soportado"
            buttonType="button"
            singleImage={true}
            {...propsPreviewImage}
          />
        }
        <div className="my-3 border-bottom"></div>
        <p>
          <i className="material-icons md-18 text-primary">check_circle</i>
          <strong className="text-accent ml-2">{LANG.QUESTIONS.FORM.CORRECT_ANSWER}</strong>
        </p>
        <TextField 
          name="correct_answer"
          placeholder={LANG.QUESTIONS.FORM.PLACEHOLDER_CORRECT_ANSWER}
          counter
          counterLimit={90}
          value={question.answers[indexCorrect].text || ''}
          onChange={(e) => this.changeAnswerHandler(indexCorrect, e.target.value, true)}
        />
        <p>
          <i className="material-icons md-18 text-primary">sentiment_very_satisfied</i>
          <strong className="text-accent ml-2">{LANG.QUESTIONS.FORM.PLACEHOLDER_FEEDBACK_CORRECT}</strong>
        </p>
        <TextField 
          name="feedbackComplementary"
          placeholder={LANG.QUESTIONS.FORM.PLACEHOLDER_FEEDBACK_CORRECT}
          counter
          counterLimit={90}
          errorMessage={errors && errors.feedbackComplementary}
          value={question.feedbackComplementary}
          onChange={this.changeInputHandler}
        />
        <div className="my-3 border-bottom"></div>
        <p>
          <i className="material-icons md-18 text-primary">cancel</i>
          <strong className="text-accent ml-2">{LANG.QUESTIONS.FORM.INCORRECT_ANSWERS}</strong>
        </p>
        {
          question.answers.map((item, index) => {
            if (index !== indexCorrect) {
              bulletNumIncorrect++;
              return (
                <TextFieldDecorate 
                  key={index}
                  name="incorrect[]"
                  leftDecorate={bulletNumIncorrect}
                  placeholder={LANG.QUESTIONS.FORM.PLACEHOLDER_INCORRECT_ANSWER}
                  counter
                  counterLimit={90}
                  value={item.text}
                  onChange={(e) => this.changeAnswerHandler(index, e.target.value, false)}
                />
              )
            }
            return null;
          })
        }
        <p>
          <i className="material-icons md-18 text-primary">sentiment_very_dissatisfied</i>
          <strong className="text-accent ml-2">{LANG.QUESTIONS.FORM.FEEDBACK_INCORRECT}</strong>
        </p>
        <TextField 
          name="feedbackConstructive"
          placeholder={LANG.QUESTIONS.FORM.PLACEHOLDER_FEEDBACK_INCORRECT}
          counter
          counterLimit={90}
          errorMessage={errors && errors.feedbackConstructive}
          value={question.feedbackConstructive}
          onChange={this.changeInputHandler}
        />
      </form>
    );
  }

  render() {
    const titleForm = this.state.question._id 
    ? LANG.QUESTIONS.FORM.EDIT_QUESTION
    : LANG.QUESTIONS.FORM.NEW_QUESTION;

    const linkToBack = this.props.match.params.question
    ? ROUTES.QUESTIONS.DETAIL.replace(':question', this.state.question._id) 
    : ROUTES.QUIZZES.QUESTIONS.replace(':quiz', this.state.quiz._id);

    return (
      <Layout
        navBar={
          <NavbarQuiz 
            linkBack={linkToBack}
            titleNav={this.state.quiz.name}
            onlyTitle
            quiz={this.state.quiz._id}
          />
        }
      >
        <div className="text-help px-3" style={{ fontSize: '.85rem' }}>
          <p>Por favor sigue las recomendaciones de extensión de las preguntas, opciones de respuesta y retroalimentaciones para garantizar una óptima experiencia de los alumnos dentro de la aplicación.
            <br/>Recuerda redactar las retroalimentaciones de forma emocionante.
            <br/>Utiliza la opción de "Requiere imagen" en los casos en los que dentro de la pregunta requieras apoyo visual. <i>Por ejemplo: "En el siguiente esquema, ¿cómo se llama el órgano señalado con rojo?"</i>
            <br/><strong>IMPORTANTE:</strong> Recuerda llenar todos los campos.</p>
        </div>    
        <div className="card card-form-question">
          <div className="card-header pt-4">
            <div className="card-title">
              <i className="material-icons">live_help</i>
              <span>{titleForm}</span>
            </div>
            <div className="card-actions">
              <Link
                to={ROUTES.QUIZZES.QUESTIONS.replace(':quiz', this.state.quiz._id)}
                className="btn btn-link"
              >
                {LANG.QUESTIONS.BTN_CANCEL}
              </Link>
              <button
                type="button"
                className="btn btn-primary btn-rounded btn-material-icon d-inline-flex"
                onClick={this.submitForm}
              >
                <i className="material-icons">save</i>
                <span>{LANG.QUESTIONS.BTN_SAVE}</span>
              </button>
            </div>
          </div>
          <div className="card-body">
            {this._renderForm()}
          </div>
        </div>
      </Layout>
    );
  }
}

export default QuestionForm;