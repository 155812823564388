import React, { Component } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap'
import LANG from '../../const/language.const';

class CardGroup extends Component {
  constructor(props) {
    super(props);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
  }

  async deleteGroup() {
    const group = Object.assign({}, this.props.group);
    await this.props.delete(group._id)
  }

  updateGroup() {
    const group = Object.assign({}, this.props.group);
    this.props.update(group)
  }

  render() {
    return (
      <div className="card card-groups">
        <div className="card-body pb-1" onClick={() => this.props.redirectGroup(this.props.group._id)}>
          <h3 className="card-title">{this.props.group.name}</h3>
          <ul className="data-stats">
            <li className="mr-5">
              <span className="title-stats">{LANG.GROUPS.CARD.STUDENTS}</span>
              <span className="number-stats">{this.props.group.enrolled}</span>
            </li>
            <li className="mr-5">
              <span className="title-stats">{LANG.GROUPS.CARD.PIN}</span>
              <span className="number-stats">{this.props.group.pin}</span>
            </li>
          </ul>
        </div>
        <div className="card-footer text-right">
          <div className="dropdown-primary-up">
            <DropdownButton
              drop='up'
              variant="icon arrow-none"
              alignRight
              title={<i className="material-icons">more_vert</i>}
              id='dropdown-button-drop'
            >
              <Dropdown.Item eventKey="1" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={this.deleteGroup} >
                  <span>Borrar</span>
                  <i className="material-icons">delete</i>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={this.updateGroup} >
                  <span>Editar</span>
                  <i className="material-icons">edit</i>
                </div>
                <div className="arrow-down">
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    );
  }
}

export default CardGroup;