import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const countriesServices = {
  getCountries,
  getCountry,
}

async function getCountries() {
  return await HTTP.get(ROUTES_API.COUNTRIES.GET_ALL);
}

async function getCountry(countryID) {
  return await HTTP.get(ROUTES_API.COUNTRIES.GET_ONE.replace(':country', countryID));
}
