import HTTP from './http.service';
import ROUTES_API from '../const/routes-api.const';

export const academicServices = {
  getAcademicInstitutes,
  getAllInstitutesByAcademic,
  setFinishedTutorials
}

async function getAcademicInstitutes(academicID) {
  return await HTTP.get(`${ROUTES_API.ACADEMICS.INSTITUTES}${academicID}/institutes`);
}

async function getAllInstitutesByAcademic(academicID) {
  return await HTTP.get(`${ROUTES_API.ACADEMICS.GET_BY_ACADEMIC}${academicID}`);
}

async function setFinishedTutorials(idAcademic) {
  return await HTTP.put(ROUTES_API.ACADEMICS.SET_FINISHED_TUTORIALS, {idAcademic});
}