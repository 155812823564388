import React, { Component } from 'react';
import Layout from '../../components/layout/layout';
import TitleNavbar from '../../components/navbar/title-navbar';
import ROUTES from '../../const/routes.const';
import LANG from '../../const/language.const';
import TextField from '../../components/ui/text-field';
import REFERENCE_FIELDS from '../../const/reference-fields.const';
import { referencesServices } from '../../services';
import swal from '@sweetalert/with-react';

class ReferenceForm extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      reference: {
        referenceType: 'book',
      },
      errors: {},
    };

    this.getReference = this.getReference.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  componentDidMount() {
    let type = this.props.match.params.type;
    let quiz = this.props.match.params.quiz;
    let reference = this.props.match.params.reference;
    if (reference) {
      this.getReference();
    } else {
      this.setState({
        reference: {
          referenceType: type,
          quiz: quiz
        }
      });
    }
  }

  async getReference() {
    try {
      let referencesId = this.props.match.params.reference;
      const response = await referencesServices.getById(referencesId);
      this.setState({
        reference: response.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  changeHandler(e) {
    let name = e.target.name;
    let value = e.target.value;
    let reference = this.state.reference;
    reference[name] = value;
    
    this.setState({
      reference
    });
  }

  submitForm() {
    let reference = this.state.reference;

    //TODO: validations
    if (reference._id) {
      this.update(reference);
    } else {
      this.create(reference);
    }
  }

  async create(reference) {
    try {
      const response = await referencesServices.create(reference);
      this.setState({
        reference: response.data
      }, this.onSuccess);
    } catch (error) {
      console.log(error.response.data)
    }
  }

  async update(reference) {
    try {
      const response = await referencesServices.update(reference);
      this.setState({
        reference: response.data
      }, this.onSuccess);
    } catch (error) {
      console.log(error.response.data)
    }
  }

  async onSuccess() {
    await swal({
      title: 'Éxito',
      text: 'Los datos de la bibliografía han sido guardados',
      icon: 'success',
      button: 'ok',
    });

    // this.props.history.push(ROUTES.REFERENCES.DETAIL.replace(':reference', this.state.reference._id));
    this.props.history.push(ROUTES.QUIZZES.REFERENCES.replace(':quiz', this.state.reference.quiz));
  }

  _renderNavbar() {
    let linkToBack = this.state.reference._id 
      ? ROUTES.REFERENCES.DETAIL.replace(':reference', this.state.reference._id)
      : ROUTES.QUIZZES.REFERENCES.replace(':quiz', this.state.reference.quiz);

    let titleNavbar = this.state.reference._id 
      ? LANG.REFERENCES.EDIT_REFERENCE
      : LANG.REFERENCES.ADD_REFERENCE;

    if (this.state.quiz) {
      titleNavbar += ` del cuestionario "${this.state.quiz.name}"`;
    }

    return (
      <TitleNavbar 
        linkBack={linkToBack}
        title={titleNavbar}
      />
    );
  }

  _renderFields() {
    const {reference} = this.state;
    let fields = REFERENCE_FIELDS[reference.referenceType];
    if (!fields) {
      return null;
    }

    return fields.map((item, index) => {
      return (
        <div key={index}>
          <p>
            <i className="material-icons md-18 text-primary">{item.icon}</i>
            <strong className="text-accent ml-2">{item.label}</strong>
          </p>
          <TextField 
            name={item.name}
            placeholder={item.placeholder}
            value={reference[item.name] || ''}
            onChange={this.changeHandler}
          />
        </div>
      )
    });
  }

  render() {
    let labelTypeRef = 'Formato para bibliografía de ';
    switch (this.state.reference.referenceType) {
      case 'book':
        labelTypeRef += ` ${LANG.REFERENCES.REFERENCE_BOOK}`;
        break;
      case 'magazine':
        labelTypeRef += ` ${LANG.REFERENCES.REFERENCE_MAGAZINE}`;
        break;
      case 'newspaper':
        labelTypeRef += ` ${LANG.REFERENCES.REFERENCE_NEWSPAPER}`;
        break;
      case 'encyclopedia':
        labelTypeRef += ` ${LANG.REFERENCES.REFERENCE_ENCYCLOPEDIA}`;
        break;
      case 'chapters':
        labelTypeRef += ` ${LANG.REFERENCES.REFERENCE_CHAPTERS}`;
        break;
      case 'web':
        labelTypeRef += ` ${LANG.REFERENCES.REFERENCE_WEB}`;
        break;
      default:
        break;
    }

    return (
      <Layout navBar={this._renderNavbar()} >
        <div className="row justify-content-md-center">
          <div className="col-sm-12 col-md-8">
            <form className="form-references">
              <div className="mb-4"><h5>{labelTypeRef}</h5></div>
              {this._renderFields() }
              <div className="row">
                <div className="col-12 text-right mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-rounded btn-material-icon d-inline-flex"
                    onClick={this.submitForm}
                  >
                    <i className="material-icons">save</i>
                    <span>{LANG.REFERENCES.FORM_REFERENCE.BTN_SAVE}</span>
                  </button> 
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ReferenceForm;