import React from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../ui/search-bar';
import SelectField from '../ui/select-field';

function NavbarInstituteGroups(props) {
  return (
    <div className="navbar navbar-institute-groups">
      <div className="nav-back">
        <Link to={props.linkBack}>
          <i className="material-icons arrow-back">arrow_back</i>
        </Link>
      </div>
      <div className="navbar-select-institute">
        <SelectField 
          value={props.selectedOption}
          options={props.selectOptions}
          onChange={props.selectChange}
        />
      </div>
      <div className="navbar-search">
        <SearchBar
          value={props.searchQuery}
          showCancelSearch={true}
          onChange={props.searchHandler}
        />
      </div>
      <div className="actions">
        <button
          type="button"
          className="btn btn-primary btn-rounded btn-material-icon"
          onClick={props.actionHandler}>
          <i className="material-icons">add</i>
          <span>{props.actionText}</span>
        </button>
      </div>
    </div>
  );
}

export default NavbarInstituteGroups;