import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../const/routes.const';

class NotFound extends Component {
    render() {
        return (
            <div className="wrapper page-not-found">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-6">
                            <div className="bunny">
                            </div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <div className="logo">
                            </div>
                            <h1>404</h1>
                            <div className="text">
                                <p>No encontramos la página que estás buscando.</p>
                                <p>Intenta con otras palabras</p>
                            </div>

                            <Link to={ ROUTES.HOME.ROOT } className="btn-gif btn-gif-default" >Regresar al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;