import React from 'react';

function CardGroupAccuracy(props) {
  let totalAnswers = props.stats && props.stats.general ? props.stats.general.totalCorrect + props.stats.general.totalIncorrect : 0;
  let totalSingleEvents = props.stats && props.stats.singleEvents ? props.stats.singleEvents.totalCorrect + props.stats.singleEvents.totalIncorrect : 0;
  let totalTeamsEvents = props.stats && props.stats.teamEvents ? props.stats.teamEvents.totalCorrect + props.stats.teamEvents.totalIncorrect : 0;
  let correctPercentage = totalAnswers ? Math.round(props.stats.general.totalCorrect / totalAnswers * 100) : 0;
  let incorrectPercentage = totalAnswers ? Math.round(props.stats.general.totalIncorrect / totalAnswers * 100) : 0;
  let singlePercentage = totalSingleEvents ? Math.round(totalSingleEvents / totalAnswers * 100) : 0;
  let teamsPercentage = totalTeamsEvents ? Math.round(totalTeamsEvents / totalAnswers * 100) : 0;
  let friendlyPercentage = 100 - (singlePercentage + teamsPercentage);

  return (
    <div className="card card-group-accuracy">
      <div className="card-body">
        <div className="row">
          <div className="col-md-8">
            <h3>Porcentajes de efectividad general del grupo</h3>
            <div className="row percentage-accuracy">
              <div className="col-6">
                <h4><div className="oval-correct"></div>{correctPercentage}%</h4>
                <small style={{marginLeft: '18px'}}>Respuestas correctas</small>
              </div>
              <div className="col-6">
                <h4><div className="oval-incorrect"></div>{incorrectPercentage}%</h4>
                <small style={{marginLeft: '18px'}}>Respuestas incorrectas</small>
              </div>
            </div>
            <div className="progress-accuracy mt-4">
              <h4 className="mb-2 ml-3">{totalAnswers} <small>preguntas respondidas</small></h4>
              <div className="progress">
                <div className="progress-bar bg-friendly" role="progressbar" style={{width: `${friendlyPercentage}%`}} aria-valuenow={friendlyPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                <div className="progress-bar bg-single" role="progressbar" style={{width: `${singlePercentage}%`}} aria-valuenow={singlePercentage} aria-valuemin="0" aria-valuemax="100"></div>
                <div className="progress-bar bg-teams" role="progressbar" style={{width: `${teamsPercentage}%`}} aria-valuenow={teamsPercentage} aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <small><div className="oval-friendly"></div>Juego amistoso</small>
                </div>
                <div className="col-4">
                  <small><div className="oval-single"></div>E.E. individual</small>
                </div>
                <div className="col-4">
                  <small><div className="oval-teams"></div>E.E. Equipo</small>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <div className="col-12">
                <h3>Mejores temas</h3>
                <ol style={{ borderLeft: '2px solid #FFEC79' }}>
                  {
                    props.statsThemes && props.statsThemes.bestThemes.map(item => {
                      return (
                        <li key={item._id}>{item.topic}</li>
                      )
                    })
                  }
                </ol>
              </div>
              <div className="col-12">
                <h3>Peores temas</h3>
                <ol style={{ borderLeft: '2px solid #FF7979' }}>
                  {
                    props.statsThemes && props.statsThemes.worstTopics.map(item => {
                      return (
                        <li key={item._id}>{item.topic}</li>
                      )
                    })
                  }
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardGroupAccuracy;