import React from 'react';
import LANG from '../../const/language.const';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Icon } from '../../components/ui';

function CardQuiz(props) {
  let editOptions = props.editOption === 'group' ? 'group' : 'quiz';
  return (
    <div className="card card-quiz">
      <div className="card-body pb-1" onClick={() => props.showDetail(props.quiz._id)}>
        <h3 className="card-title">
          {props.quiz.name}
        </h3>
        <h4 className="card-subtitle">{props.quiz.topic}</h4>
        <div className="d-flex justify-content-between py-2">
          <small className="text-primary text-uppercase mr-4">{props.quiz ? props.quiz.level.name : 'level'}</small>
          <small className="text-primary text-uppercase">{props.quiz ? props.quiz.degree.name : 'degree'}</small>
        </div>
        <ul className="data-stats">
          <li className="">
            <span className="title-stats">{LANG.QUIZZES.QUESTIONS}</span>
            <span className="number-stats">{props.quiz.totalQuestions}</span>
          </li>
          <li className="">
            <span className="title-stats">{LANG.QUIZZES.REFERENCES}</span>
            <span className="number-stats">{props.quiz.totalReferences}</span>
          </li>
        </ul>
        <div className="quiz-community">
          {
            props.quiz.authorAndOwner && <Icon name="person" size={30} />
          }
          {
            props.quiz.authorAndOwner && props.quiz.type === 'SHARED' &&
            <Icon name="tag_faces" size={30} />
          }
          {
            !props.quiz.authorAndOwner && props.quiz.reviewer &&
            <Icon name="visibility" size={30} />
          }
          {
            !props.quiz.authorAndOwner && !props.quiz.reviewer &&
            <Icon name="shopping_cart" size={30} />
          }
        </div>
      </div>

      <div className="card-footer text-right">
        { editOptions === 'group' &&
          <button
            className="btn btn-light btn-circle"
            onClick={() => props.delete(props.quiz)}
          >
            <i className="material-icons text-primary">delete</i>
          </button>
        }
        { editOptions === 'quiz' &&
          <div className="dropdown-primary-up">
            <DropdownButton
              drop='up'
              variant="icon arrow-none"
              alignRight
              title={<i className="material-icons">more_vert</i>}
              id='dropdown-button-drop'
            >
              <Dropdown.Item eventKey="1" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={() => props.delete(props.quiz)} >
                  <span>Borrar</span>
                  <i className="material-icons">delete</i>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="2" >
                <div 
                  className="d-flex justify-content-between align-items-center" 
                  onClick={() => props.update(props.quiz)}>
                  <span>Editar</span>
                  <i className="material-icons">edit</i>
                </div>
                <div className="arrow-down">
                </div>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        }
      </div>
    </div>
  );
}

export default CardQuiz;