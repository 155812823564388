import React from 'react';
import DatePicker, { registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import Icon from './icon';
registerLocale('es', es)

function TimePickerField(props) {
  const {
    showTimeSelect,
    showTimeSelectOnly,
    timeIntervals,
    timeCaption,
    dateFormat,
    ...restProps
  } = props;

  let intervals = timeIntervals ? timeIntervals : 15;
  let caption = timeCaption ? timeCaption : 'Hora';
  let format = dateFormat ? dateFormat : 'h:mm aa'; 

  return (
    <div className="form-group">
      <div className="fg-line fg-line-datepicker">
        <Icon name="schedule" />
        <DatePicker 
          className="form-control"
          showTimeSelect={true}
          showTimeSelectOnly={true}
          timeIntervals={intervals}
          timeCaption={caption}
          dateFormat={format}
          {...restProps}
        />
      </div>
    </div>
  );
}

export default TimePickerField;